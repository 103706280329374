<template>
    <Button type="button" v-tooltip.top="'pelunasan tagihan studi error Mandiri'" icon="pi pi-check" label="" class="p-button-info ml-2" @click="display = true" />
    <div class="col-12 lg:col-12">
        <Dialog header="Pelunasan Tagihan Studi Mahasiswa Error Bank Mandiri" v-model:visible="display" :breakpoints="{ '960px': '75vw' }" :style="{ width: '30vw' }" :modal="true" @keydown.enter.prevent="update()" @hide="v$.$reset()">
            <div class="col-12 lg:col-12 p-fluid">
                <span class="p-float-label mt-4">
                    <InputText id="NIM_DTS" disabled type="text" styleClass="wid100" autofocus v-model.lazy="data_edit.NIM_DTS" />
                    <label for="NIM_DTS">NIM</label>
                </span>
                <span class="p-float-label mt-4">
                    <InputText id="nama_mahasiswa_dts" disabled v-model.lazy="data_edit.nama_mahasiswa_dts" />
                    <label for="nama_mahasiswa_dts">Nama Mahasiswa/wi</label>
                </span>
                <span class="p-float-label mt-4">
                    <InputText id="nama_kategori" disabled v-model.lazy="data_edit.nama_kategori" />
                    <label for="nama_kategori">Kategori Bayar</label>
                </span>
                <span class="p-float-label mt-4">
                    <InputText id="nama_prodi" disabled v-model.lazy="data_edit.nama_prodi" />
                    <label for="nama_prodi">Prodi</label>
                </span>
                <span class="p-float-label mt-4">
                    <InputText id="tahun_ak" disabled v-model.lazy="data_edit.tahun_ak" />
                    <label for="tahun_ak">Tahun Kalender Bayar</label>
                </span>
                <span class="p-float-label mt-4">
                    <InputNumber id="total_tagihan" type="text" styleClass="wid100" disabled autofocus locale="id" v-model.lazy="data_edit.total_tagihan" />
                    <label for="total_tagihan">Tagihan</label>
                </span>
                <span class="p-float-label mt-4">
                    <InputNumber id="total_bayar" type="text" styleClass="wid100" autofocus locale="id" v-model.lazy="data_edit.nominal" />
                    <label for="total_bayar">Total Bayar</label>
                </span>
                <!-- <span class="p-float-label mt-4">
                    <InputText
                        id="nama_bank_pts"
                        disabled
                        v-model.lazy="data_edit.nama_bank_pts"
                        @blur="v$.data_edit.nama_bank_pts.$touch()"
                        :class="{ 'p-invalid': v$.data_edit.nama_bank_pts.$invalid ? !v$.data_edit.nama_bank_pts.$anyError : null }"
                    />
                    <label for="nama_bank_pts">Jenis Pelunasan</label>
                    <small v-if="v$.data_edit.nama_bank_pts.$invalid" :class="{ 'p-error': v$.data_edit.nama_bank_pts.$invalid ? !v$.data_edit.nama_bank_pts.$anyError : null }">{{ checkValidasi('nama_bank_pts') }}</small>
                </span> -->
            </div>
            <template #footer>
                <Button label="Batal" :loading="busy" @click="display = false" icon="" class="p-button-secondary" />
                <Button label="Simpan" :loading="busy" @click="update()" icon="" class="p-button-success" :disabled="!isValid" />
            </template>
        </Dialog>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
export default {
    props: ['dataEdit'],
    setup() {
        return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
    },
    emits: {
        alert: null,
    },
    data() {
        return {
            display: false,
            busy: false,
            option_jenis: [{ value: 'Mandiri', text: 'Mandiri' }],
            tanggal_dibayar: null,
        };
    },
    validations() {
        return {
            data_edit: {
                tanggal_mulai: {
                    required,
                },
                tanggal_tutup: {
                    required,
                },
                tagihan: {
                    required,
                },
            },
        };
    },
    computed: {
        formString() {
            return JSON.stringify(this.data);
        },
        isValid() {
            return !this.v$.$invalid;
        },
        isDirty() {
            return this.v$.$anyDirty;
        },
        data_edit() {
            let vm = this;
            let x = vm.dataEdit;
            // x.tanggal_dibayar = null
            x.details_tagihan_studi_id = x.detailsTagihanStudiId;
            x.nama_mahasiswa = x.nama_mahasiswa_dts;
            x.total_tagihan = x.tagihan;
            x.total_dibayar = x.tagihan;
            x.NIM_PTS = x.NIM_DTS;
            x.nim = x.NIM_DTS;
            x.nominal = x.tagihan;
            x.kode_pendaftaran_pts = x.kode_pendaftaran_dts;
            x.tanggal_mulai = x.bayar_mulai;
            x.tanggal_tutup = x.bayar_tutup;
            return x;
        },
    },
    mounted() {
        this.v$.$reset();
    },
    methods: {
        set_date(x, y) {
            this.data_edit[x] = y;
        },
        update() {
            let vm = this;
            vm.busy = true;
            vm.v$.$touch();
            if (vm.isValid && vm.isDirty) {
                vm.$axiosbilling
                    .post('va/mandiri/pelunasanMandiri', vm.data_edit)
                    .then((res) => {
                        console.log(res.data.status);
                        if (res.data.status == 200) {
                            if (res.data.message == 'sukses') {
                                vm.display = false;
                                vm.busy = false;
                                vm.$emit('alert', { severity: 'success', summary: 'Konfirmasi', detail: 'Berhasil Mengubah Tagihan Mahasiswa', life: 3000 });
                            } else {
                                vm.busy = false;
                                vm.display = false;
                                vm.$emit('alert', { severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                            }
                        } else {
                            vm.busy = false;
                            vm.$emit('alert', { severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                            vm.display = false;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        vm.busy = false;
                        vm.display = false;
                        vm.$emit('alert', { severity: 'error', summary: 'Konfirmasi', detail: 'Terjadi kesalahan pada server', life: 3000 });
                    });
            } else {
                vm.busy = false;
                vm.$emit('alert', { severity: 'error', summary: 'Peringatan', detail: 'Masih terdapat isian data yang tidak valid', life: 3000 });
            }
        },
        checkValidasi(fieldName) {
            const field = this.v$.data_edit[fieldName];
            let x = field.$errors[0].$validator;
            if (x == 'required') {
                return '* Data wajib diisi';
            } else if (x == 'email') {
                return '* Data harus berbentuk email';
            } else if (x == 'numeric') {
                return '* Data harus terdiri hanxa dari angka';
            } else if (x == 'minLength') {
                return `* Data minimal ${field.$errors[0].$params.min} digits`;
            } else if (x == 'maxLength') {
                return `* Data maksimal ${field.$errors[0].$params.max} digits`;
            } else if (x == 'alphaNum') {
                return `* Data tidak boleh mengandung spasi atau simbol`;
            } else if (x == 'official') {
                return `* Data tidak boleh mengandung spasi atau simbol`;
            }
        },
    },
};
</script>
