<template>
    <div :class="layoutContainerClass" @click="onDocumentClick">
        <div class="layout-main">
            <AppTopBar
                :items="filteredMenu"
                :menuMode="menuMode"
                :colorScheme="colorScheme"
                :menuActive="menuActive"
                :topbarMenuActive="topbarMenuActive"
                :activeInlineProfile="activeInlineProfile"
                @topbar-item-click="onTopbarItemClick"
                @menubutton-click="onMenuButtonClick"
                @sidebar-mouse-over="onSidebarMouseOver"
                @sidebar-mouse-leave="onSidebarMouseLeave"
                @toggle-menu="onToggleMenu"
                @change-inlinemenu="onChangeActiveInlineMenu"
                @menu-click="onMenuClick"
                @menuitem-click="onMenuItemClick"
                @root-menuitem-click="onRootMenuItemClick"
            />

            <AppMenu
                :model="filteredMenu"
                :menuMode="menuMode"
                :colorScheme="colorScheme"
                :menuActive="menuActive"
                :sidebarActive="sidebarActive"
                :sidebarStatic="sidebarStatic"
                :pinActive="pinActive"
                :staticMenuMobileActive="staticMenuMobileActive"
                :activeInlineProfile="activeInlineProfile"
                @sidebar-mouse-over="onSidebarMouseOver"
                @sidebar-mouse-leave="onSidebarMouseLeave"
                @toggle-menu="onToggleMenu"
                @change-inlinemenu="onChangeActiveInlineMenu"
                @menu-click="onMenuClick"
                @menuitem-click="onMenuItemClick"
                @root-menuitem-click="onRootMenuItemClick"
            />

            <AppBreadcrumb :menuMode="menuMode" v-model:searchActive="searchActive" v-model:searchClick="searchClick" @menubutton-click="onMenuButtonClick" @rightmenu-button-click="onRightMenuButtonClick" />

            <div class="layout-main-content">
                <router-view />
            </div>

            <AppFooter :colorScheme="colorScheme" />
        </div>

        <!-- <AppRightMenu :rightMenuActive="rightMenuActive" @rightmenu-click="onRightMenuClick" @rightmenu-active="onChangeRightMenuActive" /> -->

        <AppConfig
            v-model:menuMode="menuMode"
            :theme="theme"
            :componentTheme="componentTheme"
            :colorScheme="colorScheme"
            :configActive="configActive"
            :configClick="configClick"
            @config-button-click="onConfigButtonClick"
            @config-click="onConfigClick"
            @change-color-scheme="onChangeColorScheme"
            @change-component-theme="onChangeComponentTheme"
            @change-menu-theme="onChangeMenuTheme"
            @change-menu-mode="onChangeMenuMode"
        />
    </div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
// import AppRightMenu from './AppRightMenu.vue';
import AppFooter from './AppFooter.vue';
import AppBreadcrumb from './AppBreadcrumb.vue';
import AppConfig from './AppConfig.vue';
import EventBus from './event-bus';

export default {
    emits: ['layout-mode-change', 'menu-theme', 'layoutModeChange', 'sidebar-mouse-over', 'sidebar-mouse-leave', 'change-color-scheme', 'change-component-theme', 'change-menu-theme', 'change-inlinemenu'],
    props: {
        colorScheme: String,
        topbarTheme: String,
        theme: String,
        componentTheme: String,
    },
    data() {
        return {
            search: false,
            searchClick: false,
            searchActive: false,
            // menuMode: 'sidebar',
            menuActive: false,
            activeInlineProfile: false,
            inlineMenuClick: false,
            overlayMenuActive: false,
            topbarMenuActive: false,
            topbarItemClick: false,
            sidebarActive: true,
            sidebarStatic: true,
            pinActive: true,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            rightMenuClick: false,
            rightMenuActive: false,
            configActive: false,
            configClick: false,
            menu: [
                {
                    label: 'Dashboard',
                    icon: 'pi pi-fw pi-home',
                    to: '/dashboard',
                },
                {
                    label: 'Cari Mahasiswa',
                    icon: 'pi pi-fw pi-search',
                    to: '/pencarian_mahasiswa',
                },
                {
                    label: 'Master Data',
                    icon: 'pi pi-fw pi-star-fill',
                    items: [
                        { label: 'Jenis Kategori', icon: 'pi pi-fw pi-circle', disabled: true, to: '/jenis_kategori_bayar', levels: [2, 3, 4, 99] },
                        { label: 'Bank', icon: 'pi pi-fw pi-circle', disabled: true, to: '/bank', levels: [2, 3, 4, 99] },
                    ],
                    level: [1, 2, 3, 4, 99],
                },
                {
                    label: 'Mahasiswa',
                    icon: 'pi pi-fw pi-users',
                    items: [
                        { label: 'Calon MABA', icon: 'pi pi-fw pi-id-card', to: '/master_maba', disabled: true },
                        { label: 'Mahasiswa', icon: 'pi pi-fw pi-users', to: '/master_mahasiswa', disabled: true },
                        { label: 'Arsip', icon: 'pi pi-fw pi-users', to: '/arsip_mahasiswa', disabled: true },
                    ],
                    level: [1, 2, 3, 4, 99],
                },
                {
                    label: 'Master Biaya',
                    icon: 'pi pi-fw pi-credit-card',
                    items: [
                        { label: 'Usulan Biaya', icon: 'pi pi-fw pi-circle', disabled: true, to: '/usulan_biaya', levels: [2, 3, 4, 99] },
                        // { label: 'Kategori Biaya', icon: 'pi pi-fw pi-circle',disabled : true, to: '/kategori_biaya' },
                        { label: 'Master Biaya Semester', icon: 'pi pi-fw pi-circle', disabled: true, to: '/master_biaya_semester', levels: [2, 3, 4, 99] },
                        // { label: 'Riwayat Tugas Tambahan', icon: 'pi pi-fw pi-circle',disabled : true, to: '/riwayat_tugas_tambahan' },
                    ],
                    level: [2, 3, 4, 99],
                },
                {
                    label: 'Modul Laporan',
                    icon: 'pi pi-fw pi-credit-card',
                    items: [
                        { label: 'Rekap Tagihan PMB', icon: 'pi pi-fw pi-circle', disabled: true, to: '/rekap_tagihan_pmb' },
                        { label: 'Rekap Tagihan MABA', icon: 'pi pi-fw pi-circle', disabled: true, to: '/rekap_tagihan_maba' },
                        { label: 'Rekap Tagihan Mahasiswa', icon: 'pi pi-fw pi-circle', disabled: true, to: '/rekap_tagihan_mahasiswa', levels: [2, 3, 4, 99] },
                        { label: 'Rekap Piutang Mahasiswa', icon: 'pi pi-fw pi-circle', disabled: true, to: '/rekap_piutang_mahasiswa' },
                        { label: 'Rekap Tagihan Prodi', icon: 'pi pi-fw pi-circle', disabled: true, to: '/rekap_tagihan_prodi', levels: [2, 3, 4, 99] },
                        { label: 'Laporan Gakin', icon: 'pi pi-fw pi-circle', to: '/rekap_laporan_gakin' },
                    ],
                    level: [2, 3, 4, 99],
                },
                {
                    label: 'Modul Penetapan',
                    icon: 'pi pi-fw pi-credit-card',
                    items: [
                        { label: 'Penetapan Tahun Ajaran', icon: 'pi pi-fw pi-circle', disabled: true, to: '/penetapan_tahun_ajaran', levels: [2, 3, 4, 99] },
                        { label: 'Penetapan Kalender Bayar', icon: 'pi pi-fw pi-circle', disabled: true, to: '/penetapan_kalender_bayar', levels: [2, 3, 4, 99] },
                        // { label: 'Penetapan Kategori Bayar', icon: 'pi pi-fw pi-circle',disabled : true, to: '/penetapan_kategori_bayar',level: [2, 3, 4, 99] },
                        { label: 'Penetapan Kalender Bayar PMB', icon: 'pi pi-fw pi-circle', disabled: true, to: '/penetapan_kalender_bayar_pmb' },
                        { label: 'Penetapan Biaya Studi', icon: 'pi pi-fw pi-circle', disabled: true, to: '/penetapan_biaya_studi', levels: [2, 3, 4, 99] },

                        // { label: 'Riwayat Tugas Tambahan', icon: 'pi pi-fw pi-id-card', to: '/riwayat_tugas_tambahan' },
                    ],
                    level: [2, 3, 4, 99],
                },
                {
                    label: 'Modul Lainnya',
                    icon: 'pi pi-fw pi-credit-card',
                    items: [
                        { label: 'BIDIK KAMU', icon: 'pi pi-fw pi-circle', disabled: true, to: '/beasiswa_bidik_kamu', levels: [2, 3, 4, 99] },
                        { label: 'GAKIN', icon: 'pi pi-fw pi-circle', disabled: true, to: '/beasiswa_gakin', levels: [2, 3, 4, 99, 5] },
                        { label: 'Pelunasan Beasiswa', icon: 'pi pi-fw pi-circle', disabled: true, to: '/pelunasan_beasiswa', levels: [2, 3, 4, 99, 5] },
                        { label: 'Tagihan Wisuda', icon: 'pi pi-fw pi-circle', disabled: true, to: '/wisuda', levels: [2, 3, 4, 99, 5] },
                        { label: 'Pengembalian Dana', icon: 'pi pi-fw pi-circle', disabled: true, to: '/pengembalian_dana', levels: [2, 3, 4, 99, 5] },
                        // { label: 'Penetapan Kalender Bayar PMB', icon: 'pi pi-fw pi-circle',disabled : true, to: '/penetapan_kalender_bayar_pmb', disabled: true },
                        // { label: 'Penetapan Biaya Studi', icon: 'pi pi-fw pi-circle',disabled : true, to: '/penetapan_biaya_studi' },

                        // { label: 'Riwayat Tugas Tambahan', icon: 'pi pi-fw pi-id-card', to: '/riwayat_tugas_tambahan' },
                    ],
                    level: [2, 3, 4, 5, 99],
                },
                // {
                //     label: 'Sync Tagihan',
                //     icon: 'pi pi-fw pi-sync',
                //     items: [
                //         { label: 'Sync Tagihan', icon: 'pi pi-fw pi-circle', disabled: true, to: '/sync_tagihan', levels: [2, 3, 4, 99] },
                //     ],
                //     level: [2, 3, 4, 5, 99],
                // },

                // { label: 'User', icon: 'pi pi-fw pi-users', to: '/users', level: [99] },
                // {
                //     label: "Master Data", icon: "pi pi-fw pi-star-fill",
                //     items: [
                //         {label: "Master Agama", icon: "pi pi-fw pi-circle-off", to: "/agama"},
                //         {label: "Master Ikatan Kerja", icon: "pi pi-fw pi-circle-off", to: "/ikatan_kerja"},

                //     ],
                // },
            ],
        };
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        },
    },
    methods: {
        onDocumentClick() {
            if (!this.searchClick && this.searchActive) {
                this.onSearchHide();
            }

            if (!this.topbarItemClick) {
                this.topbarMenuActive = false;
            }

            if (!this.menuClick) {
                if (this.isHorizontal() || this.isSlim()) {
                    this.menuActive = false;
                    EventBus.emit('reset-active-index');
                }

                if (this.overlayMenuActive || this.staticMenuMobileActive) {
                    this.overlayMenuActive = false;
                    this.staticMenuMobileActive = false;
                }

                this.hideOverlayMenu();
                this.unblockBodyScroll();
            }

            if (!this.rightMenuClick) {
                this.rightMenuActive = false;
            }

            if (this.configActive && !this.configClick) {
                this.configActive = false;
            }

            this.topbarItemClick = false;
            this.menuClick = false;
            this.configClick = false;
            this.rightMenuClick = false;
            this.searchClick = false;
            this.inlineMenuClick = false;
        },
        onSearchHide() {
            this.searchActive = false;
            this.searchClick = false;
        },
        onSidebarMouseOver() {
            if (this.menuMode === 'sidebar' && !this.sidebarStatic) {
                this.sidebarActive = this.isDesktop();
                setTimeout(() => {
                    this.pinActive = this.isDesktop();
                }, 200);
            }
        },
        onSidebarMouseLeave() {
            if (this.menuMode === 'sidebar' && !this.sidebarStatic) {
                setTimeout(() => {
                    this.sidebarActive = false;
                    this.pinActive = false;
                }, 250);
            }
        },
        hideOverlayMenu() {
            this.overlayMenuActive = false;
            this.staticMenuMobileActive = false;
        },
        onMenuButtonClick(event) {
            this.menuClick = true;

            if (this.isOverlay()) {
                this.overlayMenuActive = !this.overlayMenuActive;
            }

            if (this.isDesktop()) {
                this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
            } else {
                this.staticMenuMobileActive = !this.staticMenuMobileActive;
            }

            event.preventDefault();
        },
        onTopbarItemClick(event) {
            this.topbarItemClick = true;
            this.topbarMenuActive = !this.topbarMenuActive;
            this.hideOverlayMenu();
            event.preventDefault();
        },
        onRightMenuButtonClick() {
            this.rightMenuClick = true;
            this.rightMenuActive = true;
        },
        onRightMenuClick() {
            this.rightMenuClick = true;
        },
        onMenuClick() {
            this.menuClick = true;
        },
        onRootMenuItemClick() {
            this.menuActive = !this.menuActive;
        },
        onMenuItemClick(event) {
            if (!event.item.items) {
                this.hideOverlayMenu();
                EventBus.emit('reset-active-index');
            }

            if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
                this.menuActive = false;
            }
        },
        onChangeMenuMode(menuMode) {
            this.$store.commit('changeMenuMode', menuMode);
            this.overlayMenuActive = false;
        },
        onConfigClick() {
            this.configClick = true;
        },
        onConfigButtonClick(event) {
            this.configActive = !this.configActive;
            this.configClick = true;
            event.preventDefault();
        },
        onChangeRightMenuActive(active) {
            this.rightMenuActive = active;
        },
        onChangeMenuTheme(theme) {
            this.$emit('change-menu-theme', theme);
        },
        onChangeComponentTheme(theme) {
            this.$emit('change-component-theme', theme);
        },
        onChangeColorScheme(scheme) {
            this.$emit('change-color-scheme', scheme);
        },
        onToggleMenu(event) {
            this.menuClick = true;

            if (this.overlayMenuActive) {
                this.overlayMenuActive = false;
            }

            if (this.sidebarActive) {
                this.sidebarStatic = !this.sidebarStatic;
            }

            event.preventDefault();
        },
        onChangeActiveInlineMenu() {
            this.activeInlineProfile = !this.activeInlineProfile;
        },
        blockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.add('blocked-scroll');
            } else {
                document.body.className += ' blocked-scroll';
            }
        },
        unblockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.remove('blocked-scroll');
            } else {
                document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        },
        isHorizontal() {
            return this.menuMode === 'horizontal';
        },
        isSlim() {
            return this.menuMode === 'slim';
        },
        isOverlay() {
            return this.menuMode === 'overlay';
        },
        isStatic() {
            return this.menuMode === 'static';
        },
        isSidebar() {
            return this.menuMode === 'sidebar';
        },
        isDesktop() {
            return window.innerWidth > 991;
        },
        isMobile() {
            return window.innerWidth <= 991;
        },
    },
    computed: {
        menuMode() {
            return this.$store.state.billing.menuMode;
        },
        layoutContainerClass() {
            return [
                'layout-wrapper',
                {
                    'layout-static': this.menuMode === 'static',
                    'layout-overlay': this.menuMode === 'overlay',
                    'layout-overlay-active': this.overlayMenuActive,
                    'layout-slim': this.menuMode === 'slim',
                    'layout-horizontal': this.menuMode === 'horizontal',
                    'layout-active': this.menuActive,
                    'layout-mobile-active': this.staticMenuMobileActive,
                    'layout-sidebar': this.menuMode === 'sidebar',
                    'layout-sidebar-static': this.menuMode === 'sidebar' && this.sidebarStatic,
                    'layout-static-inactive': this.staticMenuDesktopInactive && this.menuMode === 'static',
                    'p-ripple-disabled': this.$primevue.config.ripple === false,
                },
            ];
        },
        filteredMenu() {
            let x = this.$store.state.billing.user;
            if (x) {
                return this.menu.filter((item) => {
                    if (item.items) {
                        // item.items.filter((ele) => {
                        //     // console.log(ele.label,!ele.levels || ele.levels.includes(x.level));
                        //     return !ele.levels || ele.levels.includes(x.level);
                        // });
                        item.items.map((ele) => {
                            if (ele.levels && ele.levels.includes(x.level)) {
                                // console.log(ele);
                                ele.disabled = false;
                            }
                        });
                    }
                    return !item.level || item.level.includes(x.level);
                });
            } else {
                return this.menu;
            }
        },
    },
    components: {
        AppTopBar,
        AppMenu,
        // AppRightMenu,
        AppFooter,
        AppBreadcrumb,
        AppConfig,
    },
};
</script>
