<template>
    <div class="grid">
        <Button type="button" v-tooltip.top="'tambah data mahasiswa GAKIN'" icon="pi pi-plus" label="Tambah" class="p-button mb-2 mt-2 ml-2" @click="display = true" />
        <div class="col-12 lg:col-12">
            <Dialog header="Tambah Data Mahasiswa GAKIN" v-model:visible="display" :breakpoints="{ '960px': '75vw' }" :style="{ width: '40vw' }" :modal="true" @keydown.enter.prevent="register()" @hide="v$.$reset()">
                <div class="col-12 lg:col-12 p-fluid">
                    <span class="p-float-label mt-4">
                        <InputText id="NIM" type="text" styleClass="wid100" autofocus v-model.lazy="data.NIM" @blur="v$.data.NIM.$touch()" :class="{ 'p-invalid': v$.data.NIM.$invalid ? !v$.data.NIM.$anyError : null }" />
                        <label for="NIM">NIM</label>
                        <small v-if="v$.data.NIM.$invalid" :class="{ 'p-error': v$.data.NIM.$invalid ? !v$.data.NIM.$anyError : null }">{{ checkValidasi('NIM') }}</small>
                    </span>
                    <span class="p-float-label mt-4">
                        <Dropdown
                            id="prodi_id"
                            :options="prodi"
                            dataKey="value"
                            filter
                            optionLabel="text"
                            optionValue="value"
                            v-model.lazy="data.prodi_id"
                            @blur="v$.data.prodi_id.$touch()"
                            :class="{ 'p-invalid': v$.data.prodi_id.$invalid ? !v$.data.prodi_id.$anyError : null }"
                        />
                        <label for="prodi_id">Prodi</label>
                        <small v-if="v$.data.prodi_id.$invalid" :class="{ 'p-error': v$.data.prodi_id.$invalid ? !v$.data.prodi_id.$anyError : null }">{{ checkValidasi('prodi_id') }}</small>
                    </span>
                    <span class="p-float-label mt-4">
                        <Dropdown
                            id="tahun_angkatan"
                            :options="tahun"
                            dataKey="value"
                            filter
                            optionLabel="text"
                            optionValue="value"
                            v-model.lazy="data.tahun_angkatan"
                            @blur="v$.data.tahun_angkatan.$touch()"
                            :class="{ 'p-invalid': v$.data.tahun_angkatan.$invalid ? !v$.data.tahun_angkatan.$anyError : null }"
                        />
                        <label for="tahun_angkatan">Tahun Angkatan</label>
                        <small v-if="v$.data.tahun_angkatan.$invalid" :class="{ 'p-error': v$.data.tahun_angkatan.$invalid ? !v$.data.tahun_angkatan.$anyError : null }">{{ checkValidasi('tahun_angkatan') }}</small>
                    </span>
                    <span class="p-float-label mt-4">
                        <Dropdown
                            id="status_alih_jenjang"
                            :options="options_alih_jenjang"
                            dataKey="value"
                            filter
                            optionLabel="text"
                            optionValue="value"
                            v-model.lazy="data.status_alih_jenjang"
                            @blur="v$.data.status_alih_jenjang.$touch()"
                            :class="{ 'p-invalid': v$.data.status_alih_jenjang.$invalid ? !v$.data.status_alih_jenjang.$anyError : null }"
                        />
                        <label for="status_alih_jenjang">Status Biaya</label>
                        <small v-if="v$.data.status_alih_jenjang.$invalid" :class="{ 'p-error': v$.data.status_alih_jenjang.$invalid ? !v$.data.status_alih_jenjang.$anyError : null }">{{ checkValidasi('status_alih_jenjang') }}</small>
                    </span>
                    <span class="p-float-label mt-4">
                        <InputNumber
                            disabled
                            id="tagihan"
                            type="text"
                            styleClass="wid100"
                            autofocus
                            locale="id"
                            v-model.lazy="data.tagihan"
                            @blur="v$.data.tagihan.$touch()"
                            :class="{ 'p-invalid': v$.data.tagihan.$invalid ? !v$.data.tagihan.$anyError : null }"
                        />
                        <label for="tagihan">Tagihan</label>
                        <small v-if="v$.data.tagihan.$invalid" :class="{ 'p-error': v$.data.tagihan.$invalid ? !v$.data.tagihan.$anyError : null }">{{ checkValidasi('tagihan') }}</small>
                    </span>
                    <span class="p-float-label mt-4">
                        <InputNumber
                            id="nominal_beasiswa"
                            type="text"
                            styleClass="wid100"
                            autofocus
                            locale="id"
                            v-model.lazy="data.nominal_beasiswa"
                            @blur="v$.data.nominal_beasiswa.$touch()"
                            :class="{ 'p-invalid': v$.data.nominal_beasiswa.$invalid ? !v$.data.nominal_beasiswa.$anyError : null }"
                        />
                        <label for="nominal_beasiswa">Nominal Beasiswa</label>
                        <small v-if="v$.data.nominal_beasiswa.$invalid" :class="{ 'p-error': v$.data.nominal_beasiswa.$invalid ? !v$.data.nominal_beasiswa.$anyError : null }">{{ checkValidasi('nominal_beasiswa') }}</small>
                    </span>
                </div>
                <template #footer>
                    <Button label="Batal" :loading="busy" @click="display = false" icon="" class="p-button-secondary" />
                    <Button label="Simpan" :loading="busy" @click="register()" icon="" class="p-button-success" :disabled="!isValid" />
                </template>
            </Dialog>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, alphaNum, maxValue } from '@vuelidate/validators';
export default {
    props: ['kategoriBayar', 'prodi', 'tahun'],
    setup() {
        return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
    },
    emits: {
        alert: null,
    },
    data() {
        return {
            display: false,
            data: {
                NIM: null,
                tagihan: null,
                prodi_id: null,
                tahun_angkatan: null,
                nominal_beasiswa: null,
                status_alih_jenjang: null,
            },
            options_alih_jenjang: [
                { value: '0', text: 'Reguler' },
                { value: '1', text: 'Alih Jenjang' },
            ],

            list_kalender_bayar: [],
            tahun_ak: [],
            busy: false,
        };
    },
    validations() {
        return {
            data: {
                NIM: {
                    required,
                    alphaNum,
                },
                tagihan: {
                    required,
                },
                nominal_beasiswa: {
                    required,
                    maxValue: maxValue(this.data.tagihan ? this.data.tagihan : 1),
                },
                prodi_id: {
                    required,
                },
                tahun_angkatan: {
                    required,
                },
                status_alih_jenjang: {
                    required,
                },
            },
        };
    },
    computed: {
        formString() {
            return JSON.stringify(this.data);
        },
        isValid() {
            return !this.v$.$invalid;
        },
        isDirty() {
            return this.v$.$anyDirty;
        },
        req_tagihan() {
            return this.data.prodi_id != null && this.data.tahun_angkatan != null && this.data.status_alih_jenjang != null;
        },
        data_req() {
            let vm = this;
            return {
                prodi_id: vm.data.prodi_id,
                tahun_ak: vm.data.tahun_angkatan,
                status_alih_jenjang: vm.data.status_alih_jenjang,
                jumlah: 1,
                halaman: 1,
            };
        },
    },
    watch: {
        data_req(newV, oldV) {
            if (newV != oldV) {
                if (this.req_tagihan) {
                    this.getMasterBiayaSemester();
                }
            }
        },
    },
    mounted() {
        this.v$.$reset();
    },
    methods: {
        register() {
            let vm = this;
            vm.busy = true;
            if (vm.isValid && vm.isDirty) {
                vm.$axiosbilling
                    .post('mhs_gakin/register', vm.data)
                    .then((res) => {
                        console.log(res.data);
                        if (res.data.status == 200) {
                            if (res.data.message == 'sukses') {
                                vm.data.NIM = '';
                                vm.display = false;
                                vm.busy = false;
                                vm.$emit('alert', { severity: 'success', summary: 'Konfirmasi', detail: 'Berhasil Mendaftarkan Mahasiswa Beasiswa GAKIN', life: 3000 });
                            } else {
                                vm.busy = false;
                                vm.display = false;
                                vm.$emit('alert', { severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                            }
                        } else {
                            vm.busy = false;
                            if (res.data.status == 204) {
                                vm.$emit('alert', { severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                            }
                            vm.display = false;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        vm.busy = false;
                        vm.display = false;
                        vm.$emit('alert', { severity: 'error', summary: 'Konfirmasi', detail: 'Terjadi kesalahan pada server', life: 3000 });
                    });
            } else {
                vm.busy = false;
                vm.$emit('alert', { severity: 'error', summary: 'Peringatan', detail: 'Masih terdapat isian data yang tidak valid', life: 3000 });
            }
        },
        async getMasterBiayaSemester() {
            let vm = this;
            let res = await vm.$axiosbilling.post('masterBiayaSemester/list', vm.data_req);
            console.log(res, 'master biaya');
            if (res.data.data) {
                if (res.data.data.length) {
                    vm.data.tagihan = res.data.data[0].biaya;
                } else {
                    vm.data.status_alih_jenjang = null;
                    vm.$toast.add({ severity: 'error', summary: 'Peringatan', detail: 'Master Biaya Semester Tidak Ditemukan', life: 3000 });
                }
            } else {
                vm.data.status_alih_jenjang = null;
                vm.$toast.add({ severity: 'error', summary: 'Peringatan', detail: 'Master Biaya Semester Tidak Ditemukan', life: 3000 });
            }
        },
        // async getKategoriBiaya(x, y, z) {
        //     let vm = this;
        //     vm.tahun_ak = [];
        //     if (x && y && x) {
        //         let res = await vm.$axiosbilling('kategoriBiaya/listById' + `/${x}/${y}/${z}`);
        //         if (res.data.status == 200) {
        //             vm.tahun_ak = res.data.data.map((item) => {
        //                 return { ...item, value: item.tahun_akademik_id, text: item.tahun_ak };
        //             });
        //         }
        //     }
        // },
        async set_data(x) {
            let vm = this;
            await vm.tahun_ak.forEach((ele) => {
                if (x == ele.tahun_akademik_id) {
                    vm.data.tahun_akademik_id = ele.kategori_biaya_id;
                    vm.data.tagihan = ele.biaya_kb;
                    vm.data.tahun_kb = ele.tahun_kb;
                    vm.data.tahunsk_kb = ele.tahunsk_kb;
                    vm.data.tahun_akademik_id = ele.tahun_akademik_id;
                    vm.data.bayar_mulai = vm.$moment(ele.tanggal_pembukaan).format('LL');
                    vm.data.bayar_tutup = vm.$moment(ele.tanggal_penutupan).format('LL');
                    // console.log(ele.biaya_kb);
                }
            });
        },
        checkValidasi(fieldName) {
            const field = this.v$.data[fieldName];
            let x = field.$errors[0].$validator;
            if (x == 'required') {
                return '* Data wajib diisi';
            } else if (x == 'email') {
                return '* Data harus berbentuk email';
            } else if (x == 'numeric') {
                return '* Data harus terdiri hanxa dari angka';
            } else if (x == 'minLength') {
                return `* Data minimal ${field.$errors[0].$params.min} digits`;
            } else if (x == 'maxLength') {
                return `* Data maksimal ${field.$errors[0].$params.max} digits`;
            } else if (x == 'alphaNum') {
                return `* Data tidak boleh mengandung spasi atau simbol`;
            } else if (x == 'official') {
                return `* Data tidak boleh mengandung spasi atau simbol`;
            }
        },
    },
};
</script>
