<template>
    <div>
        <apexchart width="250px" height="250px" type="donut" :options="newOptions" :series="newSeries"></apexchart>
    </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts';
export default {
    components: {
        apexchart: VueApexCharts,
    },
    props: ['rekap'],
    computed: {
        terbayar() {
            let x = 0;
            this.rekap.forEach((el) => {
                x += el.terbayar;
            });
            return x;
        },
        total() {
            let x = 0;
            this.rekap.forEach((el) => {
                x += el.tagihan;
            });
            return x;
        },
        piutang() {
            let x = 0;
            this.rekap.forEach((el) => {
                x += el.tagihan - el.terbayar;
            });

            return x;
        },
        newOptions() {
            let x = [];
            for (let i = 0; i < this.rekap.length; i++) {
                x.push(this.rekap[i].nama_prodi);
            }

            return {
                chart: {
                    id: 'Chart Tagihan',
                },
                legend: {
                    position: 'bottom',
                },
                labels: ['Piutang', 'Terbayar'],
                title: {
                    text: '',
                    align: 'center',
                    offsetY: 10,
                },
                tooltip: {
                    fillSeriesColor: false,
                    y: {
                        show: true,
                        formatter: function (val) {
                            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                        },
                    },
                },
                plotOptions: {
                    pie: {
                        donut: {
                            size: '65%',
                        },
                    },
                },
            };
        },
        newSeries() {
            return [this.piutang, this.terbayar];
        },
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    id: 'Chart Tagihan',
                },
                xaxis: {
                    categories: [],
                },
            },
            series: [
                {
                    name: 'Belum Dibayar',
                    data: [],
                },
                {
                    name: 'Piutang',
                    data: [],
                },
                {
                    name: 'Total Tagihan',
                    data: [],
                },
            ],
        };
    },
};
</script>