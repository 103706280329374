import numeral from "numeral";

// load a locale
numeral.register('locale', 'id', {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    abbreviations: {
        thousand: 'rb',
        million: 'jt',
        billion: 'M',
        trillion: 'T'
    },
    currency: {
        symbol: 'Rp'
    }
});

// switch between locales
numeral.locale('id');

const formatter = numeral
formatter.locale('id')
formatter.defaultFormat('0,0.00')

export default formatter