// export const SSO_API = 'http://localhost:8101';
// export const SSO_URL = 'http://localhost:8080';

// export const SIMADU_API = 'http://localhost:8107';
// export const SIMADU_URL = 'http://localhost:8081';

// export const SIBILLING_API = 'http://localhost:8104';
// export const SIBILLING_URL = 'http://localhost:8083';

export const SSO_API = 'https://sso.poltekkes-smg.ac.id/api';
export const SSO_URL = 'https://sso.poltekkes-smg.ac.id';

export const SIMADU_API = 'https://neosimadu.poltekkes-smg.ac.id/api';
export const SIMADU_URL = 'https://neosimadu.poltekkes-smg.ac.id';

export const SIBILLING_API = 'https://billing.poltekkes-smg.ac.id/api'
export const SIBILLING_URL = 'https://billing.poltekkes-smg.ac.id'
