<template>
    <div class="grid">
        <Toast />
        <div class="col-12">
            <div class="card">
                <h5>User Access</h5>
                <div class="grid">
                    <div class="add"><Button type="button" icon="pi pi-plus" label="Tambah" class="p-button mb-2 mt-2 ml-2"
                            @click="modalInput = true" /></div>
                </div>
                <DataTable @page="pages($event)" :value="users" :paginator="true" class="p-datatable-gridlines"
                    :rows="perPage" v-model:filters="filters1" dataKey="id" :rowHover="true" filterDisplay="menu"
                    :loading="loading1" :filters="filters1" responsiveLayout="scroll" :globalFilterFields="field">
                    <template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <div class="add">
                                <Dropdown :options="perPages" id="Role" required="true" autofocus v-model.lazy="perPage" />
                                Per Halaman dari {{ total_users }} data
                            </div>
                            <div class="filter">
                                <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2"
                                    @click="clearFilter1()" />
                                <span class="p-input-icon-left mb-2">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filters1.global.value" placeholder="Keyword Search"
                                        style="width: 100%" />
                                </span>
                            </div>
                        </div>
                    </template>
                    <template #empty> Tidak ada data. </template>
                    <template #loading> Loading data. mohon tunggu.... </template>
                    <Column field="no" header="No" style="width: 50px">
                        <template #body="{ data }">
                            {{ data.no }}
                        </template>
                    </Column>
                    <Column field="identity_billing" header="Identity" style="min-width: 12rem" :sortable="true">
                        <template #body="{ data }">
                            {{ data.identity_billing }}
                        </template>
                    </Column>
                    <Column field="email" header="Email" style="min-width: 12rem" :sortable="true">
                        <template #body="{ data }">
                            {{ data.email }}
                        </template>
                    </Column>
                    <Column field="level" header="Level" style="min-width: 12rem" :sortable="true">
                        <template #body="{ data }">
                            {{ data.level }}
                        </template>
                    </Column>
                    <Column field="actions" header="Actions" bodyClass="text-center" style="min-width: 2rem">
                        <template #body="{ data }">
                            <div class="flex sm:flex-row">
                                <div class="mt-2">
                                    <Button type="button" icon="pi pi-pencil" label="" class="p-button-warning mt-2 ml-2"
                                        @click="editData(data)" />
                                </div>
                                <!-- <div class="mt-2">
                                    <Button type="button" icon="pi pi-trash" label="" class="p-button-danger mt-2 ml-2" @click="deleteData(data)" />
                                </div> -->
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>

            <!-- MODAL INPUT -->
            <Dialog header="Tambah Data users" v-model:visible="modalInput" :breakpoints="{ '960px': '75vw' }"
                :style="{ width: '40vw' }" :modal="true" @keydown.enter.prevent="register()" @hide="v$.$reset()">
                <div class="col-12 lg:col-12 p-fluid">
                    <span class="p-float-label mt-4">
                        <InputText id="name" required="true" autofocus v-model.lazy="data.identity_billing"
                            @blur="v$.data.identity_billing.$touch()"
                            :class="{ 'p-invalid': v$.data.identity_billing.$invalid ? !v$.data.identity_billing.$anyError : null }" />
                        <label for="name">Identity</label>
                        <small v-if="v$.data.identity_billing.$invalid"
                            :class="{ 'p-error': v$.data.identity_billing.$invalid ? !v$.data.identity_billing.$anyError : null }">{{
                                checkValidasi('data', 'identity_billing') }}</small>
                    </span>
                    <span class="p-float-label mt-4">
                        <InputText id="name" required="true" autofocus v-model.lazy="data.email"
                            @blur="v$.data.email.$touch()"
                            :class="{ 'p-invalid': v$.data.email.$invalid ? !v$.data.email.$anyError : null }" />
                        <label for="name">Email</label>
                        <small v-if="v$.data.email.$invalid"
                            :class="{ 'p-error': v$.data.email.$invalid ? !v$.data.email.$anyError : null }">{{
                                checkValidasi('data', 'email') }}</small>
                    </span>
                    <span class="p-float-label mt-4">
                        <InputText id="name" required="true" autofocus v-model.lazy="data.level"
                            @blur="v$.data.level.$touch()"
                            :class="{ 'p-invalid': v$.data.level.$invalid ? !v$.data.level.$anyError : null }" />
                        <label for="name">Level</label>
                        <small v-if="v$.data.level.$invalid"
                            :class="{ 'p-error': v$.data.level.$invalid ? !v$.data.level.$anyError : null }">{{
                                checkValidasi('data', 'level') }}</small>
                    </span>
                </div>
                <template #footer>
                    <Button label="Batal" @click="modalInput = false" icon="" class="p-button-secondary" />
                    <Button label="Simpan" @click="register()" icon="" class="p-button-success" :disabled="!isValid" />
                </template>
            </Dialog>

            <!-- MODAL EDIT -->
            <Dialog header="Edit Data users" v-model:visible="modalEdit" :breakpoints="{ '960px': '75vw' }"
                :style="{ width: '40vw' }" :modal="true" @keydown.enter.prevent="simpanEdit()" @hide="v$.$reset()">
                <div class="col-12 lg:col-12 p-fluid">
                    <span class="p-float-label mt-4">
                        <InputText id="name" required="true" autofocus v-model.lazy="dataEdit.identity_billing"
                            @blur="v$.dataEdit.identity_billing.$touch()"
                            :class="{ 'p-invalid': v$.dataEdit.identity_billing.$invalid ? !v$.dataEdit.identity_billing.$anyError : null }" />
                        <label for="name">Identity</label>
                        <small v-if="v$.dataEdit.identity_billing.$invalid"
                            :class="{ 'p-error': v$.dataEdit.identity_billing.$invalid ? !v$.dataEdit.identity_billing.$anyError : null }">{{
                                checkValidasi('dataEdit', 'identity_billing') }}</small>
                    </span>
                    <span class="p-float-label mt-4">
                        <InputText id="name" required="true" autofocus v-model.lazy="dataEdit.email"
                            @blur="v$.dataEdit.email.$touch()"
                            :class="{ 'p-invalid': v$.dataEdit.email.$invalid ? !v$.dataEdit.email.$anyError : null }" />
                        <label for="name">Email</label>
                        <small v-if="v$.dataEdit.email.$invalid"
                            :class="{ 'p-error': v$.dataEdit.email.$invalid ? !v$.dataEdit.email.$anyError : null }">{{
                                checkValidasi('dataEdit', 'email') }}</small>
                    </span>
                    <span class="p-float-label mt-4">
                        <InputText id="name" required="true" autofocus v-model.lazy="dataEdit.level"
                            @blur="v$.dataEdit.level.$touch()"
                            :class="{ 'p-invalid': v$.dataEdit.level.$invalid ? !v$.dataEdit.level.$anyError : null }" />
                        <label for="name">Level</label>
                        <small v-if="v$.dataEdit.level.$invalid"
                            :class="{ 'p-error': v$.dataEdit.level.$invalid ? !v$.dataEdit.level.$anyError : null }">{{
                                checkValidasi('dataEdit', 'level') }}</small>
                    </span>

                </div>
                <template #footer>
                    <Button label="Batal" @click="modalEdit = false" icon="" class="p-button-secondary" />
                    <Button label="Simpan" @click="simpanEdit()" icon="" class="p-button-success"
                        :disabled="!isValidEdit" />
                </template>
            </Dialog>
        </div>
    </div>
</template>
<script>
import { FilterMatchMode } from 'primevue/api';
import useVuelidate from '@vuelidate/core';
import { required, maxLength } from '@vuelidate/validators';
export default {
    setup() {
        return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
    },
    data() {
        return {
            users: [],
            total_users: null,
            field: ['nama_jenis_biaya'],
            loading1: true,
            modalInput: false,
            modalEdit: false,
            data: {
                identity_billing: null,
                email: null,
                level:null,
                prodi_id: null
            },
            dataEdit: {},
            filters1: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            perPage: 10,
            perPages: [10, 25, 50, 100],
        };
    },
    validations() {
        return {
            data: {
                identity_billing: {
                    required,
                    maxLength: maxLength(50),
                },
                email: {
                    required,
                    maxLength: maxLength(50),
                },
                level: {
                    required,
                    maxLength: maxLength(50),
                },
            },
            dataEdit: {
                identity_billing: {
                    required,
                    maxLength: maxLength(50),
                },
                email: {
                    required,
                },
                level: {
                    required,
                },
            },
        };
    },
    computed: {
        formString() {
            return JSON.stringify(this.data);
        },
        isValid() {
            return !this.v$.data.$invalid;
        },
        isDirty() {
            return this.v$.data.$anyDirty;
        },
        isValidEdit() {
            return !this.v$.dataEdit.$invalid;
        },
        isDirtyEdit() {
            return this.v$.dataEdit.$anyDirty;
        },
    },
    mounted() {
        this.get_users();
        this.v$.$reset();
        // this.initFilters();
    },
    methods: {
        clearFilter1() {
            this.filters1 = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        pages(x) {
            console.log(x);
        },
        initFilters1() {
            this.filters1 = {
                nama_jenis_biaya: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        async get_users() {
            let vm = this;
            vm.loading1 = true;
            let users = await vm.$axiosbilling.post('masterUser/list', { halaman: 1, jumlah: 10 });
            console.log(users.data.data, 'data user');
            vm.users = users.data.data.map((items, idx) => {
                return { ...items, no: idx + 1 };
            });
            vm.total_users = vm.users.length
            vm.loading1 = false;
        },
        register() {
            let vm = this;
            if (vm.isValid && vm.isDirty) {
                console.log(vm.data)
                vm.$axiosbilling
                    .post('masterUser/register', vm.data)
                    .then((res) => {
                        console.log(res.data.status);
                        if (res.data.status == 200) {
                            if (res.data.message == 'sukses') {
                                vm.data = {
                                    nama_jenis_biaya: null,
                                };
                                vm.modalInput = false;
                                vm.$toast.add({ severity: 'success', summary: 'Konfirmasi', detail: 'Berhasil Mendaftarkan users', life: 3000 });
                                this.get_users();
                            } else {
                                vm.modalInput = false;
                                vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                            }
                        } else {
                            vm.modalInput = false;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        vm.modalInput = false;
                        vm.$toast.add({ severity: 'error', summary: 'Konfirmasi', detail: 'Terjadi kesalahan pada server', life: 3000 });
                    });
            } else {
                vm.$toast.add({ severity: 'error', summary: 'Peringatan', detail: 'Masih terdapat isian data yang tidak valid', life: 3000 });
            }
        },
        editData(data) {
            let vm = this;
            vm.dataEdit = data;
            vm.modalEdit = true;
            console.log(vm.dataEdit, 'ini edit data', data);
        },
        simpanEdit() {
            let vm = this;
            if (vm.isValidEdit) {
                vm.$axiosbilling
                    .post('masterUser/update', vm.dataEdit)
                    .then((res) => {
                        console.log(res.data.status);
                        if (res.data.status == 200) {
                            if (res.data.message == 'sukses') {
                                vm.modalEdit = false;
                                vm.$toast.add({ severity: 'success', summary: 'Konfirmasi', detail: 'Berhasil Mengupdate Data users', life: 3000 });
                                this.get_users();
                            } else {
                                vm.modalEdit = false;
                                vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                            }
                        } else {
                            vm.modalEdit = false;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        vm.modalEdit = false;
                        vm.$toast.add({ severity: 'error', summary: 'Konfirmasi', detail: 'Terjadi kesalahan pada server', life: 3000 });
                    });
            } else {
                vm.$toast.add({ severity: 'error', summary: 'Peringatan', detail: 'Masih terdapat isian data yang tidak valid', life: 3000 });
            }
        },
        simpanDelete(data) {
            let vm = this;
            vm.$axiosbilling
                .post('masterUser/delete', { id: data.id })
                .then((res) => {
                    console.log(res.data.status);
                    if (res.data.status == 200) {
                        if (res.data.message == 'sukses') {
                            vm.$toast.add({ severity: 'success', summary: 'Konfirmasi', detail: 'Berhasil Menghapus Data users', life: 3000 });
                            this.get_users();
                            this.$confirm.close();
                        } else {
                            vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                            this.$confirm.close();
                        }
                    } else {
                        vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: 'data sudah ada', life: 3000 });
                        this.$confirm.close();
                    }
                })
                .catch((err) => {
                    console.log(err);
                    vm.$toast.add({ severity: 'error', summary: 'Konfirmasi', detail: 'Terjadi kesalahan pada server', life: 3000 });
                    this.$confirm.close();
                });
        },
        deleteData(data) {
            // console.log(data, 'ini datanya bossss');
            this.$confirm.require({
                message: 'Apakah anda yakin ingin menghapus ini?',
                header: 'Konfirmasi',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.simpanDelete(data);
                },
                reject: () => {
                    this.$confirm.close();
                },
            });
        },
        checkValidasi(type, fieldName) {
            const field = this.v$[type][fieldName];
            let x = field.$errors[0].$validator;
            if (x == 'required') {
                return '* Data wajib diisi';
            } else if (x == 'email') {
                return '* Data harus berbentuk email';
            } else if (x == 'numeric') {
                return '* Data harus terdiri hanxa dari angka';
            } else if (x == 'minLength') {
                return `* Data minimal ${field.$errors[0].$params.min} digits`;
            } else if (x == 'maxLength') {
                return `* Data maksimal ${field.$errors[0].$params.max} digits`;
            } else if (x == 'alphaNum') {
                return `* Data tidak boleh mengandung spasi atau simbol`;
            } else if (x == 'official') {
                return `* Data tidak boleh mengandung spasi atau simbol`;
            }
        },
    },
};
</script>
<style scoped lang="scss">@import '@/assets/demo/styles/badges.scss';

::v-deep(.p-datatable-frozen-tbody) {
    font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
    font-weight: bold;
}

::v-deep(.p-progressbar) {
    height: 0.5rem;
    background-color: #d8dadc;

    .p-progressbar-value {
        background-color: #607d8b;
    }
}</style>
