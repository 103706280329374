<template>
    <div class="grid">
        <div class="col-12">
            <Toast />
            <div class="grid" @keydown.enter.prevent="get_return()">
                <div class="col">
                    <div class="card">
                        <!-- <div class="grid">
                            <div class="col">
                                <ModalInput @alert="set($event)" :kategoriBayar="kategoriBayar" :prodi="prodi" />
                            </div>
                        </div> -->
                        <div class="grid p-fluid">
                            <div class="col-6 sm:col-3">
                                <span class="p-float-label mt-4">
                                    <InputText id="NIM_DTS" required="true" autofocus v-model.lazy="fetch_data.NIM" />
                                    <label for="NIM_DTS">NIM</label>
                                </span>
                            </div>
                            <div class="col-6 sm:col-3">
                                <span class="p-float-label mt-4">
                                    <Dropdown dataEditKey="value" filter id="master_bank_id" v-model.lazy="fetch_data.master_bank_id" :options="list_bank" optionLabel="text" optionValue="value" />
                                    <label for="master_bank_id">Bank</label>
                                </span>
                            </div>
                            <div class="col-6 sm:col-3">
                                <span class="p-float-label mt-4">
                                    <InputText id="tahun_kb" required="true" autofocus v-model.lazy="fetch_data.tahun_kb" />
                                    <label for="tahun_kb">Tahun KB (Contoh: 2022-1)</label>
                                </span>
                            </div>
                            <div class="col-6 sm:col-3">
                                <span class="p-float-label mt-4">
                                    <Dropdown dataEditKey="value" filter id="tahun_penetapan" v-model.lazy="fetch_data.tahun_ak" :options="periode_tagihan" optionLabel="text" optionValue="value" />
                                    <label for="tahun_penetapan">Tahun Angkatan</label>
                                </span>
                            </div>
                        </div>
                        <!-- <div class="grid p-fluid"> -->
                        <!-- <div class="col-4">
                                <span class="p-float-label mt-4">
                                    <Dropdown dataKey="value" filter id="prodi_id" v-model.lazy="fetch_data.prodi_id" :options="prodi" optionLabel="text" optionValue="value" />
                                    <label for="prodi_id">Prodi</label>
                                </span>
                            </div>
                            <div class="col-4">
                                <span class="p-float-label mt-4">
                                    <Dropdown dataEditKey="value" filter id="tahun_penetapan" v-model.lazy="fetch_data.kategori_biaya_id" :options="jenis" optionLabel="text" optionValue="value" />
                                    <label for="tahun_penetapan">Kategori Biaya</label>
                                </span>
                            </div> -->

                        <!-- </div> -->
                        <!-- <div class="grid p-fluid">
                            <div class="col-4">
                                <span class="p-float-label mt-4">
                                    <Dropdown dataEditKey="value" filter id="status_kalender_bayar" v-model.lazy="fetch_data.status_kalender_bayar" :options="option_status_kalender_bayar" optionLabel="text" optionValue="value" />
                                    <label for="status_kalender_bayar">Status Kalender Bayar</label>
                                </span>
                            </div>
                            <div class="col-4">
                                <span class="p-float-label mt-4">
                                    <Dropdown dataEditKey="value" filter id="kalender_bayar_id" v-model.lazy="fetch_data.kalender_bayar_id" :options="periode" optionLabel="text" optionValue="value" />
                                    <label for="kalender_bayar_id">Status Kalender Bayar</label>
                                </span>
                            </div>
                            <div class="col-4">
                                <span class="p-float-label mt-4">
                                    <Dropdown id="tahun_akademik_id" filter required="true" :options="tahun" optionLabel="text" optionValue="value" v-model.lazy="fetch_data.tahun_akademik_id" />
                                    <label for="tahun_akademik_id">Tahun Tagihan</label>
                                </span>
                            </div>
                        </div> -->
                        <div class="grid p-fluid">
                            <div class="col-5">
                                <span class="p-float-label mt-4">
                                    <Calendar :showIcon="true" :showButtonBar="true" dateFormat="yy-mm-dd" :disabled="!checked" v-model.lazy="data.tanggal_awal"></Calendar>
                                    <label for="tanggal_awal">Tanggal Awal</label>
                                </span>
                            </div>
                            <div class="col-5">
                                <span class="p-float-label mt-4">
                                    <Calendar :showIcon="true" :showButtonBar="true" dateFormat="yy-mm-dd" :disabled="!checked" v-model.lazy="data.tanggal_akhir"></Calendar>
                                    <label for="tanggal_akhir">Tanggal Akhir</label>
                                </span>
                            </div>
                            <div class="col-1 mt-2">
                                <span class="p-float-label mt-4 text-right"><InputSwitch v-model="checked" /></span>
                            </div>
                            <div class="col-1 mt-2">
                                <span class="p-float-label mt-4 text-left">Masukkan Tanggal</span>
                            </div>
                        </div>
                        <div class="grid mt-4">
                            <div class="col-6">
                                <div class="grid p-fluid">
                                    <div class="col-12 sm:col-4">
                                        <Button icon="pi pi-plus" label="Tambah Data" class="p-button p-button-primary" @click="modalInput = true" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 text-right">
                                <div class="grid p-fluid">
                                    <div class="col-6 sm:col-4">
                                        <Button class="p-button p-button-success" label="Search" @click="get_return()" />
                                    </div>
                                    <div class="col-6 sm:col-4">
                                        <Button class="p-button p-button-primary" label="Reset" @click="reset()" />
                                    </div>
                                    <div class="col-4">
                                        <Button class="p-button p-button-warning" label="Excel" @click="downloadExcel()" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card">
                    <h5>List Pengembalian Dana Mahasiswa</h5>
                    <DataTable
                        :value="list_pengembalian"
                        class="p-datatable-gridlines"
                        :rows="perPage"
                        v-model:filters="filters1"
                        dataKey="id"
                        :rowHover="true"
                        filterDisplay="menu"
                        :loading="loading1"
                        :filters="filters1"
                        responsiveLayout="scroll"
                        :globalFilterFields="field"
                    >
                        <template #header>
                            <div class="flex justify-content-between flex-column sm:flex-row">
                                <div class="add"><Dropdown :options="perPages" id="Role" required="true" autofocus v-model.lazy="fetch_data.jumlah" @change="set_jumlah($event)" /> Per Halaman dari {{ total_pengembalian }} data</div>
                                <div class="filter">
                                    <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2" @click="clearFilter1()" />
                                    <span class="p-input-icon-left mb-2">
                                        <i class="pi pi-search" />
                                        <InputText v-model="filters1.global.value" placeholder="Keyword Search" style="width: 100%" />
                                    </span>
                                </div>
                            </div>
                        </template>
                        <template #empty> Tidak ada data. </template>
                        <template #loading> Loading data. mohon tunggu.... </template>
                        <Column field="no" header="No" style="width: 50px">
                            <template #body="{ data }">
                                {{ data.no }}
                            </template>
                        </Column>
                        <Column field="actions" header="Actions" style="min-width: 2rem">
                            <template #body="{ data }">
                                <div class="flex sm:flex-row">
                                    <div class="mt-2">
                                        <Button type="button" icon="pi pi-pencil" label="" class="p-button-warning mr-2" @click="editData(data)" />
                                    </div>
                                    <div class="mt-2">
                                        <ModalUpload :data="data" @alert="get_return()" />
                                    </div>
                                </div>
                            </template>
                        </Column>
                        <Column field="NIM" header="NIM" style="min-width: 12rem" :sortable="true">
                            <template #body="{ data }">
                                {{ data.NIM_PTS }}
                            </template>
                        </Column>
                        <Column field="nama_mahasiswa" header="Nama Mahasiswa" style="min-width: 12rem" :sortable="true">
                            <template #body="{ data }">
                                {{ data.nama_mahasiswa }}
                            </template>
                        </Column>
                        <Column field="nama_master_bank" header="Nama Bank" style="min-width: 12rem" :sortable="true">
                            <template #body="{ data }">
                                {{ data.nama_master_bank }}
                            </template>
                        </Column>
                        <Column field="tanggal_pengembalian" header="Tanggal TRF" style="min-width: 12rem" :sortable="true">
                            <template #body="{ data }">
                                {{ $moment(data.tanggal_pengembalian).format('ll') }}
                            </template>
                        </Column>
                        <Column field="jumlah_pengembalian" header="Jumlah Pengembalian" style="min-width: 12rem; text-align: right" :sortable="true">
                            <template #body="{ data }">
                                {{ $formats(data.jumlah_pengembalian).format() }}
                            </template>
                        </Column>
                        <Column field="nama_kategori" header="Nama Bank" style="min-width: 12rem" :sortable="true">
                            <template #body="{ data }">
                                {{ data.nama_kategori }}
                            </template>
                        </Column>
                        <template #footer>
                            <Paginator :rows="fetch_data.jumlah" :totalRecords="total_pengembalian" @page="onPage($event)"></Paginator>
                        </template>
                    </DataTable>
                </div>

                <!-- MODAL INPUT -->
                <Dialog header="Tambah Data Pengembalian Dana Mahasiswa" v-model:visible="modalInput" :breakpoints="{ '960px': '75vw' }" :style="{ width: '40vw' }" :modal="true" @keydown.enter.prevent="register()" @hide="v$.$reset()">
                    <div class="col-12 lg:col-12 p-fluid">
                        <span class="p-float-label mt-4">
                            <InputText id="name" required="true" autofocus v-model.lazy="data_pts.NIM_PTS" />
                            <label for="name">NIM</label>
                        </span>
                    </div>
                    <div class="col-12 lg:col-12 p-fluid">
                        <span class="p-float-label mt-4">
                            <Dropdown
                                id="selected_pembayaran"
                                filter
                                required="true"
                                :options="list_pts"
                                optionLabel="text"
                                optionValue="value"
                                v-model.lazy="data.selected_pembayaran"
                                @change="set_pts($event)"
                                @blur="v$.data.selected_pembayaran.$touch()"
                                :class="{ 'p-invalid': v$.data.selected_pembayaran.$invalid ? !v$.data.selected_pembayaran.$anyError : null }"
                            />
                            <label for="selected_pembayaran">Pembayaran</label>
                        </span>
                    </div>
                    <div class="col-12 lg:col-12 p-fluid">
                        <span class="p-float-label mt-4">
                            <InputText id="name" disabled required="true" autofocus v-model.lazy="data.nama_mahasiswa" />
                            <label for="name">Nama</label>
                        </span>
                    </div>
                    <div class="col-12 lg:col-12 p-fluid">
                        <span class="p-float-label mt-4">
                            <InputText id="name" disabled required="true" autofocus v-model.lazy="data.nominal_pembayaran" />
                            <label for="name">Total Pembayaran</label>
                        </span>
                    </div>
                    <div class="col-12 lg:col-12 p-fluid">
                        <span class="p-float-label mt-4">
                            <InputText id="name" disabled required="true" autofocus v-model.lazy="data.tanggal_penagihan" />
                            <label for="name">Tanggal Tagihan</label>
                        </span>
                    </div>
                    <div class="col-12 lg:col-12 p-fluid">
                        <span class="p-float-label mt-4">
                            <InputText id="name" disabled required="true" autofocus v-model.lazy="data.tanggal_pembayaran" />
                            <label for="name">Tanggal Pembayaran</label>
                        </span>
                    </div>
                    <div class="col-12 lg:col-12 p-fluid">
                        <span class="p-float-label mt-4">
                            <Dropdown
                                id="master_bank_id"
                                filter
                                dataEditKey="value"
                                required="true"
                                :options="list_bank"
                                optionLabel="text"
                                optionValue="value"
                                v-model.lazy="data.master_bank_id"
                                @blur="v$.data.master_bank_id.$touch()"
                                :class="{ 'p-invalid': v$.data.master_bank_id.$invalid ? !v$.data.master_bank_id.$anyError : null }"
                            />
                            <label for="master_bank_id">Bank</label>
                            <small v-if="v$.data.master_bank_id.$invalid" :class="{ 'p-error': v$.data.master_bank_id.$invalid ? !v$.data.master_bank_id.$anyError : null }">{{ checkValidasi('data', 'master_bank_id') }}</small>
                        </span>
                    </div>
                    <!-- </div> -->
                    <div class="col-12 lg:col-12 p-fluid">
                        <span class="p-float-label mt-4">
                            <InputNumber
                                id="jumlah_pengembalian"
                                required="true"
                                autofocus
                                v-model.lazy="data.jumlah_pengembalian"
                                @blur="v$.data.jumlah_pengembalian.$touch()"
                                :class="{ 'p-invalid': v$.data.jumlah_pengembalian.$invalid ? !v$.data.jumlah_pengembalian.$anyError : null }"
                            />
                            <label for="jumlah_pengembalian">Total Pengembalian</label>
                            <small v-if="v$.data.jumlah_pengembalian.$invalid" :class="{ 'p-error': v$.data.jumlah_pengembalian.$invalid ? !v$.data.jumlah_pengembalian.$anyError : null }">{{ checkValidasi('data', 'jumlah_pengembalian') }}</small>
                        </span>
                    </div>
                    <div class="col-12 lg:col-12 p-fluid">
                        <span class="p-float-label mt-4">
                            <Calendar id="tanggal_pengembalian" :showIcon="true" :showButtonBar="true" dateFormat="yy-mm-dd" v-model.lazy="data.tanggal_pengembalian"></Calendar>
                            <label for="tanggal_pengembalian">Tanggal Pengembalian</label>
                            <small v-if="v$.data.tanggal_pengembalian.$invalid" :class="{ 'p-error': v$.data.tanggal_pengembalian.$invalid ? !v$.data.tanggal_pengembalian.$anyError : null }">{{ checkValidasi('data', 'tanggal_pengembalian') }}</small>
                        </span>
                    </div>
                    <template #footer>
                        <Button label="Batal" @click="modalInput = false" icon="" class="p-button-secondary" />
                        <Button label="Simpan" @click="register()" icon="" class="p-button-success" :disabled="!isValid" />
                    </template>
                </Dialog>

                <!-- MODAL EDIT -->
                <Dialog header="Edit Data Pengembalian Dana Mahasiswa" v-model:visible="modalEdit" :breakpoints="{ '960px': '75vw' }" :style="{ width: '40vw' }" :modal="true" @keydown.enter.prevent="simpanEdit()" @hide="v$.$reset()">
                    <div class="col-12 lg:col-12 p-fluid">
                        <span class="p-float-label mt-4">
                            <InputText id="name" required="true" disabled autofocus v-model.lazy="dataEdit.NIM_PTS" />
                            <label for="name">NIM</label>
                        </span>
                    </div>
                    <div class="col-12 lg:col-12 p-fluid">
                        <span class="p-float-label mt-4">
                            <InputText id="name" required="true" disabled autofocus v-model.lazy="dataEdit.nama_mahasiswa" />
                            <label for="name">Nama</label>
                        </span>
                    </div>
                    <div class="col-12 lg:col-12 p-fluid">
                        <span class="p-float-label mt-4">
                            <InputText id="name" required="true" disabled autofocus v-model.lazy="dataEdit.nama_kategori" />
                            <label for="name">Pembayaran</label>
                        </span>
                    </div>

                    <div class="col-12 lg:col-12 p-fluid">
                        <span class="p-float-label mt-4">
                            <Dropdown
                                id="master_bank_id"
                                filter
                                required="true"
                                :options="list_bank"
                                optionLabel="text"
                                optionValue="value"
                                v-model.lazy="dataEdit.master_bank_id"
                                @blur="v$.dataEdit.master_bank_id.$touch()"
                                :class="{ 'p-invalid': v$.dataEdit.master_bank_id.$invalid ? !v$.dataEdit.master_bank_id.$anyError : null }"
                            />
                            <label for="master_bank_id">Bank</label>
                            <small v-if="v$.dataEdit.master_bank_id.$invalid" :class="{ 'p-error': v$.dataEdit.master_bank_id.$invalid ? !v$.dataEdit.master_bank_id.$anyError : null }">{{ checkValidasi('dataEdit', 'master_bank_id') }}</small>
                        </span>
                    </div>
                    <!-- </div> -->
                    <div class="col-12 lg:col-12 p-fluid">
                        <span class="p-float-label mt-4">
                            <InputNumber
                                id="name"
                                required="true"
                                autofocus
                                v-model.lazy="dataEdit.jumlah_pengembalian"
                                @blur="v$.dataEdit.jumlah_pengembalian.$touch()"
                                :class="{ 'p-invalid': v$.dataEdit.jumlah_pengembalian.$invalid ? !v$.dataEdit.jumlah_pengembalian.$anyError : null }"
                            />
                            <label for="name">Jumlah Pengembalian</label>
                            <small v-if="v$.dataEdit.jumlah_pengembalian.$invalid" :class="{ 'p-error': v$.dataEdit.jumlah_pengembalian.$invalid ? !v$.dataEdit.jumlah_pengembalian.$anyError : null }">{{
                                checkValidasi('dataEdit', 'jumlah_pengembalian')
                            }}</small>
                        </span>
                    </div>
                    <div class="col-12 lg:col-12 p-fluid">
                        <span class="p-float-label mt-4">
                            <Calendar id="tanggal_pengembalian" :showIcon="true" :showButtonBar="true" dateFormat="yy-mm-dd" v-model.lazy="dataEdit.tanggal_pengembalian"></Calendar>
                            <label for="tanggal_pengembalian">Tanggal Pengembalian</label>
                            <small v-if="v$.dataEdit.tanggal_pengembalian.$invalid" :class="{ 'p-error': v$.dataEdit.tanggal_pengembalian.$invalid ? !v$.dataEdit.tanggal_pengembalian.$anyError : null }">{{
                                checkValidasi('dataEdit', 'tanggal_pengembalian')
                            }}</small>
                        </span>
                    </div>
                    <template #footer>
                        <Button label="Batal" @click="modalEdit = false" icon="" class="p-button-secondary" />
                        <Button label="Simpan" @click="simpanEdit()" icon="" class="p-button-success" :disabled="!isValidEdit" />
                    </template>
                </Dialog>
            </div>
        </div>
    </div>
</template>
<script>
import { FilterMatchMode } from 'primevue/api';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import ModalUpload from './modal_upload.vue';
export default {
    setup() {
        return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
    },
    components: {
        ModalUpload,
    },
    
    data() {
        return {
            list_pengembalian: [],
            total_pengembalian: 0,
            list_bank: [],
            list_pts: [],
            checked: false,
            tahun: [],
            prodi: [],
            periode: [],
            kategori_bayar: [],
            periode_tagihan: [],
            field: ['pembayaran_tagihan_studi_id', 'nama_master_bank', 'jumlah_pengembalian', 'nama_mahasiswa', 'NIM_PTS'],
            loading1: true,
            modalInput: false,
            modalEdit: false,
            data: {
                pembayaran_tagihan_studi_id: null,
                tanggal_pengembalian: null,
                jumlah_pengembalian: null,
                master_bank_id: null,
                nominal_pembayaran: null,
                nama_mahasiswa: null,
                tanggal_pembayaran: null,
                tanggal_penagihan: null,
                file1: null,
                selected_pembayaran: null,
            },
            option_status_kalender_bayar: [
                { value: '0', text: 'Existing' },
                { value: '1', text: 'Changed' },
            ],
            option_status_tagihan: [
                { value: '0', text: 'Belum' },
                { value: '1', text: 'Lunas' },
                { value: '2', text: 'Dispensasi' },
            ],
            option_beasiswa: [
                { value: '0', text: 'Tidak' },
                { value: '1', text: 'Iya' },
            ],
            option_semester: [
                { text: 'Ganjil', value: 'GANJIL' },
                { text: 'Genap', value: 'GENAP' },
            ],
            fetch_data: {
                jumlah: 10,
                halaman: 1,
                NIM: null,
                tanggal_awal: null,
                tanggal_akhir: null,
                tahun_ak: null,
                tahun_kb: null,
                master_bank_id: null,
                pengembalian_dana_id: null,
                pembayaran_tagihan_id: null,
            },
            dataEdit: {},
            filters1: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            data_pts: {
                jumlah: 100,
                halaman: 1,
                NIM_PTS: null,
            },
            perPage: 10,
            perPages: [10, 25, 50, 100],
        };
    },
    validations() {
        return {
            data: {
                pembayaran_tagihan_studi_id: {
                    required,
                },
                master_bank_id: {
                    required,
                },
                jumlah_pengembalian: {
                    required,
                },
                tanggal_pengembalian: {
                    required,
                },
                selected_pembayaran: {
                    required,
                },
            },
            dataEdit: {
                pembayaran_tagihan_studi_id: {
                    required,
                },
                master_bank_id: {
                    required,
                },
                jumlah_pengembalian: {
                    required,
                },
                tanggal_pengembalian: {
                    required,
                },
            },
        };
    },
    watch: {
        'data_pts.NIM_PTS': function (newV, oldV) {
            if (oldV != newV) {
                if (newV.length > 7) {
                    this.get_pts();
                }
            }
        },
    },
    computed: {
        formString() {
            return JSON.stringify(this.data);
        },
        isValid() {
            return !this.v$.data.$invalid;
        },
        isDirty() {
            return this.v$.data.$anyDirty;
        },
        isValidEdit() {
            return !this.v$.dataEdit.$invalid;
        },
        isDirtyEdit() {
            return this.v$.dataEdit.$anyDirty;
        },
    },
    mounted() {
        this.get_bank();
        this.get_return();
        this.v$.$reset();
        // this.initFilters1();
    },
    methods: {
        clearFilter1() {
            this.filters1 = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        onPage(x) {
            this.fetch_data.halaman = x.page + 1;
            this.get_return();
        },
        set_jumlah(x) {
            this.fetch_data.jumlah = x.value;
            this.fetch_data.halaman = 1;
            this.get_return();
        },
        set_pts(event) {
            let vm = this;
            let x = event.value;
            vm.data.pembayaran_tagihan_studi_id = x.penjualanTagihanStudiId;
            vm.data.nama_mahasiswa = x.nama_mahasiswa;
            vm.data.nominal_pembayaran = x.total_dibayar;
            vm.data.tanggal_pembayaran = vm.$moment(x.tanggal_dibayar).format('ll');
            vm.data.tanggal_penagihan = vm.$moment(x.tanggal_penagihan).format('ll');
        },
        async get_bank() {
            let vm = this;
            vm.loading1 = true;
            let bank = await vm.$axiosbilling.post('masterBank/list');
            console.log(bank.data, 'data bank');
            if (bank.data.message == 'sukses') {
                vm.list_bank = bank.data.data.map((items) => {
                    return { ...items, value: items.id, text: items.nama_master_bank };
                });
            }

            // let prodi = await vm.$axiosbilling('prodi/listHalaman');
            // if (prodi.data.message == 'sukses') {
            //     vm.prodi = prodi.data.data.map((item) => {
            //         return { ...item, value: item.id, text: item.nama_prodi };
            //     });
            // }

            // let jenis = await vm.$axiosbilling('kategoriBiaya/listHalaman');
            // console.log(jenis.data.data[0]);
            // if (jenis.data.message == 'sukses') {
            //     vm.jenis = jenis.data.data.map((item) => {
            //         return { ...item, value: item.id, text: item.jenis_kategori_biaya + ' - ' + item.nama_prodi };
            //     });
            // }

            // let tahun = await vm.$axiosbilling('tahunAkademik/listHalaman');
            // if (tahun.data.message == 'sukses') {
            //     // console.log(tahun);
            //     vm.tahun = tahun.data.data.map((item) => {
            //         return { ...item, value: item.id, text: item.tahun_ak };
            //     });
            // }

            // let kalenderBayar = await vm.$axiosbilling('kalenderBayar/listHalaman');
            // if (kalenderBayar.data.message == 'sukses') {
            //     vm.periode = kalenderBayar.data.data.map((item) => {
            //         return { ...item, value: item.kalenderBayarId, text: item.nama_kategori + ' - (' + vm.$moment(item.tanggal_pembukaan).format('LL') + ' s/d ' + vm.$moment(item.tanggal_penutupan).format('LL') + ' ) ' };
            //     });
            // }

            // let kategoriBayar = await vm.$axiosbilling('kategoriBayar/listByStatusKategoriBayar/4');
            // // console.log(kategoriBayar, 'bayarrr');
            // vm.kategoriBayar = kategoriBayar.data.data.map((item) => {
            //     return { ...item, value: item.id, text: item.nama_kategori };
            // });

            vm.periode_tagihan = [];

            for (let i = 2015; i < 2050; i++) {
                vm.periode_tagihan.push({ text: i, value: i });
            }
            vm.loading1 = false;
        },
        async get_return() {
            let vm = this;
            vm.loading1 = true;
            let pengembalian = await vm.$axiosbilling.post('pengembalian_dana/list', vm.fetch_data);
            console.log(pengembalian.data, 'data pengembalian');
            if (pengembalian.data.message == 'sukses') {
                vm.list_pengembalian = pengembalian.data.data.map((items, idx) => {
                    return { ...items, no: idx + 1 };
                });
            }
            vm.total_pengembalian = Number(pengembalian.data.count);
            vm.loading1 = false;
        },
        async get_pts() {
            let vm = this;
            vm.loading1 = true;
            vm.list_pts = [];
            let pts = await vm.$axiosbilling.post('pembayaranTagihanStudi/list', vm.data_pts);
            console.log(pts.data.data, 'data pts');
            if (pts.data.message == 'sukses') {
                vm.list_pts = pts.data.data.map((items) => {
                    return { ...items, value: items, text: items.nama_kategori, pembayaran_tagihan_studi_id: items.penjualanTagihanStudiId };
                });
            }
            vm.loading1 = false;
        },
        register() {
            let vm = this;
            if (vm.isValid && vm.isDirty) {
                vm.$axiosbilling
                    .post('pengembalian_dana/register', vm.data)
                    .then((res) => {
                        console.log(res.data.status);
                        if (res.data.status == 200) {
                            if (res.data.message == 'sukses') {
                                vm.data = {
                                    pembayaran_tagihan_studi_id: null,
                                    tanggal_pengembalian: null,
                                    jumlah_pengembalian: null,
                                    master_bank_id: null,
                                    file1: null,
                                };
                                vm.modalInput = false;
                                vm.$toast.add({ severity: 'success', summary: 'Konfirmasi', detail: 'Berhasil Mendaftarkan bank', life: 3000 });
                                this.get_bank();
                            } else {
                                vm.modalInput = false;
                                vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                            }
                        } else {
                            vm.modalInput = false;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        vm.modalInput = false;
                        vm.$toast.add({ severity: 'error', summary: 'Konfirmasi', detail: 'Terjadi kesalahan pada server', life: 3000 });
                    });
            } else {
                vm.$toast.add({ severity: 'error', summary: 'Peringatan', detail: 'Masih terdapat isian data yang tidak valid', life: 3000 });
            }
        },
        editData(data) {
            let vm = this;
            vm.dataEdit = data;
            vm.dataEdit.tanggal_pengembalian = vm.$moment(vm.dataEdit.tanggal_pengembalian).format('YYYY-MM-DD')
            vm.modalEdit = true;
            // console.log(vm.dataEdit, 'ini edit data', data);
        },
        simpanEdit() {
            let vm = this;
            if (vm.isValidEdit) {
                vm.$axiosbilling
                    .post('pengembalian_dana/update', vm.dataEdit)
                    .then((res) => {
                        console.log(res.data.status);
                        if (res.data.status == 200) {
                            if (res.data.message == 'sukses') {
                                vm.modalEdit = false;
                                vm.$toast.add({ severity: 'success', summary: 'Konfirmasi', detail: 'Berhasil Mengupdate Data bank', life: 3000 });
                                this.get_bank();
                                this.get_return()
                            } else {
                                vm.modalEdit = false;
                                vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                            }
                        } else {
                            vm.modalEdit = false;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        vm.modalEdit = false;
                        vm.$toast.add({ severity: 'error', summary: 'Konfirmasi', detail: 'Terjadi kesalahan pada server', life: 3000 });
                    });
            } else {
                vm.$toast.add({ severity: 'error', summary: 'Peringatan', detail: 'Masih terdapat isian data yang tidak valid', life: 3000 });
            }
        },
        simpanDelete(data) {
            let vm = this;
            vm.$axiosbilling
                .post('pengembalian_dana/delete', { id: data.pengembalian_dana_id })
                .then((res) => {
                    console.log(res.data.status);
                    if (res.data.status == 200) {
                        if (res.data.message == 'sukses') {
                            vm.$toast.add({ severity: 'success', summary: 'Konfirmasi', detail: 'Berhasil Menghapus Data bank', life: 3000 });
                            this.get_bank();
                            this.$confirm.close();
                        } else {
                            vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                            this.$confirm.close();
                        }
                    } else {
                        vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: 'data sudah ada', life: 3000 });
                        this.$confirm.close();
                    }
                })
                .catch((err) => {
                    console.log(err);
                    vm.$toast.add({ severity: 'error', summary: 'Konfirmasi', detail: 'Terjadi kesalahan pada server', life: 3000 });
                    this.$confirm.close();
                });
        },
        reset() {
            let vm = this;
            vm.fetch_data = {
                jumlah: 10,
                halaman: 1,
                NIM: null,
                tanggal_awal: null,
                tanggal_akhir: null,
                tahun_ak: null,
                tahun_kb: null,
                master_bank_id: null,
                pengembalian_dana_id: null,
                pembayaran_tagihan_id: null,
            };
            this.v$.$reset();
            this.get_return();
        },
        deleteData(data) {
            // console.log(data, 'ini datanya bossss');
            this.$confirm.require({
                message: 'Apakah anda yakin ingin menghapus ini?',
                header: 'Konfirmasi',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.simpanDelete(data);
                },
                reject: () => {
                    this.$confirm.close();
                },
            });
        },
        checkValidasi(type, fieldName) {
            const field = this.v$[type][fieldName];
            let x = field.$errors[0].$validator;
            if (x == 'required') {
                return '* Data wajib diisi';
            } else if (x == 'email') {
                return '* Data harus berbentuk email';
            } else if (x == 'numeric') {
                return '* Data harus terdiri hanxa dari angka';
            } else if (x == 'minLength') {
                return `* Data minimal ${field.$errors[0].$params.min} digits`;
            } else if (x == 'maxLength') {
                return `* Data maksimal ${field.$errors[0].$params.max} digits`;
            } else if (x == 'alphaNum') {
                return `* Data tidak boleh mengandung spasi atau simbol`;
            } else if (x == 'official') {
                return `* Data tidak boleh mengandung spasi atau simbol`;
            }
        },
        async downloadExcel() {
            let vm = this;
            let x = `?download=true`;
            if (vm.data.NIM_PTS) {
                x += `&NIM_PTS=${vm.data.NIM_PTS}`;
            }
            if (vm.data.nama_mahasiswa) {
                x += `&nama_mahasiswa=${vm.data.nama_mahasiswa}`;
            }
            if (vm.data.tahun_akademik_id) {
                x += `&tahun_akademik_id=${vm.data.tahun_akademik_id}`;
            }
            if (vm.data.prodi_id) {
                x += `&prodi_id=${vm.data.prodi_id}`;
            }
            if (vm.data.total_tagihan) {
                x += `&total_tagihan=${vm.data.total_tagihan}'`;
            }
            if (vm.data.bayar_mulai) {
                x += `&bayar_mulai=${vm.$moment(vm.data.bayar_mulai).format('YYYY-MM-DD')}`;
            }
            if (vm.data.bayar_tutup) {
                x += `&bayar_tutup=${vm.$moment(vm.data.bayar_tutup).format('YYYY-MM-DD')}`;
            }
            if (vm.data.tanggal_dibayar) {
                x += `&tanggal_dibayar=${vm.data.tanggal_dibayar}`;
            }
            if (vm.data.kategori_bayar_id) {
                x += `&kategori_bayar_id=${vm.data.kategori_bayar_id}`;
            }
            if (vm.data.nama_bank_pts) {
                x += `&nama_bank_pts=${vm.data.nama_bank_pts}`;
            }
            if (vm.data.jenis_beasiswa) {
                x += `&jenis_beasiswa=${vm.data.jenis_beasiswa}`;
            }
            if (vm.data.no_va_pts) {
                x += `&no_va_pts=${vm.data.no_va_pts}`;
            }
            await window.open(vm.$ip2 + '/pengembalian_dana/excel_pengembalian_dana' + x, '_blank');
        },
    },
};
</script>
<style scoped lang="scss">
@import '@/assets/demo/styles/badges.scss';

::v-deep(.p-datatable-frozen-tbody) {
    font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
    font-weight: bold;
}

::v-deep(.p-progressbar) {
    height: 0.5rem;
    background-color: #d8dadc;

    .p-progressbar-value {
        background-color: #607d8b;
    }
}
</style>
