<template>
    <div class="container">
        <div class="row bgg"></div>
        <div class="login row align-items-center">
            <div class="col">
                <div class="card m-5 py-5">
                    <div class="logo-container">
                        <img style="width: 100%;" class="p-4" src="../assets/logo-poltekes.png" alt="logo politekkes" />
                    </div>
                    <div class="simadu mb-3">
                        <h1 class="">SIBILLING</h1>
                        <h3 class="title">Silahkan masuk ke Akun anda</h3>
                    </div>
                    <form class="row m-5 p-5 sign-container">
                        <div class="col">
                            <Button class="p-button-success" type="button" @click="loginSSO">Single Sign On (SSO)</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref } from 'vue';
const dataLogin = ref({
    username: '',
    password: '',
    client_id: '7b43b656-f503-49a1-9d49-8c39f47cf9af',
    granttype: 'password',
    client_secret: 'simadu',
});
const loginUri = ref('https://sso.poltekkes-smg.ac.id/client_login/');

const loginSSO = async () => {
    try {
        window.location.href = loginUri.value + dataLogin.value.client_id; // setUri;
        // console.log('click loginSSO');
    } catch (err) {
        alert(err);
        console.log('error', err);
    }
};
</script>



<style lang="scss" scoped>
.bgg {
    background-image: url('../assets/gedung.jpeg');
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    min-height: 100vh;
    padding: 7%;
    filter: blur(1rem);
    margin-top: 55px;
}
.login {
    position: absolute;
    max-height: 100vh;
    z-index: 2;
    min-width: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;

    .card {
        border-radius: 25px;

        .simadu {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .simadu h1 {
            text-shadow: 0px 0px 50px rgba(15, 139, 253, 0.2);
            font-size: 50px;
            line-height: 2;
            color: #013c72;
        }

        .logo-container {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .sign-container {
            border-radius: 25px;
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .SSO {
                font-size: 30px;
                line-height: 38px;
                font-weight: 600;
                color: #101828;
                margin-bottom: 0px;
            }
        }
    }

    @media (max-width: 768px) {
        width: 100%;
    }
}
</style>
