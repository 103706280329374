<template>
    <div class="grid">
        <Toast />
        <div class="col-12">
            <div class="grid">
                <div class="col">
                    <div class="card">
                        <div class="grid p-fluid">
                            <div class="col-6">
                                <span class="p-float-label mt-4">
                                    <InputText id="nama_mahasiswa" required="true" autofocus v-model.lazy="data.nama_mahasiswa" />
                                    <label for="nama_mahasiswa">Nama Mahasiswa</label>
                                </span>
                            </div>
                            <div class="col-6">
                                <span class="p-float-label mt-4">
                                    <InputText id="NIM" required="true" autofocus v-model.lazy="data.NIM" />
                                    <label for="NIM">NIM</label>
                                </span>
                            </div>
                        </div>
                        <!-- <div class="grid p-fluid">
                            <div class="col-6">
                                <span class="p-float-label mt-4">
                                    <InputText id="nominal_beasiswa" required="true" autofocus v-model.lazy="data.nominal_beasiswa" />
                                    <label for="nominal_beasiswa">Nominal Beasiswa</label>
                                </span>
                            </div>
                            <div class="col-6">
                                <span class="p-float-label mt-4">
                                    <InputText id="tagihan" required="true" autofocus v-model.lazy="data.tagihan" />
                                    <label for="tagihan">Tagihan</label>
                                </span>
                            </div>
                        </div> -->
                        <div class="grid p-fluid">
                            <div class="col-6">
                                <span class="p-float-label mt-4">
                                    <Dropdown dataKey="value" filter id="prodi_id" v-model.lazy="data.prodi_id" :options="prodi" optionLabel="text" optionValue="value" />
                                    <label for="prodi_id">Prodi</label>
                                </span>
                            </div>
                            <div class="col-6">
                                <span class="p-float-label mt-4">
                                    <Dropdown id="tahun_angkatan" filter required="true" :options="tahun" optionLabel="text" optionValue="value" v-model.lazy="data.tahun_angkatan" />
                                    <label for="tahun_angkatan">Angkatan</label>
                                </span>
                            </div>
                        </div>
                        <!-- <div class="grid p-fluid"></div> -->
                        <!-- <div class="grid p-fluid">
                            <div class="col-4">
                                <span class="p-float-label mt-4">
                                    <Dropdown dataEditKey="value" filter id="kategori_bayar_id" v-model.lazy="data.kategori_bayar_id" :options="kategoriBayar" optionLabel="text" optionValue="value" />
                                    <label for="kategori_bayar_id">Kategori Bayar</label>
                                </span>
                            </div>
                            <div class="col-4">
                                <span class="p-float-label mt-4">
                                    <InputText id="tahun_kb" required="true" autofocus v-model.lazy="data.tahun_kb" />
                                    <label for="tahun_kb">Tahun KB</label>
                                </span>
                            </div>
                            <div class="col-4">
                                <span class="p-float-label mt-4">
                                    <Dropdown dataEditKey="value" filter id="semester" v-model.lazy="data.semester" :options="option_semester" optionLabel="text" optionValue="value" />
                                    <label for="semester">Semester</label>
                                </span>
                            </div>
                        </div> -->
                        <!-- <div class="grid p-fluid">
                            <div class="col-4">
                                <span class="p-float-label mt-4">
                                    <Dropdown dataEditKey="value" filter id="status_kalender_bayar" v-model.lazy="data.status_kalender_bayar" :options="option_status_kalender_bayar" optionLabel="text" optionValue="value" />
                                    <label for="status_kalender_bayar">Status Kalender Bayar</label>
                                </span>
                            </div>
                            <div class="col-8">
                                <span class="p-float-label mt-4">
                                    <Dropdown dataEditKey="value" filter id="kalender_bayar_id" v-model.lazy="data.kalender_bayar_id" :options="periode" optionLabel="text" optionValue="value" />
                                    <label for="kalender_bayar_id">Status Kalender Bayar</label>
                                </span>
                            </div>
                        </div> -->
                        <div class="grid mt-4">
                            <div class="col-6">
                                <div class="grid p-fluid">
                                    <div class="col-4">
                                        <ModalInput @alert="set($event)" :prodi="prodi" :tahun="tahun" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 text-right">
                                <div class="grid p-fluid">
                                    <div class="col-4">
                                        <Button class="p-button p-button-success" block label="Search" @click="get_gakin()" />
                                    </div>
                                    <div class="col-4">
                                        <Button class="p-button p-button-primary" label="Reset" @click="reset()" />
                                    </div>
                                    <!-- <div class="col-4">
                                        <Button class="p-button p-button-warning" label="Excel" @click="downloadExcel()" />
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid">
                <div class="col">
                    <div class="card">
                        <h5>List Mahasiswa Beasiswa GAKIN</h5>
                        <DataTable
                            :value="tagihan"
                            class="p-datatable-gridlines"
                            :rows="data.halaman"
                            v-model:filters="filters1"
                            dataKey="detailsTagihanStudiId"
                            :rowHover="true"
                            filterDisplay="menu"
                            :loading="loading1"
                            :filters="filters1"
                            responsiveLayout="scroll"
                            :globalFilterFields="field"
                            :resizableColumns="true"
                        >
                            <template #header>
                                <div class="flex justify-content-between flex-column sm:flex-row">
                                    <div class="add"><Dropdown :options="perPages" id="Role" required="true" autofocus v-model.lazy="data.jumlah" @change="set_jumlah($event)" /> Per Halaman dari {{ total_tagihan }} data</div>
                                    <div class="filter">
                                        <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2" @click="clearFilter1()" />
                                        <span class="p-input-icon-left mb-2">
                                            <i class="pi pi-search" />
                                            <InputText v-model="filters1.global.value" placeholder="Keyword Search" style="width: 100%" />
                                        </span>
                                    </div>
                                </div>
                            </template>
                            <template #empty> Tidak ada data. </template>
                            <template #loading> Loading data. mohon tunggu.... </template>
                            <Column field="no" header="No" style="width: 50px">
                                <template #body="{ data }">
                                    {{ data.no }}
                                </template>
                            </Column>
                            <Column field="actions" header="Actions" bodyClass="text-center" style="width: 1rem">
                                <template #body="{ data }">
                                    <div class="flex sm:flex-row">
                                        <div class="mt-2">
                                            <ModalEdit :dataEdit="data" :prodi="prodi" :tahun="tahun" @alert="set($event)" />
                                        </div>
                                        <!-- <div class="mt-2" v-if="data.status_tagihan == 0">
                                            <ModalLunas :dataEdit="data" @alert="set($event)" />
                                        </div> -->
                                        <div class="mt-2">
                                            <Button type="button" v-tooltip.top="'hapus tagihan mahasiswa'" icon="pi pi-trash" label="" class="p-button-danger ml-2" @click="deleteData(data)" />
                                        </div>
                                        <div class="mt-2">
                                            <Button type="button" v-tooltip.top="''" icon="" label="" class="p-button-secondary ml-2" style="background-color: transparent; border: none" />
                                        </div>
                                    </div>
                                </template>
                            </Column>
                            <Column field="NIM" header="NIM" style="width: 12rem" :sortable="true">
                                <template #body="{ data }">
                                    {{ data.NIM }}
                                </template>
                            </Column>
                            <Column field="nama_mahasiswa" header="Nama Mahasiswa" style="width: 12rem" :sortable="true">
                                <template #body="{ data }">
                                    {{ data.nama_mahasiswa }}
                                </template>
                            </Column>
                            <Column field="nama_prodi" header="Nama Prodi" style="width: 12rem" :sortable="true">
                                <template #body="{ data }">
                                    {{ data.nama_prodi }}
                                </template>
                            </Column>
                            <Column field="tahun_ak" header="Tahun Angkatan" style="width: 4rem" :sortable="true">
                                <template #body="{ data }">
                                    {{ data.tahun_angkatan }}
                                </template>
                            </Column>
                            <Column field="tagihan" header="Tagihan" style="width: 12rem; text-align: right" :sortable="true">
                                <template #body="{ data }">
                                    {{ $formats(data.tagihan).format() }}
                                </template>
                            </Column>
                            <Column field="nominal_beasiswa" header="Beasiswa" style="width: 12rem; text-align: right" :sortable="true">
                                <template #body="{ data }">
                                    {{ $formats(data.nominal_beasiswa).format() }}
                                </template>
                            </Column>
                            <!-- <Column field="status_pembayaran" header="Status" style="width: 12rem" :sortable="true">
                                <template #body="{ data }">
                                    {{ data.status_pembayaran }}
                                </template>
                            </Column> -->
                            <template #footer>
                                <Paginator :rows="data.jumlah" :totalRecords="total_tagihan" @page="onPage($event)"></Paginator>
                            </template>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { FilterMatchMode } from 'primevue/api';
import useVuelidate from '@vuelidate/core';
import ModalInput from './modalInput.vue';
import ModalEdit from './modalEdit.vue';
// import ModalLunas from './modalLunas.vue';
export default {
    setup() {
        return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
    },
    components: {
        ModalEdit,
        ModalInput,
        // ModalLunas,
    },
    data() {
        return {
            displayInput: false,
            displayEdit: false,
            displayLunas: false,
            tagihan: [],
            total_tagihan: null,
            field: ['nama_kategori'],
            loading1: true,
            prodi: [],
            tahun: [],
            periode: [],
            kalenderBayar: [],
            kategoriBayar: [],
            data: {
                jumlah: 10,
                halaman: 1,
                NIM: null,
                tagihan: null,
                sisa_tagihan: null,
                tahun_angkatan: null,
                prodi_id: null,
            },
            src1: null,
            file1: null,
            busy: false,
            dataEdit: {},
            filters1: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            perPage: 10,
            perPages: [10, 25, 50, 100],
        };
    },
    mounted() {
        this.get_gakin();
        this.get_data();
    },
    methods: {
        clearFilter1() {
            this.filters1 = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        onPage(x) {
            this.data.halaman = x.page + 1;
            this.get_gakin();
        },
        set_jumlah(x) {
            this.data.jumlah = x.value;
            this.data.halaman = 1;
            this.get_gakin();
        },
        set(x) {
            this.$toast.add(x);
            this.get_gakin();
        },
        initFilters1() {
            this.filters1 = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        get_gakin() {
            let vm = this;
            vm.loading1 = true;
            vm.tagihan = [];
            vm.$axiosbilling
                .post('mhs_gakin/list', vm.data)
                .then((res) => {
                    console.log(res.data, 'gakin');
                    if (res.data.status == 200) {
                        vm.tagihan = res.data.data.map((item, idx) => {
                            item.no = (vm.data.halaman - 1) * vm.data.jumlah + (idx + 1);
                            // if (item.status_tagihan == 1) {
                            //     item.status_pembayaran = 'Lunas';
                            // } else if (item.status_tagihan == 2) {
                            //     item.status_pembayaran = 'Dispensasi';
                            // } else {
                            //     item.status_pembayaran = 'Belum Lunas';
                            // }
                            return item;
                        });
                        vm.loading1 = false;
                        vm.total_tagihan = Number(res.data.count);
                    } else {
                        vm.loading1 = false;
                        vm.total_tagihan = 0;
                    }
                })
                .catch((err) => {
                    console.log(err);
                    vm.loading1 = false;
                });
        },
        async get_data() {
            let vm = this;
            let prodi = await vm.$axiosbilling('prodi/listHalaman');
            if (prodi.data.message == 'sukses') {
                vm.prodi = prodi.data.data.map((item) => {
                    return { ...item, value: item.id, text: item.nama_prodi };
                });
            }

            let tahun = await vm.$axiosbilling('tahunAkademik/listHalaman');
            if (tahun.data.message == 'sukses') {
                console.log(tahun, 'tahun');
                vm.tahun = tahun.data.data.map((item) => {
                    return { ...item, value: item.tahun_ak, text: item.tahun_ak };
                });
            }
            vm.tahun.sort((a, b) => {
                return a.value - b.value;
            });

            // let kalenderBayar = await vm.$axiosbilling('kalenderBayar/listHalaman');
            // if (kalenderBayar.data.message == 'sukses') {
            //     vm.periode = kalenderBayar.data.data.map((item) => {
            //         return { ...item, value: item.kalenderBayarId, text: item.nama_kategori + ' - (' + vm.$moment(item.tanggal_pembukaan).format('LL') + ' s/d ' + vm.$moment(item.tanggal_penutupan).format('LL') + ' ) ' };
            //     });
            // }

            // let kategoriBayar = await vm.$axiosbilling('kategoriBayar/listByStatusKategoriBayar/4');
            // console.log(kategoriBayar, 'bayarrr');
            // vm.kategoriBayar = kategoriBayar.data.data.map((item) => {
            //     return { ...item, value: item.id, text: item.nama_kategori };
            // });
        },
        simpanDelete(data) {
            let vm = this;
            let id = data.mhs_gakin_id;
            vm.$axiosbilling
                .post('mhs_gakin/delete', { id })
                .then((res) => {
                    // console.log(res.data.status);
                    if (res.data.status == 200) {
                        if (res.data.message == 'sukses') {
                            vm.$toast.add({ severity: 'success', summary: 'Konfirmasi', detail: 'Berhasil Menghapus Data Mahasiswa GAKIN', life: 3000 });
                            this.get_gakin();
                            this.$confirm.close();
                        } else {
                            vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                            this.$confirm.close();
                        }
                    } else {
                        vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: 'data sudah ada', life: 3000 });
                        this.$confirm.close();
                    }
                })
                .catch((err) => {
                    console.log(err);
                    vm.$toast.add({ severity: 'error', summary: 'Konfirmasi', detail: 'Terjadi kesalahan pada server', life: 3000 });
                    this.$confirm.close();
                });
        },
        deleteData(data) {
            // console.log(data, 'ini datanya bossss');
            this.$confirm.require({
                message: `Apakah anda yakin ingin menghapus beasiswa GAKIN a.n ${data.nama_mahasiswa}?`,
                header: 'Konfirmasi',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.simpanDelete(data);
                },
                reject: () => {
                    this.$confirm.close();
                },
            });
        },
        checkValidasi(type, fieldName) {
            const field = this.v$[type][fieldName];
            let x = field.$errors[0].$validator;
            if (x == 'required') {
                return '* Data wajib diisi';
            } else if (x == 'email') {
                return '* Data harus berbentuk email';
            } else if (x == 'numeric') {
                return '* Data harus terdiri hanxa dari angka';
            } else if (x == 'minLength') {
                return `* Data minimal ${field.$errors[0].$params.min} digits`;
            } else if (x == 'maxLength') {
                return `* Data maksimal ${field.$errors[0].$params.max} digits`;
            } else if (x == 'alphaNum') {
                return `* Data tidak boleh mengandung spasi atau simbol`;
            } else if (x == 'official') {
                return `* Data tidak boleh mengandung spasi atau simbol`;
            }
        },
        reset() {
            this.data = {
                jumlah: 10,
                halaman: 1,
                NIM: null,
                tagihan: null,
                kode_pendaftaran_dts: null,
                trx_id: null,
                status_tagihan: null,
                bayar_mulai: null,
                bayar_tutup: null,
                status_kalender_bayar: null,
                kategori_biaya_id: null,
                tahun_kb: null,
                kategori_bayar_id: null,
                tahun_angkatan: null,
                prodi_id: null,
                kalender_bayar_id: null,
                semester: null,
                is_beasiswa: null,
            };
            this.get_gakin();
        },
        async downloadExcel() {
            let vm = this;
            let x = `?download=true`;
            if (vm.data.NIM) {
                x += `&NIM=${vm.data.NIM}`;
            }
            if (vm.data.kode_pendaftaran_dts) {
                x += `&kode_pendaftaran_dts=${vm.data.kode_pendaftaran_dts}`;
            }
            if (vm.data.trx_id) {
                x += `&trx_id=${vm.data.trx_id}'`;
            }
            if (vm.data.prodi_id) {
                x += `&prodi_id=${vm.data.prodi_id}`;
            }
            if (vm.data.tahun_angkatan) {
                x += `&tahun_angkatan=${vm.data.tahun_angkatan}`;
            }
            if (vm.data.tagihan) {
                x += `&tagihan=${vm.data.tagihan}`;
            }
            if (vm.data.status_tagihan) {
                x += `&status_tagihan=${vm.data.status_tagihan}`;
            }
            if (vm.data.is_beasiswa) {
                x += `&is_beasiswa=${vm.data.is_beasiswa}`;
            }
            if (vm.data.kategori_bayar_id) {
                x += `&kategori_bayar_id=${vm.data.kategori_bayar_id}`;
            }
            if (vm.data.status_kalender_bayar) {
                x += `&status_kalender_bayar=${vm.data.status_kalender_bayar}`;
            }
            if (vm.data.tahun_kb) {
                x += `&tahun_kb=${vm.data.tahun_kb}`;
            }
            if (!vm.data.semester) {
                x += `&semester=${vm.data.semester}`;
            }
            if (vm.data.kalender_bayar_id) {
                x += `&kalender_bayar_id=${vm.data.kalender_bayar_id}`;
            }
            if (vm.data.bayar_mulai) {
                x += `&bayar_mulai=${vm.data.bayar_mulai}`;
            }
            if (vm.data.bayar_tutup) {
                x += `&bayar_tutup=${vm.data.bayar_tutup}`;
            }
            await window.open(vm.$ip2 + '/detailsTagihanStudi/excelTagihan' + x, '_blank');
        },
    },
};
</script>
<style scoped lang="scss">
@import '@/assets/demo/styles/badges.scss';

::v-deep(.p-datatable-frozen-tbody) {
    font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
    font-weight: bold;
}

::v-deep(.p-progressbar) {
    height: 0.5rem;
    background-color: #d8dadc;

    .p-progressbar-value {
        background-color: #607d8b;
    }
}
</style>
