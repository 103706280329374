const state = {
    sso_access_token: null,
    sso_refresh_token: null,
    expired: null,
    refresh_expired: null,
    sso_user_id: null,
    sso_client_id: null,
    sso_username: null,
    sso_user_status: null,
    sso_user_role: '',
    loading: false,
    alert: '',
    semester_aktif: null,
    kalender_akademik: null,
    show: false,
    backColor: '',
    user: null,
    menuMode: 'sidebar',
    scheme: 'light',
    bsi_client_id: '2576',
    bni_client_id: '29873',
    bni_prefix: '988',
    btn_prefix: '95673000000',
    btn_prefix_bendahara: '95673111111',
    mandiri_client_id: '88976',
    force: false,
};

const mutations = {
    set_login_token(state, tokens) {
        // console.log(tokens, "set store login");
        state.sso_access_token = tokens.accessToken;
        state.sso_refresh_token = tokens.refreshToken;
        state.sso_client_id = tokens.client.id;
        state.sso_user_id = tokens.user.id;
        state.expired = tokens.accessTokenExpiresAt;
        state.refresh_expired = tokens.refreshTokenExpiresAt;
        state.sso_username = tokens.user.username;
        state.sso_user_status = tokens.user.user_status;
        state.sso_user_role = tokens.user.role;
    },
    set_intercept_token(state, tokens) {
        // console.log(tokens)
        state.sso_access_token = tokens.accessToken;
        state.sso_refresh_token = tokens.refreshToken;
        state.sso_client_id = tokens.client.id;
        state.sso_user_id = tokens.user.id;
        state.expired = tokens.accessTokenExpiresAt;
        state.sso_username = tokens.user.username;
    },
    set_clear_token(state) {
        // console.log("set_clear");
        state.sso_access_token = null;
        state.sso_refresh_token = null;
        state.sso_client_id = null;
        state.sso_user_id = null;
        state.expired = null;
        state.sso_username = null;
        state.sso_user_status = null;
        state.sso_user_role = null;
        state.user_level = null;
    },
    force_logout(state, status) {
        state.force = status;
    },
    set_semester_aktif(state, semester) {
        state.semester_aktif = semester;
    },
    set_kalender_aktif(state, kalender) {
        state.kalender_akademik = kalender;
    },
    changeMenuMode(state, menu) {
        state.menuMode = menu;
    },
    change_color_scheme(state, scheme) {
        state.scheme = scheme;
    },
    set_loading_state(state, value) {
        state.loading = value;
    },
    set_user(state, value) {
        state.user = value;
    },
    show_alert_success(state, value) {
        state.alert = value.toUpperCase();
        state.backColor = 'background-color: #91f086';
        state.show = true;
    },
    show_alert_fail(state, value) {
        state.alert = value.toUpperCase();
        state.backColor = 'background-color: #FF6962';
        state.show = true;
    },
    hide_alert(state) {
        state.alert = '';
        state.show = false;
    },
    set_data_edit(state, value) {
        state.data_edit = value;
    },
};
const actions = {
    save_token_login({ commit }, log_respon) {
        // console.log(log_respon,'store login')
        commit('set_login_token', log_respon);
    },
    save_token_intercept({ commit }, int_respon) {
        commit('set_intercept_token', int_respon);
    },
    clear_token({ commit }) {
        commit('set_clear_token');
    },
    set_loading({ commit }, value) {
        commit('set_loading_state', value);
    },
    set_alert_show_success({ commit }, value) {
        commit('show_alert_success', value);
    },
    set_alert_show_fail({ commit }, value) {
        commit('show_alert_fail', value);
    },
    set_alert_hide({ commit }) {
        commit('hide_alert');
    },
    set_data_edit({ commit }, value) {
        commit('set_edit_data', value);
    },
};
//   const modules = {};
// const plugins = [createPersistedState({
//   storage: {
//     getItem: key => Cookies.get(key),
//     setItem: (key, value) => Cookies.set(key, value, { expires: 3, secure: true }),
//     removeItem: key => Cookies.remove(key)
//   }
// })];
//   const plugins = [createPersistedState()];
export const billing = {
    state,
    mutations,
    actions,
    // modules,
    // plugins,
};
