<template>
    <div class="grid">
        <Toast />
        <div class="col-12">
            <div class="grid">
                <div class="col">
                    <div class="card">
                        <div class="grid p-fluid">
                            <div class="col-6">
                                <span class="p-float-label mt-4">
                                    <Dropdown dataKey="value" filter id="prodi_id" v-model.lazy="data.prodi_id" :options="prodi" optionLabel="text" optionValue="value" />
                                    <label for="prodi_id">Prodi</label>
                                </span>
                            </div>
                            <div class="col-3">
                                <span class="p-float-label mt-4">
                                    <Dropdown dataEditKey="value" filter id="tahun" v-model.lazy="data.tahun_kb" :options="tahun" optionLabel="text" optionValue="value" />
                                    <label for="tahun">Tahun</label>
                                </span>
                            </div>
                            <div class="col-3">
                                <span class="p-float-label mt-4">
                                    <Dropdown dataEditKey="value" filter id="semester" v-model.lazy="data.semester" :options="option_semester" optionLabel="text" optionValue="value" />
                                    <label for="semester">Semester</label>
                                </span>
                            </div>
                        </div>
                        <div class="grid mt-4">
                            <div class="col-6">
                                <div class="grid">
                                    <div class="col-4">Total Piutang</div>
                                    <div class="col-6 text-right">{{ $formats(totalPiutang).format() }}</div>
                                </div>
                                <div class="grid">
                                    <div class="col-4">Total Tagihan </div>
                                    <div class="col-6 text-right">{{ $formats(total).format() }}</div>
                                </div>
                            </div>
                            <div class="col-6 text-right">
                                <div class="grid p-fluid">
                                    <div class="col-4">
                                        <Button class="p-button p-button-success" block label="Search" @click="get_rekap()" />
                                    </div>
                                    <div class="col-4">
                                        <Button class="p-button p-button-primary" label="Reset" @click="reset()" />
                                    </div>
                                    <!-- <div class="col-4">
                                        <Button class="p-button p-button-warning" label="Excel" @click="downloadExcel()" />
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid">
                <div class="col">
                    <div class="card">
                        <h5>Detail Tagihan Studi</h5>
                        <DataTable
                            :value="rekap"
                            class="p-datatable-gridlines"
                            :rows="perPage"
                            v-model:filters="filters1"
                            dataKey="id"
                            :paginator="true"
                            :rowHover="true"
                            filterDisplay="menu"
                            :loading="loading1"
                            :filters="filters1"
                            responsiveLayout="scroll"
                            :globalFilterFields="field"
                        >
                            <template #header>
                                <div class="flex justify-content-between flex-column sm:flex-row">
                                    <!-- <div class="add"><Dropdown :options="perPages" id="Role" required="true" autofocus v-model.lazy="data.jumlah" /> Per Halaman dari {{ total_tagihan }} data</div> -->
                                    <div class="filter">
                                        <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2" @click="clearFilter1()" />
                                        <span class="p-input-icon-left mb-2">
                                            <i class="pi pi-search" />
                                            <InputText v-model="filters1.global.value" placeholder="Keyword Search" style="width: 100%" />
                                        </span>
                                    </div>
                                </div>
                            </template>
                            <template #empty> Tidak ada data. </template>
                            <template #loading> Loading data. mohon tunggu.... </template>
                            <Column field="no" header="No" style="width: 50px">
                                <template #body="{ data }">
                                    {{ data.no }}
                                </template>
                            </Column>
                            <Column field="nama_prodi" header="Nama Prodi" style="min-width: 12rem" :sortable="true">
                                <template #body="{ data }">
                                    {{ data.nama_prodi }}
                                </template>
                            </Column>
                            <Column field="piutang" header="Piutang" style="min-width: 4rem; text-align: right" :sortable="true">
                                <template #body="{ data }">
                                    {{ $formats(data.piutang).format() }}
                                </template>
                            </Column>
                            <Column field="terbayar" header="Terbayar" style="min-width: 4rem; text-align: right" :sortable="true">
                                <template #body="{ data }">
                                    {{ $formats(data.terbayar).format() }}
                                </template>
                            </Column>
                            <Column field="tagihan" header="Tagihan" style="min-width: 4rem; text-align: right" :sortable="true">
                                <template #body="{ data }">
                                    {{ $formats(data.tagihan).format() }}
                                </template>
                            </Column>
                            <Column field="status_pembayaran" header="Persen" style="min-width: 2rem" :sortable="true">
                                <template #body="{ data }"> {{ ((data.terbayar / data.tagihan) * 100).toFixed(2) }} % </template>
                            </Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { FilterMatchMode } from 'primevue/api';
import useVuelidate from '@vuelidate/core';
export default {
    setup() {
        return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
    },
    data() {
        return {
            rekap: [],
            total_tagihan: null,
            field: ['nama_prodi'],
            loading1: true,
            prodi: [],
            tahun: [],
            total: 0,
            totalPiutang: 0,
            option_semester: [
                { text: 'Ganjil', value: 'GANJIL' },
                { text: 'Genap', value: 'GENAP' },
            ],
            data: {
                tahun_kb: null,
                semester: null,
                prodi_id: null,
            },
            busy: false,
            filters1: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            perPage: 50,
            perPages: [10, 25, 50, 100],
        };
    },
    mounted() {
        this.get_rekap();
        this.get_data();
    },
    methods: {
        clearFilter1() {
            this.filters1 = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        onPage(x) {
            this.data.halaman = x.page + 1;
            this.get_rekap();
        },
        initFilters1() {
            this.filters1 = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        get_rekap() {
            let vm = this;
            vm.loading1 = true;
            vm.busy = true;
            vm.totalPiutang = 0
            vm.$axiosbilling
                .post('detailsTagihanStudi/rekapTagihan', vm.data)
                .then((res) => {
                    if (res.data.status == 200) {
                        if (res.data.message == 'sukses') {
                            vm.total = res.data.data[0].Totaltagihan;
                            vm.rekap = res.data.data[0].data.map((item, idx) => {
                                let piutang = Number(item.tagihan - item.terbayar);
                                vm.totalPiutang += piutang;
                                return { ...item, piutang: piutang, no: idx + 1 };
                            });
                        }
                    }
                    console.log(vm.rekap[0]);
                    vm.loading1 = false;
                    vm.busy = false;
                })
                .catch((err) => {
                    console.log(err);
                    vm.loading1 = false;
                });
        },
        async get_data() {
            let vm = this;

            for (let i = 2022; i < 2035; i++) {
                vm.tahun.push({ value: i, text: i });
            }

            let prodi = await vm.$axiosbilling('prodi/listHalaman');
            if (prodi.data.message == 'sukses') {
                vm.prodi = prodi.data.data.map((item) => {
                    return { ...item, value: item.id, text: item.nama_prodi };
                });
            }
        },
        reset() {
            this.data = {
                tahun_kb: null,
                semester: null,
                prodi_id: null,
            };
            this.get_rekap();
        },
    },
};
</script>
<style scoped lang="scss">
@import '@/assets/demo/styles/badges.scss';

::v-deep(.p-datatable-frozen-tbody) {
    font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
    font-weight: bold;
}

::v-deep(.p-progressbar) {
    height: 0.5rem;
    background-color: #d8dadc;

    .p-progressbar-value {
        background-color: #607d8b;
    }
}
</style>
