<template>
    <div class="grid">
        <Toast />
        <div class="col-12">
            <div class="card text-center">
                <h3>
                    Sync Tagihan Mahasiswa <span v-if="semester_aktif"> {{ semester_aktif.nama_semester }}</span>
                </h3>
            </div>
            <div class="card" align-items="">
                <div class="grid">
                    <div class="col text-center" v-if="isBefore">
                        <h4 :class="{ 'p-error': isBefore }">Periode Her-Registrasi Mahasiswa Belum di Mulai</h4>
                    </div>
                    <div class="col text-center" v-else-if="isHerreg">
                        <h4 :class="{ 'p-error': isHerreg }">Periode Her-Registrasi Mahasiswa Sedang Berjalan</h4>
                    </div>
                    <div class="col text-center" v-else>
                        <h4 :class="{ 'p-success': periode }"></h4>
                    </div>
                </div>
                <div class="grid">
                    <div class="col text-center">
                        <p class="text-green-500">Periode Her-Registrasi Mahasiswa {{ semester_aktif.nama_semester }} berlangsung mulai {{ $moment(kalender.tgl_awal).format('LL') }} s/d {{ $moment(kalender.tgl_akhir).format('LL') }}</p>
                    </div>
                </div>
                <div class="grid">
                    <div class="col-3 mt-4"></div>
                    <div class="col-6">
                        <div class="grid p-fluid">
                            <div class="col-6">
                                <span class="p-float-label mt-4">
                                    <Dropdown disabled id="semester_id" required="true" :options="optionsSemester" optionLabel="nama_semester" optionValue="semester_id" autofocus v-model.lazy="data.semester_id" />
                                    <label for="semester_id">Semester</label>
                                </span>
                            </div>
                            <div class="col-6">
                                <span class="p-float-label mt-4">
                                    <Dropdown disabled id="tahun_ajaran_id" required="true" :options="optionsTahun" optionLabel="nama_tahun_ajaran" optionValue="tahun_ajaran_id" autofocus v-model.lazy="data.tahun_ajaran_id" />
                                    <label for="tahun_ajaran_id">Tahun Ajaran</label>
                                </span>
                            </div>
                        </div>
                        <div class="grid p-fluid">
                            <div class="col-6">
                                <span class="p-float-label mt-4">
                                    <Dropdown disabled dataKey="value" filter id="kategori_bayar_id" v-model.lazy="data.kategori_bayar_id" :options="optionsKategori" optionLabel="text" optionValue="value" />
                                    <label for="kategori_bayar_id">Kategori Bayar</label>
                                </span>
                            </div>
                            <div class="col-6">
                                <span class="p-float-label mt-4">
                                    <InputText disabled id="tahun_kb" filter required="true" :options="optionsKB" optionLabel="text" optionValue="value" v-model.lazy="data.tahun_kb" />
                                    <label for="tahun_kb">Tahun</label>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grid p-fluid mt-4">
                    <div class="col-4 col-offset-4">
                        <Button :disabled="isBefore || (isHerreg && semester_aktif)" @click="sync()" block type="button" v-tooltip.top="'Sync Tagihan'" icon="pi pi-sync" label="Sync" class="p-button-success ml-2"></Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            data: {
                semester_id: null,
                tahun_ajaran_id: null,
                sisa_tagihan: null,
                tahun_kb: null,
                kategori_bayar_id: null,
            },
            optionsSemester: [],
            optionsTahun: [],
            optionsKategori: [],
            optionsKB: [],
        };
    },
    computed: {
        semester_aktif() {
            return this.$store.state.billing.semester_aktif ? this.$store.state.billing.semester_aktif : null;
        },
        kalender() {
            return this.$store.state.billing.kalender_akademik ? this.$store.state.billing.kalender_akademik['Her-Registrasi akademik'] : null;
        },
        isHerreg() {
            let vm = this;
            let now = vm.$moment();
            let awal = vm.$moment(vm.kalender.tgl_awal);
            let akhir = vm.$moment(vm.kalender.tgl_akhir);
            return now.isBetween(awal, akhir);
        },
        isBefore() {
            let vm = this;
            let now = vm.$moment();
            let awal = vm.$moment(vm.kalender.tgl_awal);
            return now < awal;
        },
        kategori() {
            let vm = this;
            let x = vm.semester_aktif;
            let isGanjil = x.nama_semester.includes('Ganjil') ? 'GANJIL' : 'GENAP';
            return `Tahun ${x.kode_tahun_ajaran_feeder} Semester ${isGanjil}`;
        },
    },
    watch: {
        semester_aktif: function (newV, oldV) {
            if (newV != oldV) {
                this.set_data();
            }
        },
    },
    mounted() {
        this.set_data();
    },
    methods: {
        async set_data() {
            let vm = this;
            let jenis = await vm.$axiosbilling('kategoriBayar/listByStatusKategoriBayar/4');
            console.log(jenis.data.data[0], 'kategori biaya');
            if (jenis.data.message == 'sukses') {
                vm.optionsKategori = jenis.data.data.map((item) => {
                    return { ...item, value: item.id, text: item.nama_kategori };
                });
            }
            if (vm.semester_aktif) {
                vm.optionsSemester.push(vm.semester_aktif);
                vm.optionsTahun.push(vm.semester_aktif);
                vm.data.semester_id = vm.semester_aktif.semester_id;
                vm.data.tahun_ajaran_id = vm.semester_aktif.tahun_ajaran_id;
                vm.data.tahun_kb = vm.semester_aktif.kode_tahun_ajaran_feeder;
                vm.optionsKategori.forEach((ele) => {
                    console.log(ele.nama_kategori, vm.kategori);
                    if (ele.nama_kategori.toLowerCase() == vm.kategori.toLowerCase()) {
                        vm.data.kategori_bayar_id = ele.value;
                    }
                });
            }
        },
        sync() {
            this.$confirm.require({
                message: 'Apakah anda yakin akan melakukan sinkronisasi data mahasiswa dan tagihan ? (proses ini akan memakan waktu beberapa jam)',
                header: 'Konfirmasi',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.sync_tagihan();
                },
                reject: () => {
                    this.$confirm.close();
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped></style>
