<template>
    <div class="grid">
        <Toast />
        <div class="col-12">
            <div class="grid">
                <div class="col">
                    <div class="card">
                        <!-- <div class="grid">
                            <div class="col">
                                <ModalInput @alert="set($event)" :kategoriBayar="kategoriBayar" :prodi="prodi" />
                            </div>
                        </div> -->
                        <div class="grid p-fluid">
                            <div class="col-4">
                                <span class="p-float-label mt-4">
                                    <InputText id="NIM_DTS" required="true" autofocus v-model.lazy="data.NIM_DTS" />
                                    <label for="NIM_DTS">NIM</label>
                                </span>
                            </div>
                            <div class="col-4">
                                <span class="p-float-label mt-4">
                                    <InputText id="kode_pendaftaran_dts" required="true" autofocus v-model.lazy="data.kode_pendaftaran_dts" />
                                    <label for="kode_pendaftaran_dts">Kode Pendaftaran</label>
                                </span>
                            </div>
                            <div class="col-4">
                                <span class="p-float-label mt-4">
                                    <InputText id="trx_id" v-model.lazy="data.trx_id" />
                                    <label for="trx_id">Transaction ID</label>
                                </span>
                            </div>
                        </div>
                        <div class="grid p-fluid">
                            <div class="col-8">
                                <span class="p-float-label mt-4">
                                    <Dropdown dataKey="value" filter id="prodi_id" v-model.lazy="data.prodi_id" :options="prodi" optionLabel="text" optionValue="value" />
                                    <label for="prodi_id">Prodi</label>
                                </span>
                            </div>
                            <div class="col-4">
                                <span class="p-float-label mt-4">
                                    <Dropdown dataEditKey="value" filter id="tahun_akademik" v-model.lazy="data.tahun_akademik" :options="periode_tagihan" optionLabel="text" optionValue="value" />
                                    <label for="tahun_akademik">Tahun Angkatan</label>
                                </span>
                            </div>
                        </div>
                        <div class="grid p-fluid">
                            <div class="col-4">
                                <span class="p-float-label mt-4">
                                    <InputText id="tagihan" required="true" autofocus v-model.lazy="data.tagihan" />
                                    <label for="tagihan">Tagihan</label>
                                </span>
                            </div>
                            <div class="col-4">
                                <span class="p-float-label mt-4">
                                    <Dropdown dataEditKey="value" filter id="status_tagihan" v-model.lazy="data.status_tagihan" :options="option_status_tagihan" optionLabel="text" optionValue="value" />
                                    <label for="status_tagihan">Status Tagihan</label>
                                </span>
                            </div>
                            <div class="col-4">
                                <span class="p-float-label mt-4">
                                    <Dropdown dataKey="value" filter id="is_beasiswa" v-model.lazy="data.is_beasiswa" :options="option_beasiswa" optionLabel="text" optionValue="value" /> <label for="is_beasiswa">Beasiswa</label>
                                </span>
                            </div>
                        </div>
                        <div class="grid p-fluid">
                            <div class="col-4">
                                <span class="p-float-label mt-4">
                                    <Dropdown dataEditKey="value" filter id="kategori_bayar_id" v-model.lazy="data.kategori_bayar_id" :options="kategoriBayar" optionLabel="text" optionValue="value" />
                                    <label for="kategori_bayar_id">Kategori Bayar</label>
                                </span>
                            </div>
                            <div class="col-4">
                                <span class="p-float-label mt-4">
                                    <InputText id="tahun_kb" required="true" autofocus v-model.lazy="data.tahun_kb" />
                                    <label for="tahun_kb">Tahun KB</label>
                                </span>
                            </div>
                            <div class="col-4">
                                <span class="p-float-label mt-4">
                                    <Dropdown dataEditKey="value" filter id="semester" v-model.lazy="data.semester" :options="option_semester" optionLabel="text" optionValue="value" />
                                    <label for="semester">Semester</label>
                                </span>
                            </div>
                        </div>
                        <div class="grid p-fluid">
                            <div class="col-4">
                                <span class="p-float-label mt-4">
                                    <Dropdown dataEditKey="value" filter id="status_kalender_bayar" v-model.lazy="data.status_kalender_bayar" :options="option_status_kalender_bayar" optionLabel="text" optionValue="value" />
                                    <label for="status_kalender_bayar">Status Kalender Bayar</label>
                                </span>
                            </div>
                            <div class="col-4">
                                <span class="p-float-label mt-4">
                                    <Dropdown dataEditKey="value" filter id="kalender_bayar_id" v-model.lazy="data.kalender_bayar_id" :options="periode" optionLabel="text" optionValue="value" />
                                    <label for="kalender_bayar_id">Status Kalender Bayar</label>
                                </span>
                            </div>
                            <div class="col-4">
                                <span class="p-float-label mt-4">
                                    <Dropdown id="tahun_akademik_id" filter required="true" :options="tahun" optionLabel="text" optionValue="value" v-model.lazy="data.tahun_akademik_id" />
                                    <label for="tahun_akademik_id">Tahun Tagihan</label>
                                </span>
                            </div>
                        </div>
                        <div class="grid mt-4">
                            <div class="col-6"></div>
                            <div class="col-6 text-right">
                                <div class="grid p-fluid">
                                    <div class="col-4">
                                        <Button class="p-button p-button-success" block label="Search" @click="get_tagihan()" />
                                    </div>
                                    <div class="col-4">
                                        <Button class="p-button p-button-primary" label="Reset" @click="reset()" />
                                    </div>
                                    <div class="col-4">
                                        <Button class="p-button p-button-warning" label="Download Belum Lunas" @click="downloadExcel()" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid">
                <div class="col">
                    <div class="card">
                        <h5>Pelunasan Beasiswa Tagihan Studi</h5>
                        <DataTable
                            :value="tagihan"
                            class="p-datatable-gridlines"
                            :rows="data.halaman"
                            v-model:filters="filters1"
                            dataKey="detailsTagihanStudiId"
                            :rowHover="true"
                            filterDisplay="menu"
                            :loading="loading1"
                            :filters="filters1"
                            responsiveLayout="scroll"
                            :globalFilterFields="field"
                            :resizableColumns="true"
                        >
                            <template #header>
                                <div class="flex justify-content-between flex-column sm:flex-row">
                                    <div class="add"><Dropdown :options="perPages" id="Role" required="true" autofocus v-model.lazy="data.jumlah" @change="set_jumlah($event)" /> Per Halaman dari {{ total_tagihan }} data</div>
                                    <div class="filter">
                                        <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2" @click="clearFilter1()" />
                                        <span class="p-input-icon-left mb-2">
                                            <i class="pi pi-search" />
                                            <InputText v-model="filters1.global.value" placeholder="Keyword Search" style="width: 100%" />
                                        </span>
                                    </div>
                                </div>
                            </template>
                            <template #empty> Tidak ada data. </template>
                            <template #loading> Loading data. mohon tunggu.... </template>
                            <Column field="no" header="No" style="width: 50px">
                                <template #body="{ data }">
                                    {{ data.no }}
                                </template>
                            </Column>
                            <Column field="actions" header="Actions" bodyClass="text-center" style="min-width: 2rem">
                                <template #body="{ data }">
                                    <div class="flex sm:flex-row">
                                        <div class="mt-2" v-if="data.status_tagihan == 0">
                                            <ModalChange :dataEdit="data" @alert="set($event)" />
                                        </div>
                                        <!-- <div class="mt-2" v-if="data.status_tagihan == 0">
                                            <ModalLunas :dataEdit="data" @alert="set($event)" />
                                        </div> -->
                                        <!-- <div class="mt-2" v-if="data.status_tagihan == 0">
                                            <Button type="button" v-tooltip.top="'hapus tagihan mahasiswa'"
                                                icon="pi pi-trash" label="" class="p-button-danger ml-2"
                                                @click="deleteData(data)" />
                                        </div> -->
                                        <div class="mt-2" v-if="data.status_tagihan == 0">
                                            <ModalVa :datas="data" @alert="set($event)" />
                                        </div>
                                        <div class="mt-2">
                                            <Button type="button" v-tooltip.top="''" class="p-button-secondary ml-2" style="background-color: transparent; border: none" />
                                        </div>
                                    </div>
                                </template>
                            </Column>
                            <Column field="NIM_DTS" header="NIM" style="width: 12rem" :sortable="true">
                                <template #body="{ data }">
                                    {{ data.NIM_DTS }}
                                </template>
                            </Column>
                            <Column field="nama_mahasiswa_dts" header="Nama Mahasiswa" style="width: 12rem" :sortable="true">
                                <template #body="{ data }">
                                    {{ data.nama_mahasiswa_dts }}
                                </template>
                            </Column>
                            <Column field="nama_prodi" header="Nama Prodi" style="width: 12rem" :sortable="true">
                                <template #body="{ data }">
                                    {{ data.nama_prodi }}
                                </template>
                            </Column>
                            <Column field="tahun_ak" header="Tahun" style="min-width: 4rem" :sortable="true">
                                <template #body="{ data }">
                                    {{ data.tahun_ak }}
                                </template>
                            </Column>
                            <Column field="nama_kategori" header="Kategori" style="width: 12rem" :sortable="true">
                                <template #body="{ data }">
                                    {{ data.nama_kategori }}
                                </template>
                            </Column>
                            <Column field="tagihan" header="Tagihan" style="width: 12rem; text-align: right" :sortable="true">
                                <template #body="{ data }">
                                    {{ $formats(data.tagihan).format() }}
                                </template>
                            </Column>
                            <Column field="status_pembayaran" header="Status" style="width: 12rem" :sortable="true">
                                <template #body="{ data }">
                                    {{ data.status_pembayaran }}
                                </template>
                            </Column>

                            <template #footer>
                                <Paginator :rows="data.jumlah" :totalRecords="total_tagihan" @page="onPage($event)"> </Paginator>
                            </template>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { FilterMatchMode } from 'primevue/api';
import useVuelidate from '@vuelidate/core';
import ModalChange from './modalChangeBeasiswa.vue';
import ModalVa from './CBTN.vue';
// import ModalInput from './modalInput.vue';
// import ModalLunas from './modalLunas.vue';
export default {
    setup() {
        return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
    },
    components: {
        ModalChange,
        // ModalInput,
        // ModalLunas,
        ModalVa,
    },
    data() {
        return {
            displayInput: false,
            displayEdit: false,
            displayLunas: false,
            tagihan: [],
            total_tagihan: null,
            field: ['nama_kategori'],
            loading1: true,
            prodi: [],
            tahun: [],
            periode_tagihan: [],
            periode: [],
            kalenderBayar: [],
            kategoriBayar: [],
            option_status_kalender_bayar: [
                { value: '0', text: 'Existing' },
                { value: '1', text: 'Changed' },
            ],
            option_status_tagihan: [
                { value: '0', text: 'Belum' },
                { value: '1', text: 'Lunas' },
                { value: '2', text: 'Dispensasi' },
            ],
            option_beasiswa: [
                { value: '0', text: 'Tidak' },
                { value: '1', text: 'Iya' },
            ],
            option_semester: [
                { text: 'Ganjil', value: 'GANJIL' },
                { text: 'Genap', value: 'GENAP' },
            ],
            data: {
                jumlah: 10,
                halaman: 1,
                NIM_DTS: null,
                tagihan: null,
                kode_pendaftaran_dts: null,
                trx_id: null,
                status_tagihan: null,
                bayar_mulai: null,
                bayar_tutup: null,
                status_kalender_bayar: null,
                kategori_biaya_id: null,
                tahun_kb: null,
                kategori_bayar_id: null,
                tahun_akademik_id: null,
                prodi_id: null,
                kalender_bayar_id: null,
                tahun_akademik: null,
                semester: null,
                is_beasiswa: null,
                ditagihkan_kepada: 'bendahara_poltekkes',
            },
            src1: null,
            file1: null,
            busy: false,
            dataEdit: {},
            filters1: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            perPage: 10,
            perPages: [10, 25, 50, 100],
        };
    },
    mounted() {
        this.get_tagihan();
        this.get_data();
    },
    methods: {
        clearFilter1() {
            this.filters1 = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        onPage(x) {
            this.data.halaman = x.page + 1;
            this.get_tagihan();
        },
        set_jumlah(x) {
            this.data.jumlah = x.value;
            this.data.halaman = 1;
            this.get_tagihan();
        },
        set(x) {
            this.$toast.add(x);
            this.get_tagihan();
        },
        initFilters1() {
            this.filters1 = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        get_tagihan() {
            let vm = this;
            vm.loading1 = true;
            vm.$axiosbilling
                .post('detailsTagihanStudi/list', vm.data)
                .then((res) => {
                    console.log(res, 'dts');
                    if (res.data.status == 200) {
                        vm.tagihan = res.data.data.map((item, idx) => {
                            item.no = (vm.data.halaman - 1) * vm.data.jumlah + (idx + 1);
                            if (item.status_tagihan == 1) {
                                item.status_pembayaran = 'Lunas';
                            } else if (item.status_tagihan == 2) {
                                item.status_pembayaran = 'Dispensasi';
                            } else {
                                item.status_pembayaran = 'Belum Lunas';
                            }
                            item.original = item.tagihan;
                            return item;
                        });
                        vm.loading1 = false;
                        vm.total_tagihan = Number(res.data.count);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    vm.loading1 = false;
                });
        },
        async get_data() {
            let vm = this;
            let prodi = await vm.$axiosbilling('prodi/listHalaman');
            if (prodi.data.message == 'sukses') {
                vm.prodi = prodi.data.data.map((item) => {
                    return { ...item, value: item.id, text: item.nama_prodi };
                });
            }

            let tahun = await vm.$axiosbilling('tahunAkademik/listHalaman');
            if (tahun.data.message == 'sukses') {
                // console.log(tahun);
                vm.tahun = tahun.data.data.map((item) => {
                    return { ...item, value: item.id, text: item.tahun_ak };
                });
            }

            let kalenderBayar = await vm.$axiosbilling('kalenderBayar/listHalaman');
            if (kalenderBayar.data.message == 'sukses') {
                vm.periode = kalenderBayar.data.data.map((item) => {
                    return { ...item, value: item.kalenderBayarId, text: item.nama_kategori + ' - (' + vm.$moment(item.tanggal_pembukaan).format('LL') + ' s/d ' + vm.$moment(item.tanggal_penutupan).format('LL') + ' ) ' };
                });
            }

            let kategoriBayar = await vm.$axiosbilling('kategoriBayar/listByStatusKategoriBayar/4');
            // console.log(kategoriBayar, 'bayarrr');
            vm.kategoriBayar = kategoriBayar.data.data.map((item) => {
                return { ...item, value: item.id, text: item.nama_kategori };
            });
            vm.periode_tagihan = [];

            for (let i = 2015; i < 2050; i++) {
                vm.periode_tagihan.push({ text: i, value: i });
            }
        },
        simpanDelete(data) {
            let vm = this;
            let id = data.detailsTagihanStudiId;
            vm.$axiosbilling
                .post('detailsTagihanStudi/delete', { id })
                .then((res) => {
                    // console.log(res.data.status);
                    if (res.data.status == 200) {
                        if (res.data.message == 'sukses') {
                            vm.$toast.add({ severity: 'success', summary: 'Konfirmasi', detail: 'Berhasil Menghapus Data tagihan Biaya', life: 3000 });
                            this.get_tagihan();
                            this.$confirm.close();
                        } else {
                            vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                            this.$confirm.close();
                        }
                    } else {
                        vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: 'data sudah ada', life: 3000 });
                        this.$confirm.close();
                    }
                })
                .catch((err) => {
                    console.log(err);
                    vm.$toast.add({ severity: 'error', summary: 'Konfirmasi', detail: 'Terjadi kesalahan pada server', life: 3000 });
                    this.$confirm.close();
                });
        },
        deleteData(data) {
            // console.log(data, 'ini datanya bossss');
            this.$confirm.require({
                message: 'Apakah anda yakin ingin menghapus tagihan ini?',
                header: 'Konfirmasi',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.simpanDelete(data);
                },
                reject: () => {
                    this.$confirm.close();
                },
            });
        },
        checkValidasi(type, fieldName) {
            const field = this.v$[type][fieldName];
            let x = field.$errors[0].$validator;
            if (x == 'required') {
                return '* Data wajib diisi';
            } else if (x == 'email') {
                return '* Data harus berbentuk email';
            } else if (x == 'numeric') {
                return '* Data harus terdiri hanxa dari angka';
            } else if (x == 'minLength') {
                return `* Data minimal ${field.$errors[0].$params.min} digits`;
            } else if (x == 'maxLength') {
                return `* Data maksimal ${field.$errors[0].$params.max} digits`;
            } else if (x == 'alphaNum') {
                return `* Data tidak boleh mengandung spasi atau simbol`;
            } else if (x == 'official') {
                return `* Data tidak boleh mengandung spasi atau simbol`;
            }
        },
        reset() {
            this.data = {
                jumlah: 10,
                halaman: 1,
                NIM_DTS: null,
                tagihan: null,
                kode_pendaftaran_dts: null,
                trx_id: null,
                status_tagihan: null,
                bayar_mulai: null,
                bayar_tutup: null,
                status_kalender_bayar: null,
                kategori_biaya_id: null,
                tahun_kb: null,
                kategori_bayar_id: null,
                tahun_akademik_id: null,
                prodi_id: null,
                kalender_bayar_id: null,
                semester: null,
                is_beasiswa: null,
                ditagihkan_kepada: 'bendahara_poltekkes',
            };
            this.get_tagihan();
        },
        async downloadExcel() {
            let vm = this;
            let x = `?download=true`;
            if (vm.data.NIM_DTS) {
                x += `&NIM_DTS=${vm.data.NIM_DTS}`;
            }
            if (vm.data.kode_pendaftaran_dts) {
                x += `&kode_pendaftaran_dts=${vm.data.kode_pendaftaran_dts}`;
            }
            if (vm.data.trx_id) {
                x += `&trx_id=${vm.data.trx_id}'`;
            }
            if (vm.data.prodi_id) {
                x += `&prodi_id=${vm.data.prodi_id}`;
            }
            if (vm.data.tahun_akademik_id) {
                x += `&tahun_akademik_id=${vm.data.tahun_akademik_id}`;
            }
            if (vm.data.tahun_akademik) {
                x += `&tahun_akademik=${vm.data.tahun_akademik}`;
            }
            if (vm.data.tagihan) {
                x += `&tagihan=${vm.data.tagihan}`;
            }
            if (vm.data.status_tagihan) {
                x += `&status_tagihan=${vm.data.status_tagihan}`;
            }
            if (vm.data.is_beasiswa) {
                x += `&is_beasiswa=${vm.data.is_beasiswa}`;
            }
            if (vm.data.kategori_bayar_id) {
                x += `&kategori_bayar_id=${vm.data.kategori_bayar_id}`;
            }
            if (vm.data.status_kalender_bayar) {
                x += `&status_kalender_bayar=${vm.data.status_kalender_bayar}`;
            }
            if (vm.data.tahun_kb) {
                x += `&tahun_kb=${vm.data.tahun_kb}`;
            }
            if (!vm.data.semester) {
                x += `&semester=${vm.data.semester}`;
            }
            if (vm.data.kalender_bayar_id) {
                x += `&kalender_bayar_id=${vm.data.kalender_bayar_id}`;
            }
            if (vm.data.bayar_mulai) {
                x += `&bayar_mulai=${vm.data.bayar_mulai}`;
            }
            if (vm.data.bayar_tutup) {
                x += `&bayar_tutup=${vm.data.bayar_tutup}`;
            }
            if (vm.data.ditagihkan_kepada) {
                x += `&ditagihkan_kepada=bendahara_poltekkes`;
            }
            await window.open(vm.$ip2 + '/mhsBidikKamu/downloadbidikkamu' + x, '_blank');
        },
    },
};
</script>
<style scoped lang="scss">
@import '@/assets/demo/styles/badges.scss';

::v-deep(.p-datatable-frozen-tbody) {
    font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
    font-weight: bold;
}

::v-deep(.p-progressbar) {
    height: 0.5rem;
    background-color: #d8dadc;

    .p-progressbar-value {
        background-color: #607d8b;
    }
}
</style>
