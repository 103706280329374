<template>
    <div class="grid">
        <Toast />
        <div class="col-12">
            <div class="card">
                <h5>Kategori Biaya</h5>
                <div class="grid p-fluid">
                    <div class="col-12 sm:col-2">
                        <div class="add"><Button type="button" icon="pi pi-plus" label="Tambah" class="p-button mb-2 mt-2" @click="modalInput = true" /></div>
                    </div>
                </div>
                <DataTable
                    :value="kategori_biaya"
                    class="p-datatable-gridlines"
                    :rows="perPage"
                    v-model:filters="filters1"
                    dataKey="id"
                    :rowHover="true"
                    filterDisplay="menu"
                    :loading="loading1"
                    :filters="filters1"
                    responsiveLayout="scroll"
                    :globalFilterFields="field"
                >
                    <template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <div class="add"><Dropdown :options="perPages" id="Role" required="true" autofocus v-model.lazy="perPage" /> Per Halaman dari {{ total_kategori_biaya }} data</div>
                            <div class="filter">
                                <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2" @click="clearFilter1()" />
                                <span class="p-input-icon-left mb-2">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filters1.global.value" placeholder="Keyword Search" style="width: 100%" />
                                </span>
                            </div>
                        </div>
                    </template>
                    <template #empty> Tidak ada data. </template>
                    <template #loading> Loading data. mohon tunggu.... </template>
                    <Column field="no" header="No" style="width: 50px">
                        <template #body="{ data }">
                            {{ data.no }}
                        </template>
                    </Column>
                    <Column field="kode_prodi" header="Kode Prodi" style="min-width: 4rem" :sortable="true">
                        <template #body="{ data }">
                            {{ data.kode_prodi }}
                        </template>
                    </Column>
                    <Column field="nama_prodi" header="Nama Prodi" style="min-width: 12rem" :sortable="true">
                        <template #body="{ data }">
                            {{ data.nama_prodi }}
                        </template>
                    </Column>
                    <Column field="nama_kategori" header="Nama Kategori Biaya" style="min-width: 12rem" :sortable="true">
                        <template #body="{ data }">
                            {{ data.nama_kategori }}
                        </template>
                    </Column>
                    <Column field="jenis_kategori_biaya" header="Jenis Kategori Biaya" style="min-width: 12rem" :sortable="true">
                        <template #body="{ data }">
                            {{ data.jenis_kategori_biaya }}
                        </template>
                    </Column>
                    <Column field="tahun_ak" header="Tahun Akademik" style="min-width: 4rem" :sortable="true">
                        <template #body="{ data }">
                            {{ data.tahun_ak }}
                        </template>
                    </Column>
                    <Column field="biaya_kb" header="Biaya Akademik" style="min-width: 12rem; text-align: right" :sortable="true">
                        <template #body="{ data }">
                            {{ $formats(data.biaya_kb).format() }}
                        </template>
                    </Column>
                    <Column field="actions" header="Actions" bodyClass="text-center" style="min-width: 2rem">
                        <template #body="{ data }">
                            <div class="flex sm:flex-row">
                                <div class="mt-2">
                                    <Button type="button" v-tooltip.top="'edit kategori biaya'" icon="pi pi-pencil" label="" class="p-button-warning mt-2 ml-2" @click="editData(data)" />
                                </div>
                                <div class="mt-2">
                                    <Button type="button" v-tooltip.top="'hapus kategori biaya'" icon="pi pi-trash" label="" class="p-button-danger mt-2 ml-2" @click="deleteData(data)" />
                                </div>
                                <div class="mt-2">
                                    <Button type="button" v-tooltip.top="''" icon="" label="" class="p-button-secondary mt-2 ml-2" style="background-color: transparent; border: none" />
                                </div>
                            </div>
                        </template>
                    </Column>
                    <template #footer>
                        <Paginator :rows="perPage" :totalRecords="total_kategori_biaya" @page="onPage($event)"></Paginator>
                    </template>
                </DataTable>
            </div>

            <!-- MODAL INPUT -->
            <Dialog header="Tambah Data Kategori Biaya" v-model:visible="modalInput" :breakpoints="{ '960px': '75vw' }" :style="{ width: '40vw' }" :modal="true" @keydown.enter.prevent="register()" @hide="v$.$reset()">
                <div class="col-12 lg:col-12 p-fluid">
                    <span class="p-float-label mt-4">
                        <Dropdown
                            dataKey="value"
                            filter
                            id="jenis_kategori_biaya"
                            v-model.lazy="data.jenis_kategori_biaya"
                            :options="bayar"
                            optionLabel="text"
                            optionValue="value"
                            @blur="v$.data.jenis_kategori_biaya.$touch()"
                            :class="{ 'p-invalid': v$.data.jenis_kategori_biaya.$invalid ? !v$.data.jenis_kategori_biaya.$anyError : null }"
                        />
                        <label for="jenis_kategori_biaya">Jenis Kategori</label>
                        <small v-if="v$.data.jenis_kategori_biaya.$invalid" :class="{ 'p-error': v$.data.jenis_kategori_biaya.$invalid ? !v$.data.jenis_kategori_biaya.$anyError : null }">{{ checkValidasi('data', 'jenis_kategori_biaya') }}</small>
                    </span>
                    <span class="p-float-label mt-4">
                        <Dropdown
                            dataKey="value"
                            filter
                            id="kalender_bayar_id"
                            v-model.lazy="data.kalender_bayar_id"
                            :options="kalenderBayar"
                            optionLabel="text"
                            optionValue="value"
                            @change="setPreview($event.value)"
                            @blur="v$.data.kalender_bayar_id.$touch()"
                            :class="{ 'p-invalid': v$.data.kalender_bayar_id.$invalid ? !v$.data.kalender_bayar_id.$anyError : null }"
                        />
                        <label for="kalender_bayar_id">Nama Kategori</label>
                        <small v-if="v$.data.kalender_bayar_id.$invalid" :class="{ 'p-error': v$.data.kalender_bayar_id.$invalid ? !v$.data.kalender_bayar_id.$anyError : null }">{{ checkValidasi('data', 'kalender_bayar_id') }}</small>
                    </span>
                    <span class="p-float-label mt-4">
                        <InputText disabled id="name" required="true" autofocus v-model.lazy="preview.nama_kategori" />
                        <label for="name">Kategori Pembayaran</label>
                    </span>
                    <span class="p-float-label mt-4">
                        <InputText disabled id="name" required="true" autofocus v-model.lazy="preview.tanggal_pembukaan" />
                        <label for="name">Tanggal Pembukaan</label>
                    </span>
                    <span class="p-float-label mt-4">
                        <InputText disabled id="name" required="true" autofocus v-model.lazy="preview.tanggal_penutupan" />
                        <label for="name">Tanggal Penutupan</label>
                    </span>
                    <span class="p-float-label mt-4">
                        <Dropdown
                            dataKey="value"
                            filter
                            id="prodi_id"
                            v-model.lazy="data.prodi_id"
                            :options="prodi"
                            optionLabel="text"
                            optionValue="value"
                            @blur="v$.data.prodi_id.$touch()"
                            :class="{ 'p-invalid': v$.data.prodi_id.$invalid ? !v$.data.prodi_id.$anyError : null }"
                        />
                        <label for="prodi_id">Prodi</label>
                        <small v-if="v$.data.prodi_id.$invalid" :class="{ 'p-error': v$.data.prodi_id.$invalid ? !v$.data.prodi_id.$anyError : null }">{{ checkValidasi('data', 'prodi_id') }}</small>
                    </span>
                    <span class="p-float-label mt-4">
                        <Dropdown
                            dataKey="value"
                            filter
                            id="tahun_akademik_id"
                            v-model.lazy="data.tahun_akademik_id"
                            :options="tahun"
                            optionLabel="text"
                            optionValue="value"
                            @blur="v$.data.tahun_akademik_id.$touch()"
                            :class="{ 'p-invalid': v$.data.tahun_akademik_id.$invalid ? !v$.data.tahun_akademik_id.$anyError : null }"
                        />
                        <label for="tahun_akademik_id">Tahun Akademik</label>
                        <small v-if="v$.data.tahun_akademik_id.$invalid" :class="{ 'p-error': v$.data.tahun_akademik_id.$invalid ? !v$.data.tahun_akademik_id.$anyError : null }">{{ checkValidasi('data', 'tahun_akademik_id') }}</small>
                    </span>
                    <span class="p-float-label mt-4">
                        <InputText id="name" required="true" autofocus v-model.lazy="data.tahun_kb" @blur="v$.data.tahun_kb.$touch()" :class="{ 'p-invalid': v$.data.tahun_kb.$invalid ? !v$.data.tahun_kb.$anyError : null }" />
                        <label for="name">Tahun Kalender</label>
                        <small v-if="v$.data.tahun_kb.$invalid" :class="{ 'p-error': v$.data.tahun_kb.$invalid ? !v$.data.tahun_kb.$anyError : null }">{{ checkValidasi('data', 'tahun_kb') }}</small>
                    </span>
                    <span class="p-float-label mt-4">
                        <Dropdown
                            dataKey="value"
                            filter
                            id="semester"
                            v-model.lazy="data.semester"
                            :options="semester"
                            optionLabel="text"
                            optionValue="value"
                            @blur="v$.data.semester.$touch()"
                            :class="{ 'p-invalid': v$.data.semester.$invalid ? !v$.data.semester.$anyError : null }"
                        />
                        <label for="semester">Semester</label>
                        <small v-if="v$.data.semester.$invalid" :class="{ 'p-error': v$.data.semester.$invalid ? !v$.data.semester.$anyError : null }">{{ checkValidasi('data', 'semester') }}</small>
                    </span>
                    <span class="p-float-label mt-4">
                        <InputText id="name" required="true" autofocus v-model.lazy="data.tahunsk_kb" @blur="v$.data.tahunsk_kb.$touch()" :class="{ 'p-invalid': v$.data.tahunsk_kb.$invalid ? !v$.data.tahunsk_kb.$anyError : null }" />
                        <label for="name">Tahun SK</label>
                        <small v-if="v$.data.tahunsk_kb.$invalid" :class="{ 'p-error': v$.data.tahunsk_kb.$invalid ? !v$.data.tahunsk_kb.$anyError : null }">{{ checkValidasi('data', 'tahunsk_kb') }}</small>
                    </span>
                    <span class="p-float-label mt-4">
                        <InputNumber id="name" required="true" autofocus locale="id" v-model.lazy="data.biaya_kb" @blur="v$.data.biaya_kb.$touch()" :class="{ 'p-invalid': v$.data.biaya_kb.$invalid ? !v$.data.biaya_kb.$anyError : null }" />
                        <label for="name">Biaya</label>
                        <small v-if="v$.data.biaya_kb.$invalid" :class="{ 'p-error': v$.data.biaya_kb.$invalid ? !v$.data.biaya_kb.$anyError : null }">{{ checkValidasi('data', 'biaya_kb') }}</small>
                    </span>
                </div>

                <template #footer>
                    <Button label="Batal" @click="modalInput = false" icon="" class="p-button-secondary" />
                    <Button label="Simpan" @click="register()" icon="" class="p-button-success" :disabled="!isValid" />
                </template>
            </Dialog>

            <!-- MODAL EDIT -->
            <Dialog header="Edit Data kategori_biaya Biaya" v-model:visible="modalEdit" :breakpoints="{ '960px': '75vw' }" :style="{ width: '40vw' }" :modal="true" @keydown.enter.prevent="simpanEdit()" @hide="v$.$reset()">
                <div class="col-12 lg:col-12 p-fluid">
                    <span class="p-float-label mt-4">
                        <Dropdown
                            dataKey="value"
                            filter
                            id="jenis_kategori_biaya"
                            v-model.lazy="dataEdit.jenis_kategori_biaya"
                            :options="bayar"
                            optionLabel="text"
                            optionValue="value"
                            @blur="v$.dataEdit.jenis_kategori_biaya.$touch()"
                            :class="{ 'p-invalid': v$.dataEdit.jenis_kategori_biaya.$invalid ? !v$.dataEdit.jenis_kategori_biaya.$anyError : null }"
                        />
                        <label for="jenis_kategori_biaya">Jenis Kategori</label>
                        <small v-if="v$.dataEdit.jenis_kategori_biaya.$invalid" :class="{ 'p-error': v$.dataEdit.jenis_kategori_biaya.$invalid ? !v$.dataEdit.jenis_kategori_biaya.$anyError : null }">{{
                            checkValidasi('dataEdit', 'jenis_kategori_biaya')
                        }}</small>
                    </span>
                    <span class="p-float-label mt-4">
                        <Dropdown
                            dataEditKey="value"
                            filter
                            id="kalender_bayar_id"
                            v-model.lazy="dataEdit.kalender_bayar_id"
                            :options="kalenderBayar"
                            optionLabel="text"
                            optionValue="value"
                            @change="setPreview($event.value)"
                            @blur="v$.dataEdit.kalender_bayar_id.$touch()"
                            :class="{ 'p-invalid': v$.dataEdit.kalender_bayar_id.$invalid ? !v$.dataEdit.kalender_bayar_id.$anyError : null }"
                        />
                        <label for="kalender_bayar_id">Nama Kategori</label>
                        <small v-if="v$.dataEdit.kalender_bayar_id.$invalid" :class="{ 'p-error': v$.dataEdit.kalender_bayar_id.$invalid ? !v$.dataEdit.kalender_bayar_id.$anyError : null }">{{ checkValidasi('dataEdit', 'kalender_bayar_id') }}</small>
                    </span>
                    <span class="p-float-label mt-4">
                        <InputText disabled id="name" required="true" autofocus v-model.lazy="preview.nama_kategori" />
                        <label for="name">Kategori Pembayaran</label>
                    </span>
                    <span class="p-float-label mt-4">
                        <InputText disabled id="name" required="true" autofocus v-model.lazy="preview.tanggal_pembukaan" />
                        <label for="name">Tanggal Pembukaan</label>
                    </span>
                    <span class="p-float-label mt-4">
                        <InputText disabled id="name" required="true" autofocus v-model.lazy="preview.tanggal_penutupan" />
                        <label for="name">Tanggal Penutupan</label>
                    </span>
                    <span class="p-float-label mt-4">
                        <Dropdown
                            dataEditKey="value"
                            filter
                            id="prodi_id"
                            v-model.lazy="dataEdit.prodi_id"
                            :options="prodi"
                            optionLabel="text"
                            optionValue="value"
                            @blur="v$.dataEdit.prodi_id.$touch()"
                            :class="{ 'p-invalid': v$.dataEdit.prodi_id.$invalid ? !v$.dataEdit.prodi_id.$anyError : null }"
                        />
                        <label for="prodi_id">Prodi</label>
                        <small v-if="v$.dataEdit.prodi_id.$invalid" :class="{ 'p-error': v$.dataEdit.prodi_id.$invalid ? !v$.dataEdit.prodi_id.$anyError : null }">{{ checkValidasi('dataEdit', 'prodi_id') }}</small>
                    </span>
                    <span class="p-float-label mt-4">
                        <Dropdown
                            dataEditKey="value"
                            filter
                            id="tahun_akademik_id"
                            v-model.lazy="dataEdit.tahun_akademik_id"
                            :options="tahun"
                            optionLabel="text"
                            optionValue="value"
                            @blur="v$.dataEdit.tahun_akademik_id.$touch()"
                            :class="{ 'p-invalid': v$.dataEdit.tahun_akademik_id.$invalid ? !v$.dataEdit.tahun_akademik_id.$anyError : null }"
                        />
                        <label for="tahun_akademik_id">Tahun Akademik</label>
                        <small v-if="v$.dataEdit.tahun_akademik_id.$invalid" :class="{ 'p-error': v$.dataEdit.tahun_akademik_id.$invalid ? !v$.dataEdit.tahun_akademik_id.$anyError : null }">{{ checkValidasi('dataEdit', 'tahun_akademik_id') }}</small>
                    </span>
                    <span class="p-float-label mt-4">
                        <InputText id="name" required="true" autofocus v-model.lazy="dataEdit.tahun_kb" @blur="v$.dataEdit.tahun_kb.$touch()" :class="{ 'p-invalid': v$.dataEdit.tahun_kb.$invalid ? !v$.dataEdit.tahun_kb.$anyError : null }" />
                        <label for="name">Tahun Kalender</label>
                        <small v-if="v$.dataEdit.tahun_kb.$invalid" :class="{ 'p-error': v$.dataEdit.tahun_kb.$invalid ? !v$.dataEdit.tahun_kb.$anyError : null }">{{ checkValidasi('dataEdit', 'tahun_kb') }}</small>
                    </span>
                    <span class="p-float-label mt-4">
                        <Dropdown
                            dataEditKey="value"
                            filter
                            id="semester"
                            v-model.lazy="dataEdit.semester"
                            :options="semester"
                            optionLabel="text"
                            optionValue="value"
                            @blur="v$.dataEdit.semester.$touch()"
                            :class="{ 'p-invalid': v$.dataEdit.semester.$invalid ? !v$.dataEdit.semester.$anyError : null }"
                        />
                        <label for="semester">Semester</label>
                        <small v-if="v$.dataEdit.semester.$invalid" :class="{ 'p-error': v$.dataEdit.semester.$invalid ? !v$.dataEdit.semester.$anyError : null }">{{ checkValidasi('dataEdit', 'semester') }}</small>
                    </span>
                    <span class="p-float-label mt-4">
                        <InputText id="name" required="true" autofocus v-model.lazy="dataEdit.tahunsk_kb" @blur="v$.dataEdit.tahunsk_kb.$touch()" :class="{ 'p-invalid': v$.dataEdit.tahunsk_kb.$invalid ? !v$.dataEdit.tahunsk_kb.$anyError : null }" />
                        <label for="name">Tahun SK</label>
                        <small v-if="v$.dataEdit.tahunsk_kb.$invalid" :class="{ 'p-error': v$.dataEdit.tahunsk_kb.$invalid ? !v$.dataEdit.tahunsk_kb.$anyError : null }">{{ checkValidasi('dataEdit', 'tahunsk_kb') }}</small>
                    </span>
                    <span class="p-float-label mt-4">
                        <InputNumber
                            id="name"
                            required="true"
                            autofocus
                            locale="id"
                            v-model.lazy="dataEdit.biaya_kb"
                            @blur="v$.dataEdit.biaya_kb.$touch()"
                            :class="{ 'p-invalid': v$.dataEdit.biaya_kb.$invalid ? !v$.dataEdit.biaya_kb.$anyError : null }"
                        />
                        <label for="name">Biaya</label>
                        <small v-if="v$.dataEdit.biaya_kb.$invalid" :class="{ 'p-error': v$.dataEdit.biaya_kb.$invalid ? !v$.dataEdit.biaya_kb.$anyError : null }">{{ checkValidasi('dataEdit', 'biaya_kb') }}</small>
                    </span>
                </div>
                <template #footer>
                    <Button label="Batal" @click="modalEdit = false" icon="" class="p-button-secondary" />
                    <Button label="Simpan" @click="simpanEdit()" icon="" class="p-button-success" :disabled="!isValidEdit" />
                </template>
            </Dialog>
            <Dialog header="File kategori_biaya" v-model:visible="modalDetail" :breakpoints="{ '960px': '75vw' }" :style="{ width: '40vw' }" :modal="true" @keydown.enter.prevent="simpanEdit()" @hide="v$.$reset()">
                <div class="col-12 lg:col-12 p-fluid">
                    <div>
                        <span class="flex justify-content-center mt-4">
                            <center><Image :src="src1" alt="" imageStyle="height: 130px; width: 130px" preview /></center>
                        </span>
                    </div>
                </div>
                <template #footer>
                    <Button label="Batal" @click="modalDetail = false" icon="" class="p-button-secondary" />
                </template>
            </Dialog>
        </div>
    </div>
</template>
<script>
import { FilterMatchMode } from 'primevue/api';
import useVuelidate from '@vuelidate/core';
import { required, numeric } from '@vuelidate/validators';
export default {
    setup() {
        return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
    },
    data() {
        return {
            kategori_biaya: [],
            total_kategori_biaya: null,
            prodi: [],
            bayar: [],
            kalenderBayar: [],
            field: ['nama_kategori', 'tahun_kb', 'nama_prodi', 'koed_prodi'],
            loading1: true,
            modalInput: false,
            modalEdit: false,
            modalDetail: false,
            preview: {},
            semester: [
                { value: 'GANJIL', text: 'Ganjil' },
                { value: 'GENAP', text: 'Genap' },
            ],
            data: {
                kategori_bayar_id: null,
                kalender_bayar_id: null,
                tahun_akademik_id: null,
                prodi_id: null,
                biaya_kb: 0,
                tahun_kb: null,
                tahunsk_kb: null,
                semester: null,
                jenis_kategori_biaya: null,
            },
            busy: false,
            dataEdit: {},
            filters1: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            currentPage: 0,
            perPage: 25,
            perPages: [10, 25, 50, 100],
        };
    },
    validations() {
        return {
            data: {
                kategori_bayar_id: {
                    required,
                },
                kalender_bayar_id: {
                    required,
                },
                tahun_akademik_id: {
                    required,
                },
                prodi_id: {
                    required,
                },
                biaya_kb: {
                    required,
                    numeric,
                },
                tahun_kb: {
                    required,
                },
                tahunsk_kb: {
                    required,
                },
                semester: {
                    required,
                },
                jenis_kategori_biaya: {
                    required,
                },
            },
            dataEdit: {
                kategori_bayar_id: {
                    required,
                },
                kalender_bayar_id: {
                    required,
                },
                tahun_akademik_id: {
                    required,
                },
                prodi_id: {
                    required,
                },
                biaya_kb: {
                    required,
                    numeric,
                },
                tahun_kb: {
                    required,
                },
                tahunsk_kb: {
                    required,
                },
                semester: {
                    required,
                },
                jenis_kategori_biaya: {
                    required,
                },
            },
        };
    },
    computed: {
        formString() {
            return JSON.stringify(this.data);
        },
        isValid() {
            return !this.v$.data.$invalid;
        },
        isDirty() {
            return this.v$.data.$anyDirty;
        },
        isValidEdit() {
            return !this.v$.dataEdit.$invalid;
        },
        isDirtyEdit() {
            return this.v$.dataEdit.$anyDirty;
        },
    },
    mounted() {
        this.get_kategori_biaya(this.currentPage);
        this.get_data();
        this.v$.$reset();
    },
    methods: {
        clearFilter1() {
            this.filters1 = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        onPage(x) {
            this.currentPage = x.page;
            this.get_kategori_biaya(this.currentPage);
        },
        setPreview(x) {
            this.kalenderBayar.forEach((ele) => {
                if (x == ele.id) {
                    ele.tanggal_pembukaan = this.$moment(ele.tanggal_pembukaan).format('LL');
                    ele.tanggal_penutupan = this.$moment(ele.tanggal_penutupan).format('LL');
                    this.preview = ele;
                }
            });
        },
        async get_data() {
            let vm = this;

            let bayar = await vm.$axiosbilling('masterJenisBiaya/list', {});
            if (bayar.data.message == 'sukses') {
                vm.bayar = bayar.data.data.map((item) => {
                    return { ...item, value: item.nama_jenis_biaya, text: item.nama_jenis_biaya };
                });
            }

            let prodi = await vm.$axiosbilling('prodi/listHalaman');
            if (prodi.data.message == 'sukses') {
                vm.prodi = prodi.data.data.map((item) => {
                    return { ...item, value: item.id, text: item.nama_prodi };
                });
            }

            let tahun = await vm.$axiosbilling('tahunAkademik/listHalaman');
            if (tahun.data.message == 'sukses') {
                vm.tahun = tahun.data.data.map((item) => {
                    return { ...item, value: item.id, text: item.tahun_ak };
                });
            }

            let kalenderBayar = await vm.$axiosbilling('kalenderBayar/listHalaman');
            if (kalenderBayar.data.message == 'sukses') {
                vm.kalenderBayar = kalenderBayar.data.data.map((item) => {
                    return { ...item, value: item.kalenderBayarId, text: item.nama_kategori };
                });
            }
        },
        async get_kalender_bayar(kalenderBayarId) {
            // console.log(kalenderBayarId);
            try {
                let vm = this;
                let res = await vm.$axiosbilling('kalenderBayar/listByKategoriBayarId/' + kalenderBayarId);
                if (res.data.status == 200) {
                    vm.list_kalender_bayar = res.data.data;
                    // console.log(vm.list_kalender_bayar, "kalender bayar");
                    for (let i = 0; i < vm.list_kalender_bayar.length; i++) {
                        let a = {};
                        const el = vm.list_kalender_bayar[i];
                        a.value = el.id;
                        a.text = el.tanggal_kalender_bayar;
                        vm.option_kalender_bayar.push(a);
                    }
                } else {
                    throw 'no data';
                }
            } catch (err) {
                alert(err);
            }
        },
        async get_kategori_biaya(x) {
            let vm = this;
            vm.loading1 = true;
            let res = await vm.$axiosbilling.post('kategoriBiaya/list', { halaman: x + 1, jumlah: vm.perPage });
            console.log(res.data, 'iki');
            if (res.data.message == 'sukses') {
                vm.kategori_biaya = res.data.data.map((item, idx) => {
                    return { ...item, no: idx + 1 + x * vm.perPage };
                });
            }
            vm.total_kategori_biaya = res.data.count;
            vm.loading1 = false;
        },
        register() {
            let vm = this;
            console.log(vm.data);
            if (vm.isValid && vm.isDirty) {
                vm.busy = true;
                vm.$axiosbilling
                    .post('kategoriBiaya/register', vm.data)
                    .then((res) => {
                        // console.log(res.data.status);
                        if (res.data.status == 200) {
                            if (res.data.message == 'sukses') {
                                vm.data = {
                                    kategori_bayar_id: null,
                                    kalender_bayar_id: null,
                                    tahun_akademik_id: null,
                                    prodi_id: null,
                                    biaya_kb: 0,
                                    tahun_kb: null,
                                    tahunsk_kb: null,
                                    semester: null,
                                    jenis_kategori_biaya: null,
                                    nama_jenis_biaya: null,
                                };
                                vm.modalInput = false;
                                vm.$toast.add({ severity: 'success', summary: 'Konfirmasi', detail: 'Berhasil Mendaftarkan kategori_biaya Biaya', life: 3000 });
                                this.get_kategori_biaya(this.currentPage);
                            } else {
                                vm.modalInput = false;
                                vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                            }
                        } else {
                            vm.modalInput = false;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        vm.modalInput = false;
                        vm.$toast.add({ severity: 'error', summary: 'Konfirmasi', detail: 'Terjadi kesalahan pada server', life: 3000 });
                    });
            } else {
                vm.$toast.add({ severity: 'error', summary: 'Peringatan', detail: 'Masih terdapat isian data yang tidak valid', life: 3000 });
            }
        },
        editData(data) {
            let vm = this;
            vm.dataEdit = data;
            vm.setPreview(data.kalender_bayar_id);
            vm.modalEdit = true;
        },
        detail(data) {
            let vm = this;
            vm.dataEdit = data;
            vm.src1 = vm.$ip2 + data.file_kategori_bayar;
            vm.modalDetail = true;
        },
        simpanEdit() {
            let vm = this;
            if (vm.isValidEdit) {
                let formData = new FormData();
                formData.append('nama_kategori', vm.usul.nama_kategori);
                formData.append('id', vm.usul.id);
                if (vm.file1) {
                    formData.append('file1', vm.file1);
                }
                vm.$axiosbilling
                    .post('kategoriBiaya/update', formData)
                    .then((res) => {
                        // console.log(res.data.status);
                        if (res.data.status == 200) {
                            if (res.data.message == 'sukses') {
                                vm.modalEdit = false;
                                vm.$toast.add({ severity: 'success', summary: 'Konfirmasi', detail: 'Berhasil Mengupdate Data kategori_biaya Biaya', life: 3000 });
                                this.get_kategori_biaya();
                            } else {
                                vm.modalEdit = false;
                                vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                            }
                        } else {
                            vm.modalEdit = false;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        vm.modalEdit = false;
                        vm.$toast.add({ severity: 'error', summary: 'Konfirmasi', detail: 'Terjadi kesalahan pada server', life: 3000 });
                    });
            } else {
                vm.$toast.add({ severity: 'error', summary: 'Peringatan', detail: 'Masih terdapat isian data yang tidak valid', life: 3000 });
            }
        },
        approve(data, status) {
            let vm = this;
            vm.busy = true;
            data.status_kategori_bayar = status;
            vm.$axiosbilling
                .post('kategoriBiaya/update', data)
                .then((res) => {
                    // console.log(res.data.status);
                    if (res.data.status == 200) {
                        if (res.data.message == 'sukses') {
                            vm.$confirm.close();
                            vm.$toast.add({ severity: 'success', summary: 'Konfirmasi', detail: 'Berhasil Mennyetujui kategori_biaya Biaya', life: 3000 });
                            this.get_kategori_biaya();
                        } else {
                            vm.$confirm.close();
                            vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                        }
                    } else {
                        vm.$confirm.close();
                    }
                })
                .catch((err) => {
                    console.log(err);
                    vm.modalApprove = false;
                    vm.$toast.add({ severity: 'error', summary: 'Konfirmasi', detail: 'Terjadi kesalahan pada server', life: 3000 });
                });
        },
        simpanDelete(data) {
            let vm = this;
            data.id = data.kategoriBiayaId;
            vm.$axiosbilling
                .post('kategoriBiaya/delete', { id: data.id })
                .then((res) => {
                    // console.log(res.data.status);
                    if (res.data.status == 200) {
                        if (res.data.message == 'sukses') {
                            vm.$toast.add({ severity: 'success', summary: 'Konfirmasi', detail: 'Berhasil Menghapus Data kategori_biaya Biaya', life: 3000 });
                            this.get_kategori_biaya();
                            this.$confirm.close();
                        } else {
                            vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                            this.$confirm.close();
                        }
                    } else {
                        vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: 'data sudah ada', life: 3000 });
                        this.$confirm.close();
                    }
                })
                .catch((err) => {
                    console.log(err);
                    vm.$toast.add({ severity: 'error', summary: 'Konfirmasi', detail: 'Terjadi kesalahan pada server', life: 3000 });
                    this.$confirm.close();
                });
        },
        deleteData(data) {
            // console.log(data, 'ini datanya bossss');
            this.$confirm.require({
                message: 'Apakah anda yakin ingin menghapus ini?',
                header: 'Konfirmasi',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.simpanDelete(data);
                },
                reject: () => {
                    this.$confirm.close();
                },
            });
        },
        approveData(data, status) {
            // console.log(data, 'ini datanya bossss');
            this.$confirm.require({
                message: 'Apakah anda yakin ingin mennyetujui kategori_biaya ini?',
                header: 'Konfirmasi',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.approve(data, status);
                },
                reject: () => {
                    this.$confirm.close();
                },
            });
        },
        checkValidasi(type, fieldName) {
            const field = this.v$[type][fieldName];
            let x = field.$errors[0].$validator;
            if (x == 'required') {
                return '* Data wajib diisi';
            } else if (x == 'email') {
                return '* Data harus berbentuk email';
            } else if (x == 'numeric') {
                return '* Data harus terdiri hanxa dari angka';
            } else if (x == 'minLength') {
                return `* Data minimal ${field.$errors[0].$params.min} digits`;
            } else if (x == 'maxLength') {
                return `* Data maksimal ${field.$errors[0].$params.max} digits`;
            } else if (x == 'alphaNum') {
                return `* Data tidak boleh mengandung spasi atau simbol`;
            } else if (x == 'official') {
                return `* Data tidak boleh mengandung spasi atau simbol`;
            }
        },
    },
};
</script>
<style scoped lang="scss">
@import '@/assets/demo/styles/badges.scss';

::v-deep(.p-datatable-frozen-tbody) {
    font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
    font-weight: bold;
}

::v-deep(.p-progressbar) {
    height: 0.5rem;
    background-color: #d8dadc;

    .p-progressbar-value {
        background-color: #607d8b;
    }
}
</style>
