<template>
    <Button v-tooltip.hover.click="'Detail'" type="button" icon="pi pi-info" label="" class="p-button-primary m-1" @click="(display = true), getDetail()" />
    <Dialog header="Detail Mahasiswa" v-model:visible="display" :breakpoints="{ '960px': '75vw' }" :style="{ width: '40vw' }" :modal="true">
        <div class="grid m-1">
            <div class="col-5">
                <h6 class="box m-0 p-0">Nama Mahasiswa</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{ mahasiswa.nama_mahasiswa }}</p>
            </div>
            <div class="col-5">
                <h6 class="box m-0 p-0">NIM</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{ mahasiswa.NIM }}</p>
            </div>
            <div class="col-5">
                <h6 class="box m-0 p-0">NIK</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{ mahasiswa.NIK }}</p>
            </div>
            <div class="col-5">
                <h6 class="box m-0 p-0">NISN</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{ mahasiswa.NISN }}</p>
            </div>
            <div class="col-5">
                <h6 class="box m-0 p-0">NPWP</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{ mahasiswa.NPWP }}</p>
            </div>
            <div class="col-5">
                <h6 class="box m-0 p-0">KSP</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{ mahasiswa.nomor_kps }}</p>
            </div>
            <div class="col-5">
                <h6 class="box m-0 p-0">Penerima KSP</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{ mahasiswa.penerima_kps }}</p>
            </div>
            <div class="col-5">
                <h6 class="box m-0 p-0">Kode Pos</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{ mahasiswa.kodepos }}</p>
            </div>
            <!-- <div class="col-5">
                <h6 class="box m-0 p-0">Wilayah</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{mahasiswa.nama_wilayah}}</p>
            </div> -->
            <div class="col-5">
                <h6 class="box m-0 p-0">Kelurahan</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{ mahasiswa.kelurahan }}</p>
            </div>
            <div class="col-5">
                <h6 class="box m-0 p-0">Dusun</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{ mahasiswa.dusun }}</p>
            </div>
            <div class="col-5">
                <h6 class="box m-0 p-0">Rt / Rw</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{ mahasiswa.rt + ' / ' + mahasiswa.rw }}</p>
            </div>
            <div class="col-5">
                <h6 class="box m-0 p-0">Alamat</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{ mahasiswa.jalan }}</p>
            </div>
            <!-- <div class="col-5">
                <h6 class="box m-0 p-0">Tempat Tinggal</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{mahasiswa.nama_jenis_tinggal}}</p>
            </div> -->
            <div class="col-5">
                <h6 class="box m-0 p-0">Kewarganegaraan</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{ mahasiswa.kewarganegaraan }}</p>
            </div>
            <!-- <div class="col-5">
                <h6 class="box m-0 p-0">Nama Agama</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{mahasiswa.nama_agama}}</p>
            </div> -->
            <div class="col-5">
                <h6 class="box m-0 p-0">Jenis Kelamin</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{ mahasiswa.jenis_kelamin == 'P' ? 'Perempuan' : 'Laki - Laki' }}</p>
            </div>
            <div class="col-5">
                <h6 class="box m-0 p-0">Tanggal Lahir</h6>
            </div>
            <div class="col-7">
                <!-- <p class="box m-0 p-0">{{mahasiswa.tanggal_lahir}}</p> -->
                <p class="box m-0 p-0">{{ $moment(mahasiswa.tanggal_lahir).format('LL') }}</p>
            </div>
            <div class="col-5">
                <h6 class="box m-0 p-0">Tempat Lahir</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{ mahasiswa.tempat_lahir }}</p>
            </div>
            <div class="col-5">
                <h6 class="box m-0 p-0">No. HP</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{ mahasiswa.handphone }}</p>
            </div>
            <div class="col-5">
                <h6 class="box m-0 p-0">No. Telepon</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{ mahasiswa.telepon }}</p>
            </div>
            <div class="col-5">
                <h6 class="box m-0 p-0">Email</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{ mahasiswa.email }}</p>
            </div>
            <!-- <div class="col-5">
                <h6 class="box m-0 p-0">Alat Transportasi</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{mahasiswa.nama_alat_transportasi}}</p>
            </div> -->
            <!-- <div class="col-5">
                <h6 class="box m-0 p-0">Kebutuhan Khusus</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{mahasiswa.nama_kebutuhan_khusus_mahasiswa}}</p>
            </div> -->

            <hr class="col-12 mb-0 mt-3" />
            <div class="col-12">
                <h6 class="box m-0 pb-1 text-center">Ibu</h6>
            </div>
            <div class="col-5">
                <h6 class="box m-0 p-0">NIK</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{ mahasiswa.NIK_ibu_kandung }}</p>
            </div>
            <div class="col-5">
                <h6 class="box m-0 p-0">Nama</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{ mahasiswa.nama_ibu_kandung }}</p>
            </div>
            <div class="col-5">
                <h6 class="box m-0 p-0">Tanggal Lahir</h6>
            </div>
            <div class="col-7">
                <!-- <p class="box m-0 p-0">{{mahasiswa.tanggal_lahir_ibu_kandung}}</p> -->
                <p class="box m-0 p-0">{{ $moment(mahasiswa.tanggal_lahir_ibu_kandung).format('LL') }}</p>
            </div>
            <!-- <div class="col-5">
                <h6 class="box m-0 p-0">Jenjang Didik</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{mahasiswa.nama_jenjang_didik_ibu_kandung}}</p>
            </div> -->
            <!-- <div class="col-5">
                <h6 class="box m-0 p-0">Kebutuhan Khusus</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{mahasiswa.nama_kebutuhan_khusus_ibu_kandung}}</p>
            </div> -->
            <!-- <div class="col-5">
                <h6 class="box m-0 p-0">Nama Pekerjaan</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{mahasiswa.nama_pekerjaan_ibu_kandung}}</p>
            </div> -->
            <!-- <div class="col-5">
                <h6 class="box m-0 p-0">Penghasilan</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{mahasiswa.range_penghasilan_ibu_kandung}}</p>
            </div> -->

            <hr class="col-12 mb-0 mt-3" />
            <div class="col-12">
                <h6 class="box m-0 pb-1 text-center">Ayah</h6>
            </div>
            <div class="col-5">
                <h6 class="box m-0 p-0">NIK</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{ mahasiswa.NIK_ayah }}</p>
            </div>
            <div class="col-5">
                <h6 class="box m-0 p-0">Nama</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{ mahasiswa.nama_ayah }}</p>
            </div>
            <div class="col-5">
                <h6 class="box m-0 p-0">Tanggal Lahir</h6>
            </div>
            <div class="col-7">
                <!-- <p class="box m-0 p-0">{{mahasiswa.tanggal_lahir_ayah}}</p> -->
                <p class="box m-0 p-0">{{ $moment(mahasiswa.tanggal_lahir_ayah).format('LL') }}</p>
            </div>
            <!-- <div class="col-5">
                <h6 class="box m-0 p-0">Jenjang Didik</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{mahasiswa.nama_jenjang_didik_ayah}}</p>
            </div> -->
            <!-- <div class="col-5">
                <h6 class="box m-0 p-0">Kebutuhan Khusus</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{mahasiswa.nama_kebutuhan_khusus_ayah}}</p>
            </div> -->
            <!-- <div class="col-5">
                <h6 class="box m-0 p-0">Nama Pekerjaan</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{mahasiswa.nama_pekerjaan_ayah}}</p>
            </div> -->
            <!-- <div class="col-5">
                <h6 class="box m-0 p-0">Penghasilan</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{mahasiswa.range_penghasilan_ayah}}</p>
            </div> -->

            <hr class="col-12 mb-0 mt-3" />
            <div class="col-12">
                <h6 class="box m-0 pb-1 text-center">Wali</h6>
            </div>
            <div class="col-5">
                <h6 class="box m-0 p-0">NIK</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{ mahasiswa.NIK_wali }}</p>
            </div>
            <div class="col-5">
                <h6 class="box m-0 p-0">Nama</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{ mahasiswa.nama_wali }}</p>
            </div>
            <div class="col-5">
                <h6 class="box m-0 p-0">Tanggal Lahir</h6>
            </div>
            <div class="col-7">
                <!-- <p class="box m-0 p-0">{{mahasiswa.tanggal_lahir_wali}}</p> -->
                <p class="box m-0 p-0">{{ $moment(mahasiswa.tanggal_lahir_wali).format('LL') }}</p>
            </div>
            <!-- <div class="col-5">
                <h6 class="box m-0 p-0">Jenjang Didik</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{mahasiswa.nama_jenjang_didik_wali}}</p>
            </div>
            <div class="col-5">
                <h6 class="box m-0 p-0">Nama Pekerjaan</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{mahasiswa.nama_pekerjaan_wali}}</p>
            </div>
            <div class="col-5">
                <h6 class="box m-0 p-0">Penghasilan</h6>
            </div>
            <div class="col-7">
                <p class="box m-0 p-0">{{mahasiswa.range_penghasilan_wali}}</p>
            </div> -->
        </div>
        <template #footer>
            <Button label="Batal" @click="display = false" icon="" class="p-button-secondary" />
        </template>
    </Dialog>
</template>

<script>
import moment from 'moment';
moment.locale('ID');
export default {
    props: ['data'],
    data() {
        return {
            mahasiswa: {},
            moment,
            display: false,
        };
    },
    computed: {
        formString() {
            return JSON.stringify(this.data);
        },
        detail() {
            return this.dataDetail;
        },
    },
    mounted() {
        // this.v$.$reset();
    },
    methods: {
        opsi(cek, data) {
            if (data == 'boolean')
                data = [
                    { value: 0, text: 'Tidak Ada' },
                    { value: 1, text: 'Ada' },
                ];
            let result = cek;
            data.forEach((el) => {
                if (cek == el.value) result = el.text;
            });
            return result;
        },
        async getDetail() {
            const vm = this;
            let mahasiswa = await vm.$axiossimadu.get('registrasiMahasiswa/detailsById/' + vm.data.registrasi_mahasiswa_id);
            if (mahasiswa.data.status == 200) {
                vm.mahasiswa = mahasiswa.data.data[0];
            }
        },
    },
};
</script>
