<template>
    <Button v-tooltip.hover.click="'Riwayat Hereg'" type="button" icon="pi pi-fw pi-user" label="" class="p-button-warning m-1" @click="display = true, getHereg()" />
    <Dialog header="Riwayat Herreg" v-model:visible="display" :breakpoints="{ '960px': '75vw' }" :style="{ width: '60vw' }" :modal="true">
        <DataTable
            @page="pages($event)"
            :value="hereg"
            class="p-datatable-gridlines"
            v-model:filters="filters1"
            dataKey="no"
            :rowHover="true"
            filterDisplay="menu"
            :loading="loading1"
            :filters="filters1"
            responsiveLayout="scroll"
            :globalFilterFields="field"
            :resizableColumns="true"
        >
            <template #empty> Tidak ada data. </template>
            <template #loading> Loading data. mohon tunggu.... </template>
            <Column field="no" header="No" style="width: 0px"></Column>
            <Column field="tanggal_herreg" header="Tanggal Hereg" style="min-width: 10rem" :sortable="true"></Column>
            <Column field="hereg_semester" header="Semester" style="min-width: 4rem" :sortable="true"></Column>
            <Column field="IPS" header="IPS" style="min-width: 4rem" :sortable="true"></Column>
            <Column field="IPK" header="IPK" style="min-width: 4rem" :sortable="true"></Column>
            <Column field="sks_semester" header="SKS Semester" style="min-width: 4rem" :sortable="true"></Column>
            <Column field="total_sks" header="Total SKS" style="min-width: 4rem" :sortable="true"></Column>
            <Column field="biaya_kuliah" header="Biaya Kuliah" style="min-width: 10rem" :sortable="true"></Column>
        </DataTable>
        <template #footer>
            <Button label="Batal" @click="display = false" icon="" class="p-button-secondary" />
        </template>
    </Dialog>
</template>

<script>
import moment from 'moment';
import { FilterMatchMode } from 'primevue/api';
moment.locale('ID');
export default {
    props: ['data'],
    data() {
        return {
            field: [ 'no', 'tanggal_herreg', 'hereg_semester', 'IPS', 'IPK', 'sks_semester', 'total_sks', 'biaya_kuliah'],
            hereg: [],
            moment,
            display: false,
            loading1: false,
            filters1: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            perPage: 10,
            perPages: [10, 25, 50, 100],
        };
    },
    computed: {
        formString() {
            return JSON.stringify(this.data);
        },
        detail() {
            return this.dataDetail;
        },
    },
    mounted() {
        // this.v$.$reset();
    },
    methods: {
        async getHereg(){
            const vm = this
            vm.loading1 = true
            let hereg = await vm.$axiossimadu.post('heregistrasiMahasiswa/listRiwayatHerregByRegistrasiMahasiswaId', {registrasi_mahasiswa_id: vm.data.registrasi_mahasiswa_id});
            // console.log(hereg);
            vm.hereg = []
            if(hereg.data.status == 200){
                for (let i = 0; i < hereg.data.data.length; i++) {
                    const item = hereg.data.data[i];
                    vm.hereg.push({
                        ...item,
                        no: i + 1,
                        tanggal_herreg: vm.$moment(item.tanggal_herreg).format('LL')
                    })
                }
            }
            vm.loading1 = false
        },
        opsi(cek, data){
            if(data == 'boolean') data = [{value: 0, text: 'Tidak Ada'}, {value: 1, text: 'Ada'}]
            let result = cek
            data.forEach(el => {
                if(cek == el.value) result = el.text
            });
            return result
        },
    },
};
</script>
