<template>
    <div class="grid">
        <Toast />
        <div class="col-12">
            <div class="card">
                <h5>Usulan Biaya</h5>
                <div class="grid p-fluid">
                    <div class="col-12 sm:col-2">
                        <div class="add"><Button type="button" icon="pi pi-plus" label="Tambah" class="p-button mb-2 mt-2" @click="modalInput = true" /></div>
                    </div>
                </div>
                <DataTable
                    @page="pages($event)"
                    :value="usulan"
                    :paginator="true"
                    class="p-datatable-gridlines"
                    :rows="perPage"
                    v-model:filters="filters1"
                    dataKey="id"
                    :rowHover="true"
                    filterDisplay="menu"
                    :loading="loading1"
                    :filters="filters1"
                    responsiveLayout="scroll"
                    :globalFilterFields="field"
                    @row-dblclick="detail($event.data)"
                >
                    <template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <div class="add"><Dropdown :options="perPages" id="Role" required="true" autofocus v-model.lazy="perPage" /> Per Halaman dari {{ total_kategori_bayar }} data</div>
                            <div class="filter">
                                <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2" @click="clearFilter1()" />
                                <span class="p-input-icon-left mb-2">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filters1.global.value" placeholder="Keyword Search" style="width: 100%" />
                                </span>
                            </div>
                        </div>
                    </template>
                    <template #empty> Tidak ada data. </template>
                    <template #loading> Loading data. mohon tunggu.... </template>
                    <Column field="no" header="No" style="width: 50px">
                        <template #body="{ data }">
                            {{ data.no }}
                        </template>
                    </Column>
                    <Column field="nama_kategori" header="Nama Usulan Biaya" style="min-width: 12rem" :sortable="true">
                        <template #body="{ data }">
                            {{ data.nama_kategori }}
                        </template>
                    </Column>
                    <Column field="status" header="Status Usulan Biaya" style="min-width: 12rem" :sortable="true">
                        <template #body="{ data }">
                            {{ data.status }}
                        </template>
                    </Column>
                    <Column field="actions" header="Actions" bodyClass="text-center" style="min-width: 2rem">
                        <template #body="{ data }">
                            <div class="flex sm:flex-row">
                                <div class="mt-2" v-if="data.status_kategori_bayar <= 1">
                                    <Button type="button" v-tooltip.top="'edit usulan'" icon="pi pi-pencil" label="" class="p-button-warning mt-2 ml-2" @click="editData(data)" />
                                </div>
                                <div class="mt-2" v-if="data.status_kategori_bayar != 4 && data.status_kategori_bayar != 0">
                                    <Button type="button" v-tooltip.top="'tolak usulan'" icon="pi pi-ban" label="" class="p-button-danger mt-2 ml-2" @click="approveData(data, 0)" />
                                </div>
                                <div class="mt-2" v-if="data.status_kategori_bayar == 0">
                                    <Button type="button" v-tooltip.top="'ajukan usulan'" icon="pi pi-check" label="" class="p-button-secondary mt-2 ml-2" @click="approveData(data, 1)" />
                                </div>
                                <div class="mt-2" v-if="data.status_kategori_bayar == 1">
                                    <Button type="button" v-tooltip.top="'setujui usulan'" icon="pi pi-check" label="" class="p-button-primary mt-2 ml-2" @click="approveData(data, 2)" />
                                </div>
                                <div class="mt-2" v-if="data.status_kategori_bayar == 2">
                                    <Button type="button" v-tooltip.top="'setujui usulan'" icon="pi pi-check" label="" class="p-button-info mt-2 ml-2" @click="approveData(data, 3)" />
                                </div>
                                <div class="mt-2" v-if="data.status_kategori_bayar == 3">
                                    <Button type="button" v-tooltip.top="'setujui usulan'" icon="pi pi-check" label="" class="p-button-success mt-2 ml-2" @click="approveData(data, 4)" />
                                </div>
                                <div class="mt-2">
                                    <Button type="button" v-tooltip.top="''" icon="" label="" class="p-button-secondary mt-2 ml-2" style="background-color: transparent; border: none" />
                                </div>
                                <!-- <div class="mt-2">
                                    <Button type="button" v-tooltip.top="'hapus usulan'" icon="pi pi-trash" label="" class="p-button-danger mt-2 ml-2" @click="deleteData(data)" />
                                </div> -->
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>

            <!-- MODAL INPUT -->
            <Dialog header="Tambah Data Usulan Biaya" v-model:visible="modalInput" :breakpoints="{ '960px': '75vw' }" :style="{ width: '40vw' }" :modal="true" @keydown.enter.prevent="register()" @hide="v$.$reset()">
                <div class="col-12 lg:col-12 p-fluid">
                    <span class="p-float-label mt-4">
                        <InputText id="name" required="true" autofocus v-model.lazy="data.nama_kategori" @blur="v$.data.nama_kategori.$touch()" :class="{ 'p-invalid': v$.data.nama_kategori.$invalid ? !v$.data.nama_kategori.$anyError : null }" />
                        <label for="name">Nama Usulan Biaya</label>
                        <small v-if="v$.data.nama_kategori.$invalid" :class="{ 'p-error': v$.data.nama_kategori.$invalid ? !v$.data.nama_kategori.$anyError : null }">{{ checkValidasi('data', 'nama_kategori') }}</small>
                    </span>
                </div>
                <div class="grid">
                    <div class="col-6">
                        <span class="p-float-label mt-4">
                            <FileUpload
                                ref="file1"
                                id="foto"
                                name="Foto"
                                :fileLimit="1"
                                :multiple="false"
                                accept="image/*"
                                :maxFileSize="10000000"
                                :showUploadButton="false"
                                :previewWidth="100"
                                chooseLabel="Pilih Foto"
                                cancelLabel="Batal"
                                @change="handleFile()"
                                @remove="src1 = null"
                                @clear="src1 = null"
                            >
                                <template #empty>
                                    <p>Pilih File Foto Bukti</p>
                                </template>
                            </FileUpload>
                            <!-- <small v-if="v$.data_skkm.file_skkm.$invalid" :class="{ 'p-error': v$.data_skkm.file_skkm.$invalid ? !v$.data_skkm.file_skkm.$anyError : null }">{{ checkValidasi('data_skkm', 'file_skkm') }}</small> -->
                        </span>
                    </div>
                    <div class="col-6">
                        <span class="flex justify-content-center mt-4">
                            <center><Image :src="src1" alt="" imageStyle="height: 130px; width: 130px" preview /></center>
                        </span>
                    </div>
                </div>

                <template #footer>
                    <Button label="Batal" @click="modalInput = false" icon="" class="p-button-secondary" />
                    <Button label="Simpan" @click="register()" icon="" class="p-button-success" :disabled="!isValid" />
                </template>
            </Dialog>

            <!-- MODAL EDIT -->
            <Dialog header="Edit Data Usulan Biaya" v-model:visible="modalEdit" :breakpoints="{ '960px': '75vw' }" :style="{ width: '40vw' }" :modal="true" @keydown.enter.prevent="simpanEdit()" @hide="v$.$reset()">
                <div class="col-12 lg:col-12 p-fluid">
                    <span class="p-float-label mt-4">
                        <InputText
                            id="name"
                            required="true"
                            autofocus
                            v-model.lazy="dataEdit.nama_kategori"
                            @blur="v$.dataEdit.nama_kategori.$touch()"
                            :class="{ 'p-invalid': v$.dataEdit.nama_kategori.$invalid ? !v$.dataEdit.nama_kategori.$anyError : null }"
                        />
                        <label for="name">Nama Usulan Biaya</label>
                        <small v-if="v$.dataEdit.nama_kategori.$invalid" :class="{ 'p-error': v$.dataEdit.nama_kategori.$invalid ? !v$.dataEdit.nama_kategori.$anyError : null }">{{ checkValidasi('dataEdit', 'nama_kategori') }}</small>
                    </span>
                </div>
                <div class="grid">
                    <div class="col-6">
                        <span class="p-float-label mt-4">
                            <FileUpload
                                ref="file1"
                                id="foto"
                                name="Foto"
                                :fileLimit="1"
                                :multiple="false"
                                accept="image/*"
                                :maxFileSize="10000000"
                                :showUploadButton="false"
                                :previewWidth="100"
                                chooseLabel="Pilih Foto"
                                cancelLabel="Batal"
                                @change="handleFile()"
                                @remove="src1 = null"
                                @clear="src1 = null"
                            >
                                <template #empty>
                                    <p>Pilih File Foto Bukti</p>
                                </template>
                            </FileUpload>
                            <!-- <small v-if="v$.data_skkm.file_skkm.$invalid" :class="{ 'p-error': v$.data_skkm.file_skkm.$invalid ? !v$.data_skkm.file_skkm.$anyError : null }">{{ checkValidasi('data_skkm', 'file_skkm') }}</small> -->
                        </span>
                    </div>
                    <div class="col-6">
                        <span class="flex justify-content-center mt-4">
                            <center><Image :src="src1" alt="" imageStyle="height: 130px; width: 130px" preview /></center>
                        </span>
                    </div>
                </div>
                <template #footer>
                    <Button label="Batal" @click="modalEdit = false" icon="" class="p-button-secondary" />
                    <Button label="Simpan" @click="simpanEdit()" icon="" class="p-button-success" :disabled="!isValidEdit" />
                </template>
            </Dialog>
            <Dialog header="File Usulan" v-model:visible="modalDetail" :breakpoints="{ '960px': '75vw' }" :style="{ width: '40vw' }" :modal="true" @keydown.enter.prevent="simpanEdit()" @hide="v$.$reset()">
                <div class="col-12 lg:col-12 p-fluid">
                    <div>
                        <span class="flex justify-content-center mt-4">
                            <center><Image :src="src1" alt="" imageStyle="height: 130px; width: 130px" preview /></center>
                        </span>
                    </div>
                </div>
                <template #footer>
                    <Button label="Batal" @click="modalDetail = false" icon="" class="p-button-secondary" />
                </template>
            </Dialog>
        </div>
    </div>
</template>
<script>
import { FilterMatchMode } from 'primevue/api';
import useVuelidate from '@vuelidate/core';
import { required, maxLength } from '@vuelidate/validators';
export default {
    setup() {
        return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
    },
    data() {
        return {
            usulan: [],
            total_usulan: null,
            field: ['nama_kategori'],
            loading1: true,
            modalInput: false,
            modalEdit: false,
            modalDetail: false,
            data: {
                nama_kategori: null,
                file1: null,
            },
            src1: null,
            file1: null,
            busy: false,
            dataEdit: {},
            filters1: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            perPage: 10,
            perPages: [10, 25, 50, 100],
        };
    },
    validations() {
        return {
            data: {
                nama_kategori: {
                    required,
                    maxLength: maxLength(50),
                },
                file1: {
                    required,
                },
            },
            dataEdit: {
                nama_kategori: {
                    required,
                    maxLength: maxLength(50),
                },
                file1: {
                    required,
                },
            },
        };
    },
    computed: {
        formString() {
            return JSON.stringify(this.data);
        },
        isValid() {
            return !this.v$.data.$invalid;
        },
        isDirty() {
            return this.v$.data.$anyDirty;
        },
        isValidEdit() {
            return !this.v$.dataEdit.$invalid;
        },
        isDirtyEdit() {
            return this.v$.dataEdit.$anyDirty;
        },
        isLevel1() {
            // admin
            return this.$store.state.billing.user.level == 1;
        },
        isLevel2() {
            // kasubkeu
            return this.$store.state.billing.user.level == 2;
        },
        isLevel3() {
            // kabagumum&aka
            return this.$store.state.billing.user.level == 3;
        },
        isLevel4() {
            // wadir2
            return this.$store.state.billing.user.level == 4;
        },
        isAdmin() {
            // superadmin
            return this.$store.state.billing.user.level == 99;
        },
    },
    mounted() {
        this.get_usulan();
        this.v$.$reset();
        // this.initFilters();
    },
    methods: {
        clearFilter1() {
            this.filters1 = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        handleFile() {
            this.file1 = this.$refs.file1.files[0];
            this.data.file1 = this.$refs.file1.files[0];
            this.src1 = URL.createObjectURL(this.file1);
        },
        pages(x) {
            console.log(x);
        },
        initFilters1() {
            this.filters1 = {
                nama_kategori: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        get_usulan() {
            let vm = this;
            vm.loading1 = true;
            console.log(this.$store.state.billing.user);
            let link = '';
            if (this.$store.state.billing.user.level == 1) {
                link = '/kategoriBayar/listHalaman';
            } else if (this.$store.state.billing.user.level == 2) {
                link = '/kategoriBayar/listByStatusKategoriBayar/1';
            } else if (this.$store.state.billing.user.level == 3) {
                link = '/kategoriBayar/listByStatusKategoriBayar/2';
            } else if (this.$store.state.billing.user.level == 4) {
                link = '/kategoriBayar/listByStatusKategoriBayar/3';
            } else if (this.$store.state.billing.user.level == 99) {
                link = '/kategoriBayar/listHalaman';
            }
            try {
                vm.$axiosbilling
                    .get(link)
                    .then((res) => {
                        console.log(res.data.data);
                        vm.usulan = [];
                        res.data.data.forEach((item, idx) => {
                            if (item.status_kategori_bayar == 0) {
                                item.status = 'pengajuan Usulan / Revisi';
                            } else if (item.status_kategori_bayar == 1) {
                                item.status = 'Menunggu Persetujuan Kasub Keuangan';
                            } else if (item.status_kategori_bayar == 2) {
                                item.status = 'Menunggu Persetujuan Kabag Umum dan Akademik';
                            } else if (item.status_kategori_bayar == 3) {
                                item.status = 'Menunggu Persetujuan Wadir 2';
                            } else if (item.status_kategori_bayar == 4) {
                                item.status = 'Disetujui';
                            }
                            if (this.$store.state.billing.user.level == 1) {
                                if (item.status_kategori_bayar < 2) {
                                    item.no = idx + 1;
                                    vm.usulan.push(item);
                                }
                            } else {
                                item.no = idx + 1;
                                vm.usulan.push(item);
                            }
                        });
                        vm.total_usulan = vm.usulan.length;
                        vm.loading1 = false;
                    })
                    .catch((err) => {
                        console.log(err);
                        vm.loading1 = false;
                    });
            } catch (err) {
                console.log(err);
                vm.loading1 = false;
            }
        },
        register() {
            let vm = this;
            if (vm.isValid && vm.isDirty) {
                vm.busy = true;
                let formData = new FormData();
                formData.append('nama_kategori', vm.data.nama_kategori);
                formData.append('file1', vm.data.file1);
                vm.$axiosbilling
                    .post('kategoriBayar/register', formData)
                    .then((res) => {
                        // console.log(res.data.status);
                        if (res.data.status == 200) {
                            if (res.data.message == 'sukses') {
                                vm.data = {
                                    nama_kategori: null,
                                    file: null,
                                };
                                vm.modalInput = false;
                                vm.$toast.add({ severity: 'success', summary: 'Konfirmasi', detail: 'Berhasil Mendaftarkan Usulan Biaya', life: 3000 });
                                this.get_usulan();
                            } else {
                                vm.modalInput = false;
                                vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                            }
                        } else {
                            vm.modalInput = false;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        vm.modalInput = false;
                        vm.$toast.add({ severity: 'error', summary: 'Konfirmasi', detail: 'Terjadi kesalahan pada server', life: 3000 });
                    });
            } else {
                vm.$toast.add({ severity: 'error', summary: 'Peringatan', detail: 'Masih terdapat isian data yang tidak valid', life: 3000 });
            }
        },
        editData(data) {
            let vm = this;
            vm.dataEdit = data;
            vm.src1 = vm.$ip2 + data.file_kategori_bayar;
            vm.modalEdit = true;
        },
        detail(data) {
            let vm = this;
            vm.dataEdit = data;
            vm.src1 = vm.$ip2 + data.file_kategori_bayar;
            vm.modalDetail = true;
        },
        simpanEdit() {
            let vm = this;
            if (vm.isValidEdit) {
                let formData = new FormData();
                formData.append('nama_kategori', vm.usul.nama_kategori);
                formData.append('id', vm.usul.id);
                if (vm.file1) {
                    formData.append('file1', vm.file1);
                }
                vm.$axiosbilling
                    .post('kategoriBayar/update', formData)
                    .then((res) => {
                        // console.log(res.data.status);
                        if (res.data.status == 200) {
                            if (res.data.message == 'sukses') {
                                vm.modalEdit = false;
                                vm.$toast.add({ severity: 'success', summary: 'Konfirmasi', detail: 'Berhasil Mengupdate Data Usulan Biaya', life: 3000 });
                                this.get_usulan();
                            } else {
                                vm.modalEdit = false;
                                vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                            }
                        } else {
                            vm.modalEdit = false;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        vm.modalEdit = false;
                        vm.$toast.add({ severity: 'error', summary: 'Konfirmasi', detail: 'Terjadi kesalahan pada server', life: 3000 });
                    });
            } else {
                vm.$toast.add({ severity: 'error', summary: 'Peringatan', detail: 'Masih terdapat isian data yang tidak valid', life: 3000 });
            }
        },
        approve(data, status) {
            let vm = this;
            vm.busy = true;
            data.status_kategori_bayar = status;
            vm.$axiosbilling
                .post('kategoriBayar/update', data)
                .then((res) => {
                    // console.log(res.data.status);
                    if (res.data.status == 200) {
                        if (res.data.message == 'sukses') {
                            vm.$confirm.close();
                            vm.$toast.add({ severity: 'success', summary: 'Konfirmasi', detail: 'Berhasil Mennyetujui Usulan Biaya', life: 3000 });
                            this.get_usulan();
                        } else {
                            vm.$confirm.close();
                            vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                        }
                    } else {
                        vm.$confirm.close();
                    }
                })
                .catch((err) => {
                    console.log(err);
                    vm.modalApprove = false;
                    vm.$toast.add({ severity: 'error', summary: 'Konfirmasi', detail: 'Terjadi kesalahan pada server', life: 3000 });
                });
        },
        simpanDelete(data) {
            let vm = this;
            vm.$axiosbilling
                .post('kategoriBayar/delete', { id: data.id })
                .then((res) => {
                    // console.log(res.data.status);
                    if (res.data.status == 200) {
                        if (res.data.message == 'sukses') {
                            vm.$toast.add({ severity: 'success', summary: 'Konfirmasi', detail: 'Berhasil Menghapus Data Usulan Biaya', life: 3000 });
                            this.get_usulan();
                            this.$confirm.close();
                        } else {
                            vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                            this.$confirm.close();
                        }
                    } else {
                        vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: 'data sudah ada', life: 3000 });
                        this.$confirm.close();
                    }
                })
                .catch((err) => {
                    console.log(err);
                    vm.$toast.add({ severity: 'error', summary: 'Konfirmasi', detail: 'Terjadi kesalahan pada server', life: 3000 });
                    this.$confirm.close();
                });
        },
        deleteData(data) {
            // console.log(data, 'ini datanya bossss');
            this.$confirm.require({
                message: 'Apakah anda yakin ingin menghapus ini?',
                header: 'Konfirmasi',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.simpanDelete(data);
                },
                reject: () => {
                    this.$confirm.close();
                },
            });
        },
        approveData(data, status) {
            // console.log(data, 'ini datanya bossss');
            this.$confirm.require({
                message: 'Apakah anda yakin ingin mennyetujui usulan ini?',
                header: 'Konfirmasi',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.approve(data, status);
                },
                reject: () => {
                    this.$confirm.close();
                },
            });
        },
        checkValidasi(type, fieldName) {
            const field = this.v$[type][fieldName];
            let x = field.$errors[0].$validator;
            if (x == 'required') {
                return '* Data wajib diisi';
            } else if (x == 'email') {
                return '* Data harus berbentuk email';
            } else if (x == 'numeric') {
                return '* Data harus terdiri hanxa dari angka';
            } else if (x == 'minLength') {
                return `* Data minimal ${field.$errors[0].$params.min} digits`;
            } else if (x == 'maxLength') {
                return `* Data maksimal ${field.$errors[0].$params.max} digits`;
            } else if (x == 'alphaNum') {
                return `* Data tidak boleh mengandung spasi atau simbol`;
            } else if (x == 'official') {
                return `* Data tidak boleh mengandung spasi atau simbol`;
            }
        },
    },
};
</script>
<style scoped lang="scss">
@import '@/assets/demo/styles/badges.scss';

::v-deep(.p-datatable-frozen-tbody) {
    font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
    font-weight: bold;
}

::v-deep(.p-progressbar) {
    height: 0.5rem;
    background-color: #d8dadc;

    .p-progressbar-value {
        background-color: #607d8b;
    }
}
</style>
